import { NotificationSettingsTables } from '@traba/react-components'
import { useState } from 'react'
import { useUser } from 'src/hooks/useUser'
import { Modal } from '../base'
import { SmsConsentModalForSettings } from '../Modals/SmsConsentModal/SmsConsentModalForSettings'

export const NotificationSettings = () => {
  const [showSmsConsentModal, setShowSmsConsentModal] = useState(false)
  const { user } = useUser()
  return (
    <>
      <NotificationSettingsTables
        handleUserHasNoConsent={() => {
          setShowSmsConsentModal(true)
        }}
        hasSMSConsent={user?.smsConsent?.agreedToSmsConsent}
      />
      <Modal
        isOpen={showSmsConsentModal}
        handleClose={() => setShowSmsConsentModal(false)}
      >
        <SmsConsentModalForSettings
          setShowSmsConsentModal={setShowSmsConsentModal}
          onCancel={() => {
            setShowSmsConsentModal(false)
          }}
        />
      </Modal>
    </>
  )
}
