import { Shift, WorkerShiftWithWorkerDetails } from '@traba/types'
import { Row } from 'src/components/base'
import WorkersManagementMenu from 'src/components/WorkersManagementMenu/WorkersManagementMenu'
import { BizToWorkerConnection } from 'src/hooks/useConnections'
import { theme } from 'src/libs/theme'
import { ConnectionType } from 'src/types'
import { WorkersOnShiftTableProps } from '../WorkersOnShiftTable.hooks'
import { useInReview } from '../WorkersOnShiftTable.hooks'

interface WorkerOnShiftTableActionsProps
  extends Pick<
    WorkersOnShiftTableProps,
    | 'clockInWorkers'
    | 'editClockInWorkers'
    | 'clockOutWorkers'
    | 'abandonWorkers'
    | 'rejectWorkers'
    | 'noShowWorkers'
    | 'bizCancelRemoveWorkers'
  > {
  connections: BizToWorkerConnection | undefined
  additionalWorkerActions?: JSX.Element
  editMode: boolean | undefined
  selectedRows: WorkerShiftWithWorkerDetails[]
  setSelectedRows: React.Dispatch<
    React.SetStateAction<WorkerShiftWithWorkerDetails[]>
  >
  shift: Shift
  timezone: string
}

export const WorkerOnShiftTableActions = ({
  connections,
  additionalWorkerActions,
  editMode,
  selectedRows,
  setSelectedRows,
  shift,
  timezone,
  clockOutWorkers,
  clockInWorkers,
  noShowWorkers,
  rejectWorkers,
  abandonWorkers,
  editClockInWorkers,
  bizCancelRemoveWorkers,
}: WorkerOnShiftTableActionsProps) => {
  const { toggleReview } = useInReview()
  if (!connections) {
    return null
  }

  return (
    <Row gap={theme.space.xs} alignCenter>
      {additionalWorkerActions ? additionalWorkerActions : null}
      {!editMode && (
        <WorkersManagementMenu
          shiftId={shift.id}
          workers={selectedRows}
          onManagementModalClose={() => setSelectedRows([])}
          connections={connections}
          onFavorite={(workerIds) => {
            for (const w of workerIds) {
              connections.favoriteWorker(w).then((connection) => {
                toggleReview(
                  workerIds,
                  'OPEN',
                  ConnectionType.FAVORITE,
                  connection.id,
                )
              })
            }
          }}
          shiftRole={shift.shiftRole}
          shiftStartTime={shift.businessStartTime ?? shift.startTime}
          shiftEndTime={shift.endTime}
          timezone={timezone}
          clockInWorkers={clockInWorkers}
          editClockInWorkers={editClockInWorkers}
          clockOutWorkers={clockOutWorkers}
          minimumPaidTime={shift?.minimumPaidTime || 0}
          abandonWorkers={abandonWorkers}
          rejectWorkers={rejectWorkers}
          noShowWorkers={noShowWorkers}
          bizCancelRemoveWorkers={bizCancelRemoveWorkers}
        />
      )}
    </Row>
  )
}
