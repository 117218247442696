import { makePlural } from '@traba/string-utils'
import React from 'react'
import {
  RegionalFilterStatus,
  useAppContext,
} from 'src/context/appContext/AppContext'
import useMobile from 'src/hooks/useMobile'
import { theme } from 'src/libs/theme'
import * as S from '../../Header/Header.styles'
import SvgIcon from '../SvgIcon'
import { Text } from '../Text'

type RegionalFilterButtonProps = {
  regionalFilterButtonRef?: React.RefObject<HTMLDivElement>
  style?: React.CSSProperties
}

export function RegionalFilterButton({
  regionalFilterButtonRef,
  style,
}: RegionalFilterButtonProps): React.ReactElement {
  const {
    state: {
      regionalFilterStatus,
      isRegionalFilterOpened,
      selectedLocationsCount,
    },
    dispatch: appContextDispatch,
  } = useAppContext()
  const { isReactNativeApp } = useMobile()

  const regionalFilterButtonColor =
    regionalFilterStatus === RegionalFilterStatus.ENABLED
      ? isRegionalFilterOpened
        ? theme.colors.Violet80
        : theme.colors.brand
      : theme.colors.MidnightBlue

  const toggleRegionalFilter = () => {
    if (regionalFilterStatus !== RegionalFilterStatus.ENABLED) {
      return
    }
    if (!isRegionalFilterOpened) {
      appContextDispatch({
        type: 'SHOW_REGIONAL_FILTER',
      })
    } else {
      appContextDispatch({
        type: 'HIDE_REGIONAL_FILTER',
      })
    }
    window.analytics.track('User Clicked Regional Filter Button')
  }

  return (
    <S.ChevronDownWrapper
      id="regional-filter-dropdown-icon"
      onPointerDown={toggleRegionalFilter}
      style={{
        padding: theme.space.zero,
        cursor:
          regionalFilterStatus === RegionalFilterStatus.ENABLED
            ? 'pointer'
            : 'not-allowed',
        marginBottom: isReactNativeApp ? theme.space.xxs : undefined,
        ...style,
      }}
      ref={regionalFilterButtonRef}
    >
      <SvgIcon name="location" size={24} color={regionalFilterButtonColor} />
      <Text
        style={{
          marginLeft: theme.space.xxxs,
          marginRight: theme.space.xxs,
          color: regionalFilterButtonColor,
        }}
      >
        {regionalFilterStatus === RegionalFilterStatus.ENABLED
          ? selectedLocationsCount > 0
            ? `${selectedLocationsCount} location${makePlural(selectedLocationsCount)}`
            : 'Select your location'
          : 'All Locations'}
      </Text>

      {regionalFilterStatus === RegionalFilterStatus.ENABLED && (
        <S.ChevronDown
          $isActive={isRegionalFilterOpened}
          color={regionalFilterButtonColor}
        />
      )}
    </S.ChevronDownWrapper>
  )
}
