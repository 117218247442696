import { DEFAULT_PAGE_SIZE } from '@traba/consts'
import {
  Card,
  IMenuItem,
  LoadingSpinner,
  SearchSelect,
} from '@traba/react-components'
import { Pill } from '@traba/react-components'
import { CompanyCategory, CompanyCategoryNameToId } from '@traba/types'
import { useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { MainLayout } from 'src/components'
import {
  Button,
  ButtonVariant,
  Col,
  Divider,
  Icon,
  Input,
  Row,
  SvgIcon,
  Text,
} from 'src/components/base'
import { Pagination } from 'src/components/base/Pagination'
import { InviteWorkersToShiftModal } from 'src/components/Modals/InviteWorkerToShiftModal/InviteWorkersToShiftModal'
import { RosterModal } from 'src/components/Modals/RosterModal/RosterModal'
import { WorkerSearchItem } from 'src/components/WorkerSearch/WorkerSearchItem'
import {
  RegionalFilterStatus,
  useAppContext,
} from 'src/context/appContext/AppContext'
import {
  useBehaviorAttributes,
  useRoleAttributes,
} from 'src/hooks/useAttributes'
import { useDebouncedState } from 'src/hooks/useDebouncedState'
import { useLocationRegions } from 'src/hooks/useLocationRegions'
import useMobile from 'src/hooks/useMobile'
import { usePagination } from 'src/hooks/usePagination'
import { useRosters } from 'src/hooks/useRosters'
import { useShouldShowSearch } from 'src/hooks/useShouldShowSearch'
import {
  SearchWorker,
  useWorkerSearch,
  WorkerSearchParameters,
  WorkerSearchResponse,
} from 'src/hooks/useWorkerSearch'

import { theme } from 'src/libs/theme'
import { AddToRosterModal } from './AddToRosterModal'

const SKILL_EXCLUSIONS = [
  'highlighter_vest',
  'hard_hat',
  'steel_hammer',
  'crescent_wrench',
  'work_gloves',
]

const FILTER_MIN_WIDTH = 160
const FILTER_MAX_WIDTH = 200

export default function WorkersSearchScreen() {
  const { dispatch: appContextDispatch } = useAppContext()
  const { isMobileViewOrReactNative } = useMobile()
  const [firstName, debouncedFirstName, setFirstName] = useDebouncedState(
    '',
    (newState) => {
      window.analytics.track(`Worker Search First Name Changed`, {
        firstName: newState,
      })
    },
  )
  const [lastName, debouncedLastName, setLastName] = useDebouncedState(
    '',
    (newState) => {
      window.analytics.track(`Worker Search Last Name Changed`, {
        lastName: newState,
      })
    },
  )
  const [selectedRegion, debouncedSelectedRegion, setSelectedRegion] =
    useDebouncedState<IMenuItem | undefined>(undefined, (newState) => {
      window.analytics.track(`Worker Search Selected Regions Changed`, {
        selectedRegions: newState,
      })
    })
  const [regionsDefaulted, setRegionsDefaulted] = useState(false)
  const [selectedAttributes, debouncedAttributes, setSelectedAttributes] =
    useDebouncedState<IMenuItem[]>([], (newState) => {
      window.analytics.track(`Worker Search Selected Attributes Changed`, {
        selectedAttributes: newState,
      })
    })
  const [
    selectedIndustries,
    debouncedSelectedIndustries,
    setSelectedIndustries,
  ] = useDebouncedState<IMenuItem[]>([], (newState) => {
    window.analytics.track(`Worker Search Selected Industries Changed`, {
      selectedIndustries: newState,
    })
  })
  const [selectedWorkerIds, setSelectedWorkerIds] = useState(new Set<string>())
  const { regions, isLoading: regionsAreLoading } = useLocationRegions()
  const { behaviorAttributes, isLoading: behaviorAttributesAreLoading } =
    useBehaviorAttributes()
  const { roleAttributes, isLoading: roleAttributesAreLoading } =
    useRoleAttributes()
  const attributeIsBehavioral = new Map<string, boolean>([
    ...(behaviorAttributes || []).map((b): [string, boolean] => [b.type, true]),
    ...(roleAttributes || []).map((r): [string, boolean] => [r.type, false]),
  ])
  const [rosterModalOpen, setRosterModalOpen] = useState(false)
  const [createRosterModalOpen, setCreateRosterModalOpen] = useState(false)
  const [workersForModals, setWorkersForModals] = useState<Set<string>>(
    new Set(),
  )
  const [inviteToShiftModalOpen, setInviteToShiftModalOpen] = useState(false)
  const [searchParams] = useSearchParams()
  const { showSearch } = useShouldShowSearch()
  const startPage = parseInt(searchParams.get('p') ?? '0')
  const {
    page,
    onPageLeft,
    onPageRight,
    data: searchResponseReturned,
    resetPagination,
  } = usePagination<WorkerSearchParameters, WorkerSearchResponse | undefined>(
    'worker_search',
    {
      regionIds: debouncedSelectedRegion
        ? [debouncedSelectedRegion.value]
        : undefined,
      firstName:
        debouncedFirstName.length === 0 ? undefined : debouncedFirstName,
      lastName: debouncedLastName.length === 0 ? undefined : debouncedLastName,
      industries:
        debouncedSelectedIndustries.length > 0
          ? debouncedSelectedIndustries.map((item) => item.value)
          : undefined,
      ...getAttributes(true),
    },
    useWorkerSearch,
    DEFAULT_PAGE_SIZE,
    startPage,
  )
  const [searchResponse, setSearchResponse] = useState<
    WorkerSearchResponse | undefined
  >()
  const sortedRegions = useMemo(
    () =>
      (regions || [])
        .filter((region) => region.showSearch || region.activateSearch)
        .sort((a, b) => {
          if (a.displayName < b.displayName) {
            return -1
          }
          if (a.displayName > b.displayName) {
            return 1
          }
          return 0
        })
        .map((region): IMenuItem => {
          return {
            label: region.displayName,
            value: region.regionId,
          }
        }),
    [regions],
  )
  useEffect(() => {
    if (sortedRegions.length === 0 || regionsDefaulted) {
      return
    }
    setSelectedRegion(sortedRegions[0])
    setRegionsDefaulted(true)
  }, [sortedRegions, setSelectedRegion, regionsDefaulted, setRegionsDefaulted])
  useEffect(() => {
    if (!searchResponseReturned && searchResponse) {
      return
    }
    setSearchResponse(searchResponseReturned)
  }, [searchResponse, searchResponseReturned])
  const [allSeenWorkers, setAllSeenWorkers] = useState<
    Map<string, SearchWorker>
  >(new Map<string, SearchWorker>())
  useEffect(() => {
    if (!searchResponseReturned) {
      return
    }

    setAllSeenWorkers((previousState) => {
      const newMap = new Map([...previousState.entries()])
      for (const worker of searchResponseReturned.workers) {
        newMap.set(worker.workerId, worker)
      }
      return newMap
    })
  }, [searchResponseReturned])
  useEffect(() => {
    appContextDispatch({
      type: 'SET_REGIONAL_FILTER_STATUS',
      value: RegionalFilterStatus.DISABLED,
    })
    return () => {
      appContextDispatch({
        type: 'SET_REGIONAL_FILTER_STATUS',
        value: RegionalFilterStatus.HIDE,
      })
    }
  }, [appContextDispatch])

  // prefetch for modal
  useRosters()

  function getAttributes(debouncedValue: boolean) {
    let behaviorAttributeIds: string[] | undefined
    let shiftAttributeIds: string[] | undefined
    for (const skill of debouncedValue
      ? debouncedAttributes
      : selectedAttributes) {
      if (attributeIsBehavioral.get(skill.value)) {
        if (!behaviorAttributeIds) {
          behaviorAttributeIds = []
        }
        behaviorAttributeIds.push(skill.value)
        continue
      }
      if (!shiftAttributeIds) {
        shiftAttributeIds = []
      }
      shiftAttributeIds.push(skill.value)
    }
    return {
      behavioralAttributes: behaviorAttributeIds,
      shiftAttributes: shiftAttributeIds,
    }
  }

  function getSelectedWorkerNamePills() {
    if (selectedWorkerIds.size < 1) {
      return
    }
    const pills = []
    for (const workerId of selectedWorkerIds) {
      const worker = allSeenWorkers.get(workerId)
      if (!worker) {
        continue
      }
      pills.push(
        <Pill
          onClick={() => {
            selectedWorkerIds.delete(workerId)
            setSelectedWorkerIds(new Set(selectedWorkerIds))
          }}
          addMargin={true}
          children={
            <Row style={{ alignItems: 'center' }}>
              <Text style={{ marginRight: theme.space.xxs }} variant="link">
                {worker.firstName} {worker.lastName}
              </Text>
              <SvgIcon name="cancel" />
            </Row>
          }
          addBorder={false}
        />,
      )
    }
    pills.push(
      <Button
        onClick={() => {
          setSelectedWorkerIds(new Set())
        }}
        variant={ButtonVariant.BRANDLINK}
      >
        Clear all
      </Button>,
    )
    return (
      <Row
        style={{
          marginBottom: theme.space.xs,
          rowGap: theme.space.xxs,
          flexWrap: 'wrap',
        }}
      >
        {pills}
      </Row>
    )
  }
  const sortedSkills = useMemo(() => {
    const attributeIdToName = new Map([
      ...(roleAttributes || []).map((r): [string, string] => [
        r.type,
        r.displayName,
      ]),
    ])
    return Array.from(attributeIdToName.entries())
      .filter(([attrType]) => !SKILL_EXCLUSIONS.includes(attrType))
      .map(([attrType, name]): IMenuItem => {
        return {
          label: name,
          value: attrType,
        }
      })
      .sort((a, b) => {
        if (a.label < b.label) {
          return -1
        }
        if (a.label > b.label) {
          return 1
        }
        return 0
      })
  }, [roleAttributes])
  const industryOptions = Array.from(CompanyCategoryNameToId.entries()).map(
    ([name, id]) => {
      return {
        label: name,
        value: id,
      }
    },
  )

  function doPageLeft() {
    window.analytics.track(`Worker Search Page Left Clicked`, {
      page,
    })
    onPageLeft()
  }
  function doPageRight() {
    window.analytics.track(`Worker Search Page Right Clicked`, {
      page,
    })
    onPageRight()
  }

  if (
    !regions ||
    regionsAreLoading ||
    !behaviorAttributes ||
    behaviorAttributesAreLoading ||
    !roleAttributes ||
    roleAttributesAreLoading ||
    !searchResponse ||
    !showSearch
  ) {
    return (
      <MainLayout title="Find New Workers">
        <LoadingSpinner />
      </MainLayout>
    )
  }

  return (
    <>
      <MainLayout title="Find New Workers">
        <Text variant="h2">Find New Workers</Text>
        {!isMobileViewOrReactNative && (
          <Text
            style={{ marginTop: theme.space.xs, marginBottom: theme.space.xs }}
            variant="body2"
          >
            Explore the exclusive Traba worker pool and find workers to add to
            your rosters, invite to shifts and more.
          </Text>
        )}
        <Divider wrapperStyle={{ marginBottom: theme.space.xs }} />
        <Row
          style={{
            flexWrap: 'wrap',
            marginBottom: theme.space.sm,
            alignItems: 'center',
            rowGap: theme.space.sm,
          }}
        >
          <Input
            type="text"
            containerStyle={{
              marginRight: theme.space.xs,
              marginTop: 0,
              width: '20%',
              minWidth: FILTER_MIN_WIDTH,
              maxWidth: FILTER_MAX_WIDTH,
            }}
            onChange={(e) => {
              resetPagination()
              setFirstName(e.target.value)
            }}
            value={firstName}
            placeholder="Worker first name"
            leftIconName="search"
          />
          <Input
            type="text"
            containerStyle={{
              marginRight: theme.space.xs,
              marginTop: 0,
              width: '20%',
              minWidth: FILTER_MIN_WIDTH,
              maxWidth: FILTER_MAX_WIDTH,
            }}
            onChange={(e) => {
              resetPagination()
              setLastName(e.target.value)
            }}
            value={lastName}
            placeholder="Worker last name"
            leftIconName="search"
          />
          <SearchSelect
            options={sortedRegions}
            handleSelect={(option: IMenuItem | undefined) => {
              if (!option) {
                return
              }
              setSelectedRegion(option)
            }}
            label="Filter by Location"
            style={{
              marginRight: theme.space.xs,
              width: '20%',
              minWidth: FILTER_MIN_WIDTH,
              maxWidth: FILTER_MAX_WIDTH,
            }}
            selectStyle={{ minHeight: '48px' }}
            selectItem={selectedRegion}
            onlyShowLabel
          />
          <SearchSelect
            multiple
            options={sortedSkills}
            handleSelectMultiple={(options: IMenuItem[]) => {
              setSelectedAttributes(options)
              resetPagination()
            }}
            label="Filter by Skills"
            style={{
              marginRight: theme.space.xs,
              width: '20%',
              minWidth: FILTER_MIN_WIDTH,
              maxWidth: FILTER_MAX_WIDTH,
            }}
            selectStyle={{ minHeight: '48px' }}
            aria-label="Filter by Skills"
            selectedItems={selectedAttributes}
            onlyShowLabel
          />
          <SearchSelect
            multiple
            options={industryOptions}
            handleSelectMultiple={(options: IMenuItem[]) => {
              setSelectedIndustries(options)
              resetPagination()
            }}
            selectStyle={{ minHeight: '48px' }}
            label="Filter by Industry"
            aria-label="Filter by Industry"
            style={{
              marginRight: theme.space.xs,
              width: '20%',
              minWidth: FILTER_MIN_WIDTH,
              maxWidth: FILTER_MAX_WIDTH,
            }}
            selectedItems={selectedIndustries}
            onlyShowLabel
          />
        </Row>
        {getSelectedWorkerNamePills()}
        <Row
          style={{
            marginBottom: theme.space.xxs,
            flex: 1,
            justifyContent: 'end',
          }}
        >
          <Button
            disabled={selectedWorkerIds.size < 1}
            style={{ marginRight: theme.space.xxs }}
            variant={ButtonVariant.OUTLINED}
            onClick={() => {
              window.analytics.track(
                `Worker Search Invite Group Of Workers To Shift Clicked`,
                {
                  workerIds: Array.from(selectedWorkerIds),
                },
              )
              setWorkersForModals(selectedWorkerIds)
              setInviteToShiftModalOpen(true)
            }}
          >
            Invite to shift
          </Button>
          <Button
            onClick={() => {
              window.analytics.track(
                `Worker Search Add Group Of Workers To Roster Clicked`,
                {
                  workerIds: Array.from(selectedWorkerIds),
                },
              )
              setWorkersForModals(selectedWorkerIds)
              setRosterModalOpen(true)
            }}
            disabled={selectedWorkerIds.size < 1}
            variant={ButtonVariant.OUTLINED}
          >
            Add to roster
          </Button>
          <Pagination
            page={page}
            pageSize={DEFAULT_PAGE_SIZE}
            onPageLeft={doPageLeft}
            onPageRight={doPageRight}
            dataSize={searchResponse.count}
          />
        </Row>
        {searchResponse.workers.length > 0 ? (
          <Col style={{ marginBottom: theme.space.xs }}>
            {searchResponse.workers.map((worker, i) => {
              return (
                <WorkerSearchItem
                  key={`worker_search_item_${i}_${worker.workerId}`}
                  selected={selectedWorkerIds.has(worker.workerId)}
                  onCheckboxClicked={() => {
                    let checked: boolean
                    if (!selectedWorkerIds.has(worker.workerId)) {
                      selectedWorkerIds.add(worker.workerId)
                      setSelectedWorkerIds(new Set(selectedWorkerIds))
                      checked = true
                    } else {
                      selectedWorkerIds.delete(worker.workerId)
                      setSelectedWorkerIds(new Set(selectedWorkerIds))
                      checked = false
                    }
                    window.analytics.track(
                      `Worker Search Worker Checkbox Clicked`,
                      {
                        workerId: worker.workerId || worker.uid,
                        checked,
                        firstName: worker.firstName,
                        lastName: worker.lastName,
                      },
                    )
                  }}
                  selectedIndustries={selectedIndustries.map(
                    (item) => item.value as CompanyCategory,
                  )}
                  worker={worker}
                  selectedAttributes={getAttributes(false)}
                  addToRosterClicked={() => {
                    window.analytics.track(
                      `Worker Search Add Single Worker To Roster Clicked`,
                      {
                        workerId: worker.workerId || worker.uid,
                      },
                    )
                    setWorkersForModals(new Set([worker.workerId]))
                    setRosterModalOpen(true)
                  }}
                  inviteToShiftClicked={() => {
                    window.analytics.track(
                      `Worker Search Invite Single Worker To Shift Clicked`,
                      {
                        workerId: worker.workerId || worker.uid,
                      },
                    )
                    setWorkersForModals(new Set([worker.workerId]))
                    setInviteToShiftModalOpen(true)
                  }}
                  addMargin={i < searchResponse.workers.length - 1}
                />
              )
            })}
          </Col>
        ) : (
          <Col
            style={{ marginTop: theme.space.lg, marginBottom: theme.space.lg }}
          >
            <Card style={{ backgroundColor: theme.colors.Orange10 }}>
              <Row>
                <SvgIcon
                  style={{ marginRight: theme.space.xxs }}
                  name="alert"
                  color={theme.colors.Yellow70}
                  size={24}
                />
                <Text variant="h6">
                  Unselect some filters to see more results
                </Text>
              </Row>
            </Card>
            <Card style={{ alignItems: 'center', marginTop: theme.space.sm }}>
              <Col style={{ alignItems: 'center' }}>
                <Col
                  style={{
                    border: `2px solid ${theme.colors.Grey20}`,
                    borderRadius: 8,
                    padding: theme.space.xs,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Icon height={24} name="folder" />
                </Col>
              </Col>
              <Text style={{ marginTop: theme.space.sm }} variant="body2">
                There are no results for your criteria.
              </Text>
            </Card>
          </Col>
        )}
        <Row style={{ flex: 1, justifyContent: 'end' }}>
          <Pagination
            page={page}
            pageSize={DEFAULT_PAGE_SIZE}
            onPageLeft={doPageLeft}
            onPageRight={doPageRight}
            dataSize={searchResponse.count}
          />
        </Row>
      </MainLayout>
      <AddToRosterModal
        isOpen={rosterModalOpen}
        handleClose={() => setRosterModalOpen(false)}
        workerIds={Array.from(workersForModals)}
        onClickNewRoster={() => {
          setRosterModalOpen(false)
          setCreateRosterModalOpen(true)
        }}
      />
      <RosterModal
        isOpen={createRosterModalOpen}
        setIsOpen={setCreateRosterModalOpen}
        workers={Array.from(allSeenWorkers.values()).filter((w) =>
          workersForModals.has(w.workerId),
        )}
        initialSelectedWorkers={workersForModals}
      />
      <InviteWorkersToShiftModal
        isOpen={inviteToShiftModalOpen}
        handleModalClose={() => setInviteToShiftModalOpen(false)}
        workers={searchResponse.workers.filter((w) =>
          workersForModals.has(w.workerId),
        )}
      />
    </>
  )
}
