export enum FeatureAnnouncementType {
  DailyView = 'DAILY_VIEW',
  WorkerSearch = 'WORKER_SEARCH',
  RegionalFilter = 'REGIONAL_FILTER',
  Timesheets = 'TIMESHEETS',
  None = 'NONE',
  SDM = 'SDM',
}

export interface FeatureAnnouncementModalProps {
  handleClose: () => void
  activeFeature: FeatureAnnouncementType
}
