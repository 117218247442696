import { InputAdornment, TextField } from '@mui/material'
import { useHotSettings } from '@traba/hooks'
import {
  IMenuItem,
  RoleSearchSelect,
  SelectDropdown,
} from '@traba/react-components'
import {
  CreateSchedule,
  RoleInfoForCreateShiftRequest,
  ShiftPayType,
} from '@traba/types'
import { getRoleSearchSelectErrorMessage } from '@traba/utils'
import React, { Dispatch, SetStateAction } from 'react'
import { useEffect, useState } from 'react'
import { Col, Divider, InlineBanner, Row, Text, Input } from 'src/components'
import { PAY_RATE_DEFAULT } from 'src/hooks/useShiftRequests'
import { theme } from 'src/libs/theme'
import { BookShiftsWorkersContentModal } from 'src/screens/BookShifts/types'
import { RoleData } from 'src/types'
import { validatePayRate, validateWorkers } from '../validation'
import { RoleCreationContent } from './RoleCreationContent'

const payTypeOptions: IMenuItem<ShiftPayType>[] = [
  {
    label: 'Hourly',
    value: ShiftPayType.HOURLY,
  },
  {
    label: 'By Unit',
    value: ShiftPayType.UNIT,
  },
]

interface RoleSelectorProps {
  selectedRole: RoleInfoForCreateShiftRequest
  roles: RoleData[]
  minHourlyPay: number
  updateRoleInfoForCreateShiftRequest: (
    updatedRoleInfoForCreateShiftRequest: RoleInfoForCreateShiftRequest,
    originalRoleId: string,
  ) => void
  recurringRoles: RoleInfoForCreateShiftRequest[]
  schedules: CreateSchedule[]
  setModalType: Dispatch<SetStateAction<BookShiftsWorkersContentModal | null>>
  userHasLimitedShiftManagementAbility?: boolean
  isEdit?: boolean
  isEventCompany?: boolean
  pbuPayPerWorker?: number
  locationId?: string
  disableRecurringRoles?: boolean
}

function RoleSelector({
  selectedRole,
  roles,
  minHourlyPay,
  recurringRoles,
  updateRoleInfoForCreateShiftRequest,
  schedules,
  setModalType,
  userHasLimitedShiftManagementAbility,
  isEdit,
  isEventCompany,
  pbuPayPerWorker,
  locationId,
}: RoleSelectorProps) {
  const {
    roleId,
    payRate,
    payType,
    slotsRequested,
    minSlotsRequested,
    numberOfUnits,
  } = selectedRole

  const { hotSettings } = useHotSettings()
  const [maskedPayRate, setMaskedPayRate] = useState(payRate.toFixed(2))

  useEffect(() => {
    setMaskedPayRate(payRate.toFixed(2))
  }, [payRate, roleId])

  const validationError = validateWorkers(selectedRole)

  const [touchedPayRate, setTouchedPayRate] = useState(false)

  const onChangeRole = (v: string) => {
    const newRole = roles.find((r) => r.roleId === v)
    const rolePayRate = newRole?.defaultPayRate ?? PAY_RATE_DEFAULT

    updateRoleInfoForCreateShiftRequest(
      {
        ...selectedRole,
        roleId: v,
        ...(payType === ShiftPayType.HOURLY && {
          payRate: rolePayRate,
        }),
        // TODO(gavin): do i need genderPreference?
        // genderPreference: selectedRole?.genderPreference,
      },
      roleId,
    )
    // TODO(gavin): add analytics
    // window.analytics.track(`User Selected Role`, {
    //   roleId: v,
    //   isEdit,
    // })
  }

  const onChangePayType = (v: string) => {
    updateRoleInfoForCreateShiftRequest(
      {
        ...selectedRole,
        payType: v as ShiftPayType,
      },
      roleId,
    )
    if (v === ShiftPayType.UNIT) {
      onRateChange('0') // reset to zero
    }
    // TODO(gavin): add analytics
    // window.analytics.track(`User Selected Pay Type`, {
    //   payType: v,
    //   isEdit,
    // })
  }

  const onRateChange = (masked: string) => {
    setMaskedPayRate(masked)
  }

  const onRateBlur = () => {
    const payRate = +Math.abs(+maskedPayRate).toFixed(2)
    setMaskedPayRate(payRate.toFixed(2))
    if (payRate === selectedRole.payRate) {
      return
    }
    setTouchedPayRate(true)
    updateRoleInfoForCreateShiftRequest({ ...selectedRole, payRate }, roleId)
  }

  const onChangeSlotsRequested = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const input = +e.target.value
    const numSlotsRequested = input && input > 0 ? input : 0
    updateRoleInfoForCreateShiftRequest(
      {
        ...selectedRole,
        minSlotsRequested: numSlotsRequested,
        slotsRequested: numSlotsRequested,
      },
      roleId,
    )
  }

  const onChangeNumberOfUnits = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const input = +e.target.value
    updateRoleInfoForCreateShiftRequest(
      {
        ...selectedRole,
        numberOfUnits: input && input > 0 ? input : 0,
      },
      roleId,
    )
  }

  const payValidationError = validatePayRate(
    selectedRole,
    minHourlyPay,
    schedules,
  )

  const roleForShift = roles.find((role) => role.roleId === roleId)

  function onCreateRole() {
    setModalType('ROLE')
    window.analytics.track(`User Clicked Create New Role`, { isEdit })
  }
  function onCreateRoleFromPrevious() {
    setModalType('ROLE_PREVIOUS')
    window.analytics.track(`User Clicked Create Role From Templates`, {
      isEdit,
    })
  }

  return (
    <Col key={roleId} mt={theme.space.sm}>
      {!hotSettings?.enableRegionalAccessPhase1 ? (
        <SelectDropdown
          placeholder={'Choose from existing roles'}
          value={roleId}
          handleSelect={onChangeRole}
          menuItems={[
            { label: 'Choose from existing roles', value: '' },
            ...roles
              .map((r) => ({
                value: r.roleId,
                label: r.roleName,
                disabled: recurringRoles.some((rr) => rr.roleId === r.roleId),
              }))
              .sort((a, b) => a.label.localeCompare(b.label)),
          ]}
        />
      ) : (
        <RoleSearchSelect
          roles={
            roles.sort((a, b) => a.roleName.localeCompare(b.roleName)) ?? []
          }
          selectedRoleId={roleId}
          selectedLocationId={locationId}
          handleRoleChange={onChangeRole}
          disabledRoleIds={recurringRoles.map((r) => r.roleId)}
          placeholder={'Choose from existing roles'}
          errorMessage={getRoleSearchSelectErrorMessage(
            locationId,
            roleForShift,
          )}
          selectStyle={{ height: '48px' }}
          style={{ maxWidth: '100%' }}
        />
      )}
      <Row>
        <RoleCreationContent
          cannotCreateRole={userHasLimitedShiftManagementAbility}
          roles={roles}
          isEventCompany={isEventCompany}
          onCreateRole={onCreateRole}
          onCreateRoleFromPrevious={onCreateRoleFromPrevious}
        />
      </Row>
      <Row mt={theme.space.xs} gap={theme.space.sm} justifyBetween>
        <Col style={{ alignItems: 'start' }}>
          <Row alignCenter gap={theme.space.xsmed}>
            <Input
              containerStyle={{ marginTop: 0, maxWidth: '140px' }}
              type="number"
              placeholder="e.g. 10"
              value={minSlotsRequested > 0 ? `${minSlotsRequested}` : ''}
              onChange={onChangeSlotsRequested}
              onBlur={() => {
                // TODO(gavin): add analytics
                // window.analytics.track(`User Updated Slots Requested`, {
                //   slotsRequested: minSlotsRequested,
                //   isEdit,
                // })
              }}
            />
            <Text variant="body1">workers</Text>
          </Row>

          {payType === ShiftPayType.UNIT ? (
            <Row alignCenter gap={theme.space.xsmed}>
              <Input
                containerStyle={{
                  marginTop: theme.space.xxs,
                  maxWidth: '140px',
                }}
                type="number"
                placeholder="e.g. 10"
                value={Number(numberOfUnits) > 0 ? `${numberOfUnits}` : ''}
                onChange={onChangeNumberOfUnits}
                onBlur={() => {
                  // TODO(gavin): add analytics
                  // window.analytics.track(`User Updated Units Requested`, {
                  //   unitsRequested: numberOfUnits,
                  //   isEdit,
                  // })
                }}
              />
              <Text variant="body1">units</Text>
            </Row>
          ) : null}
        </Col>
        <SelectDropdown
          style={{ maxWidth: '190px' }}
          label={'Pay Type'}
          value={payType}
          handleSelect={onChangePayType}
          menuItems={payTypeOptions}
        />
        <TextField
          label={payType === ShiftPayType.UNIT ? 'Pay per unit' : 'Hourly pay'}
          id="payrate"
          style={{ maxWidth: '140px' }}
          type="number"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Text variant="body1">$</Text>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <Text variant="body1">
                  {payType === ShiftPayType.UNIT ? '  / unit' : ' / hr'}
                </Text>
              </InputAdornment>
            ),
          }}
          onChange={(e) => onRateChange(e.currentTarget.value)}
          onBlur={onRateBlur}
          value={maskedPayRate}
        />
        {pbuPayPerWorker ? (
          <Text variant="body2">
            (Estimated ${pbuPayPerWorker.toFixed(2)}/worker)
          </Text>
        ) : null}
      </Row>
      {slotsRequested > 0 &&
      minSlotsRequested > 0 &&
      numberOfUnits !== undefined &&
      validationError ? (
        <InlineBanner
          severity="error"
          text={validationError.message}
          style={{ marginTop: theme.space.xs }}
        />
      ) : null}
      {touchedPayRate && payValidationError ? (
        <InlineBanner
          severity="error"
          text={payValidationError.message}
          style={{ marginTop: theme.space.xs }}
        />
      ) : null}
      <Divider
        wrapperStyle={{
          margin: `${theme.space.sm}px 0`,
        }}
      />
    </Col>
  )
}

export default React.memo(RoleSelector)
