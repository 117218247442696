import { DEFAULT_TIMEZONE, MINIMUM_MINUTES_BEFORE_SHIFT } from '@traba/consts'
import { Button, LoadingSpinner, ToText } from '@traba/react-components'
import { ShiftPayType, ShiftRequestParentWithShiftRequest } from '@traba/types'
import { differenceInMinutes, format, isValid, startOfDay } from 'date-fns'
import { Col, InlineBanner, Row, Text } from 'src/components/base'
import DatePicker from 'src/components/base/AriaDatePicker/DatePicker'
import TimeField from 'src/components/base/AriaDatePicker/TimeField'
import { useCompany } from 'src/hooks/useCompany'
import { theme } from 'src/libs/theme'
import { BookShiftsProps } from 'src/screens/BookShifts/BookShiftsScreen'
import { BookShiftRoleSection } from 'src/screens/BookShifts/components/BookShiftRoleSection'
import {
  getTimeAfterTimeWithin24Hours,
  getTimeZoneAbbreviation,
} from 'src/shared/utils/dateUtils'
import styled from 'styled-components'
import { ButtonVariant } from '../../../components/base'
import {
  MAX_END_DATE,
  MAX_START_DATE,
  getDateError,
  getTimeError,
} from '../validation'

const MobileRow = styled(Row)`
  column-gap: ${({ theme }) => theme.space.med}px;
  @media only screen and (max-width: (${({ theme }) =>
      theme.media.maxExtraSmall})) {
    justify-content: flex-start;
    column-gap: ${({ theme }) => theme.space.xs}px;
  }
`

export default function AddShiftToSchedule({
  shiftRequestParent,
  onContinue,
  onPrevious,
  shiftRequest,
  setShiftRequest,
  recurringRoles,
  addNewRole,
  removeRole,
  updateRoleInfoForCreateShiftRequest,
  shiftRequestMetadata,
  setShiftRequestMetadata,
  onNavigate,
  userHasLimitedShiftManagementAbility,
  getWorkerById,
  setRecurringRoles,
  showRequiredMultiShiftToggle,
  showGenderPreferenceSelection,
}: BookShiftsProps & {
  shiftRequestParent: ShiftRequestParentWithShiftRequest
}) {
  const { company } = useCompany()

  const minutesAheadForShiftPosting =
    company?.minutesAheadForShiftPosting ?? MINIMUM_MINUTES_BEFORE_SHIFT

  if (!shiftRequestParent) {
    return <LoadingSpinner />
  }

  const schedule = shiftRequest.schedules[0]
  const { startTime, endTime, timeZone } = schedule
  const timezone = timeZone || DEFAULT_TIMEZONE
  const isOvernight = startTime.getDate() !== endTime.getDate()

  const endTextArr: string[] = []
  const timeZoneAbrv = getTimeZoneAbbreviation(startTime, timezone)
  const shiftStartDateText = `${timeZoneAbrv ? ` (${timeZoneAbrv})` : ''}`
  if (timeZoneAbrv) {
    endTextArr.push(timeZoneAbrv)
  }
  const overnightDate = isOvernight ? `${format(endTime, 'MM/dd/yy')}` : ''
  if (overnightDate) {
    endTextArr.push(overnightDate)
  }
  const shiftEndDateText = ` (${endTextArr.join(' ')})`

  function setStartTime(newStartTime: Date) {
    if (!isValid(newStartTime)) {
      return
    }
    if (newStartTime.getTime() > MAX_START_DATE.getTime()) {
      return
    }
    if (newStartTime.getTime() < startOfDay(new Date()).getTime()) {
      return
    }
    // make sure that end time is after start time
    const newEndTime = getTimeAfterTimeWithin24Hours(endTime, newStartTime)
    setShiftRequest({
      schedules: [
        {
          ...schedule,
          startTime: newStartTime,
          endTime: newEndTime,
        },
      ],
    })
  }

  function setEndTime(time: Date) {
    const newEndTime = getTimeAfterTimeWithin24Hours(time, startTime)
    if (!isValid(newEndTime)) {
      return
    }
    if (newEndTime.getTime() > MAX_END_DATE.getTime()) {
      return
    }
    if (newEndTime.getTime() < startOfDay(new Date()).getTime()) {
      return
    }
    setShiftRequest({
      schedules: [{ ...schedule, endTime: newEndTime }],
    })
  }

  const timeError = getTimeError(
    shiftRequest,
    minutesAheadForShiftPosting,
  )?.message
  const dateError = getDateError(
    shiftRequest,
    minutesAheadForShiftPosting,
  )?.message
  const durationMinutes = differenceInMinutes(endTime, startTime)
  const durationHours = Math.floor(durationMinutes / 60)
  const durationMinutesRemainder = durationMinutes % 60
  const timeSection = (
    <>
      <Text
        variant="h5"
        style={{
          margin: `${theme.space.lg}px 0 ${theme.space.sm}px`,
        }}
      >
        Start and end time for shift
      </Text>
      {shiftRequest.payType === ShiftPayType.HOURLY ? (
        <Text
          style={{ marginBottom: theme.space.sm, marginTop: theme.space.xs }}
        >
          We have a 4 hour minimum paid time policy for shifts.{' '}
          <Text
            variant="link"
            href="https://www.traba.work/business-faq#faq_question13"
            target="_blank"
          >
            Learn more
          </Text>
          .
        </Text>
      ) : (
        <Text
          variant="body2"
          style={{ marginBottom: theme.space.xs, marginTop: theme.space.xxs }}
        >
          While we understand that some shifts might take longer than expected,
          we use the end time to be able to better schedule workers' times.
        </Text>
      )}
      <MobileRow gap={theme.space.xxs} alignEnd>
        <Col gap={theme.space.xxs}>
          <Text variant="body3">Start Time {shiftStartDateText}</Text>
          <TimeField
            time={startTime}
            setTime={(newDate) => {
              newDate && setStartTime(newDate)
              window.analytics.track(`User Updated Start Time`, {
                time: newDate,
                schedule,
              })
            }}
            timezone={timezone}
          />
        </Col>
        <ToText />
        <Col gap={theme.space.xxs}>
          <Text variant="body3">End Time {shiftEndDateText}</Text>
          <TimeField
            time={endTime}
            setTime={(newDate) => {
              newDate && setEndTime(newDate)
              window.analytics.track(`User Updated End Time`, {
                time: newDate,
                schedule,
              })
            }}
            timezone={timezone}
          />
        </Col>

        <Text variant="body2">
          {durationHours} hours{' '}
          {durationMinutesRemainder
            ? `${durationMinutesRemainder} minutes`
            : null}
        </Text>
      </MobileRow>

      {timeError ? (
        <InlineBanner
          style={{ marginTop: theme.space.xs }}
          severity="error"
          text={timeError}
        />
      ) : isOvernight ? (
        <InlineBanner
          style={{ marginTop: theme.space.xs }}
          severity="warning"
          text={`This shift is overnight. It ends on ${overnightDate}.`}
        />
      ) : null}
    </>
  )

  return (
    <>
      <>
        <Text mb={theme.space.sm} variant="h5">
          Date of new shift
        </Text>
        <DatePicker
          date={startTime}
          setDate={(d) => {
            d && setStartTime(d)
            window.analytics.track(`User Updated Start Date`, {
              date: d,
              schedule,
            })
          }}
          isClearable={false}
          granularity="day"
          minDate={new Date(new Date().setHours(0, 0, 0, 0))}
          maxDate={MAX_START_DATE}
        />
        {dateError ? (
          <InlineBanner
            style={{ marginTop: theme.space.xs }}
            severity="error"
            text={dateError}
          />
        ) : null}
      </>
      {timeSection}
      {/* ROLES SECTION */}
      <Text variant="h5" mt={theme.space.xl}>
        Roles and workers for shift
      </Text>
      <Text variant="body2" mt={theme.space.xxs} mb={theme.space.xs}>
        Do you want the same roles and workers for this shift?
      </Text>
      {/* Roles list */}
      {recurringRoles.map((r) => (
        <BookShiftRoleSection
          key={r.roleId}
          role={r}
          shiftRequest={shiftRequest}
          recurringRoles={recurringRoles}
          addNewRole={addNewRole}
          removeRole={removeRole}
          updateRoleInfoForCreateShiftRequest={
            updateRoleInfoForCreateShiftRequest
          }
          onContinue={onContinue}
          onPrevious={onPrevious}
          setShiftRequest={setShiftRequest}
          setShiftRequestMetadata={setShiftRequestMetadata}
          shiftRequestMetadata={shiftRequestMetadata}
          onNavigate={onNavigate}
          userHasLimitedShiftManagementAbility={
            userHasLimitedShiftManagementAbility
          }
          showRequiredMultiShiftToggle={showRequiredMultiShiftToggle}
          showGenderPreferenceSelection={showGenderPreferenceSelection}
          getWorkerById={getWorkerById}
          setRecurringRoles={setRecurringRoles}
          // TODO(gavin): these are not used, will need to revisit making them optional
          selectedSingleShiftDates={null}
          setSelectSingleShiftDates={() => null}
          setModalType={() => true}
          onSaveChanges={async () => {
            return
          }}
          shiftUpdates={{}}
        />
      ))}
      <Row justifyEnd mt={theme.space.sm}>
        <Button
          onClick={addNewRole}
          variant={ButtonVariant.OUTLINED}
          disabled={recurringRoles.some((r) => !r.roleId)}
        >
          + Add Role
        </Button>
      </Row>
      <Row mt={theme.space.lg} justifyBetween>
        <Button onClick={onPrevious} variant={ButtonVariant.OUTLINED}>
          Previous
        </Button>
        <Button onClick={onContinue} disabled={!!dateError || !!timeError}>
          Continue
        </Button>
      </Row>
    </>
  )
}
