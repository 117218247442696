import { theme } from '@traba/theme'
import { InputStatus, User, USER_ROLE_OPTIONS, UserRole } from '@traba/types'
import { useCallback } from 'react'
import Input from '../../../base-components/Input/Input'
import Row from '../../../base-components/Row'
import { SelectDropdown } from '../../../base-components/Select/Select'

export interface ChangeMemberRoleProps {
  member: Pick<User, 'role' | 'firstName' | 'lastName'>
  role: UserRole
  changeMemberRole: (role: UserRole) => void
  errors?: Record<string, string>
  onBlur?: () => void
}

export function ChangeMemberRoleRow({
  member,
  role,
  changeMemberRole,
  errors,
  onBlur,
}: ChangeMemberRoleProps) {
  const { firstName, lastName } = member

  const onChangeRole = useCallback(
    (value: UserRole) => {
      changeMemberRole(value)
    },
    [changeMemberRole],
  )

  return (
    <Row gap={theme.space.xs} fullWidth>
      <div style={{ flex: 1 }}>
        <Input
          label="Selected member"
          name="member"
          full
          disabled
          onChange={() => undefined}
          value={`${firstName} ${lastName}`}
          containerStyle={{ margin: 0 }}
        />
      </div>
      <div style={{ flex: 1 }}>
        <SelectDropdown
          label="Role"
          name="role"
          menuItems={USER_ROLE_OPTIONS}
          value={role}
          handleSelect={onChangeRole}
          inputStatus={errors?.role ? InputStatus.error : undefined}
          errorMessage={errors?.role}
          onBlur={onBlur}
        />
      </div>
    </Row>
  )
}
