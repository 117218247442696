import { MODAL_SIZE } from '@traba/react-components'
import { DotMenu } from '@traba/react-components'
import { makePlural } from '@traba/string-utils'
import { useState } from 'react'

import { useNavigate } from 'react-router-dom'
import { useConnections } from 'src/hooks/useConnections'
import { theme } from 'src/libs/theme'
import { IconName } from 'src/types/svg-types'
import { getInitials } from 'src/utils/stringUtils'
import { Avatar, Badge, Divider, Modal, Row, Text } from '../base'
import { BlockWorkerForm } from '../BlockWorker/BlockWorker'
import { WorkerLinkText } from '../WorkerDetails/WorkerLinkText'
import { WorkerTableRowProps } from './WorkerTableRow'

export const MobileWorkerTableRow = (props: WorkerTableRowProps) => {
  const {
    worker,
    shiftHistory,
    userCanManageWorkers,
    setReviewModal,
    favoriteWorker,
    hideConnections,
  } = props

  const navigate = useNavigate()
  const [showBlockModal, setShowBlockModal] = useState<boolean>(false)
  const { firstName, lastName, photoUrl } = worker
  const { totalShiftCount, totalMinutesWorked } = shiftHistory
  const hours = Math.round((totalMinutesWorked || 0) / 60)
  const totalShiftsWithYouText = `${totalShiftCount} shift${makePlural(
    totalShiftCount,
  )} (${hours.toString()} hr${makePlural(hours)})`

  const initials = getInitials(firstName, lastName)
  const isBlocked = props.isBlocked(worker.uid)
  const isFavorite = props.isFavorite(worker.uid)
  const isIneligible = props.isIneligible(worker.uid)

  const { removeConnection } = useConnections()
  const actions = [
    {
      title: isFavorite ? 'Unfavorite' : 'Favorite',
      color: theme.colors.MidnightBlue,
      iconName: 'heart' as IconName,
      onClick: () => {
        if (isFavorite) {
          window.analytics.track(`User Clicked Unfavorite Worker`, {
            worker,
          })
          removeConnection(worker.uid)
          return
        }
        // Keeping both events for consistency w/ other connection changes.
        window.analytics.track(`User Clicked Favorite Worker`, {
          worker,
        })
        window.analytics.track(`Business Favorited Worker`, worker)
        // Favorite the worker and perform any side effects.
        favoriteWorker(worker.uid)
        setReviewModal({
          worker,
          workerShift: shiftHistory.mostRecentShift,
        })
      },
    },
    {
      title: isBlocked ? 'Unblock' : 'Block',
      color: theme.colors.MidnightBlue,
      iconName: (isBlocked ? 'unblock' : 'block') as IconName,
      onClick: () => {
        if (isBlocked) {
          window.analytics.track(`User Clicked Unblock Worker`, {
            worker,
          })
          removeConnection(worker.uid)
          return
        }
        // Keeping both events for consistency w/ other connection changes.
        window.analytics.track(`User Clicked Block Worker`, {
          worker,
        })
        setShowBlockModal(true)
      },
    },
  ]

  return (
    <>
      <Row justifyBetween pb={theme.space.xs} pt={theme.space.xs}>
        <Row>
          <Avatar src={photoUrl} name={initials} size={56} />
          <div style={{ marginLeft: theme.space.xxs }}>
            <WorkerLinkText
              variant="h6"
              onClick={() => navigate(`/worker/${worker.uid}`)}
            >
              {`${firstName} ${lastName}`}
            </WorkerLinkText>
            <Text>{totalShiftsWithYouText}</Text>
            {isFavorite && <Badge variant="brand" title="favorite" />}
            {isBlocked && <Badge variant="danger" title="blocked" />}
          </div>
        </Row>

        {!hideConnections &&
          userCanManageWorkers &&
          !isIneligible &&
          shiftHistory.mostRecentShift && (
            <DotMenu
              type="chat-menu"
              menuItems={actions}
              style={{
                marginLeft: theme.space.xs,
                height: 40,
                width: 40,
                padding: 0,
              }}
            />
          )}
      </Row>
      <Divider />
      <Modal
        size={MODAL_SIZE.LARGE}
        isOpen={showBlockModal}
        handleClose={() => setShowBlockModal(false)}
      >
        <BlockWorkerForm
          onClose={() => setShowBlockModal(false)}
          worker={worker}
          workerShift={shiftHistory.mostRecentShift}
        />
      </Modal>
    </>
  )
}
