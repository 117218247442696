import {
  BreakType,
  CompanyInvitation,
  GenderPreference,
  ShiftStatus,
  CompanyCategory,
  WorkerMedia,
  ConnectionReason,
  ShiftAttribute,
  RoleAttributeCategory,
  LocationResponse,
  UpdateShift,
  UserAccessLevel,
  USER_ROLE_NAMES,
  ReplacementSupervisorForUser,
} from '@traba/types'

import { SvgIconName } from '../assets/svg/icons'

export type Location = {
  latitude: number
  longitude: number
}

export type Timestamp = {
  seconds: number
  nanoseconds?: number
  toDate: () => Date
}

export type ScheduledBreak = {
  breakLength: number
  count: number
}

export type ICurrentShift = {
  shiftId: string
  workedShiftId: string
}

export type TimeDifference = {
  hoursTimeDifference: number
  minutesTimeDifference: number
  isShiftInProgress?: boolean
  daysTimeDifference?: number
}

export type StatusTextTemplate = {
  statusTextPrefix: string
  statusTime: string
  statusTextSuffix: string
}

export type CondensedWorker = {
  firstName: string
  lastName: string
  uid: string
}

export type valuesType = {
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
  zipCode: string
  password: string
  passwordConfirm: string
}

export type Money = {
  amount: number // this is the amount in cents
  currency: string
}

export enum JobStatus {
  ToDo = 'TO_DO',
  InProgress = 'IN_PROGRESS',
  OnBreak = 'ON_BREAK',
  Complete = 'COMPLETE',
  Canceled = 'CANCELED',
  NoShow = 'NO_SHOW',
  Appeased = 'APPEASED',
  Rejected = 'REJECTED',
  Abandoned = 'ABANDONED',
}

export interface CreatedAt {
  createdAt?: Date | null
}

export enum BusinessCancellationReasons {
  WORKERS_NO_LONGER_NEEDED = 'Workers No Longer Needed',
  CREATED_BY_MISTAKE = 'Created By Mistake',
  OTHER = 'Other',
}

// Firebase Types
export interface IFirebaseError extends Error {
  code: string
  message: string
  stack?: string
}

export interface ApiError extends Error {
  statusCode: number
  message: string
  error: string
}

export type UserProfile = {
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
  zipCode: string
  password?: string
  role: UserRole
  permissions: UserRolePermission[]
  photoUrl?: string
  userId?: string
  uid?: string
  stripeAccountId?: string
  termsOfUse?: TermsOfUse
  payment?: PaymentAccountStatus
  onboarding?: Onboarding
  companyId?: string
  createdAt?: Date
  communicationPreferences?: CommunicationPreferences
  communicationPermissions?: CommunicationPermissions
  smsConsent?: SmsConsent
  userAccessLevel: UserAccessLevel
  locations?: LocationResponse[]
} | null

export type SmsConsent = {
  agreedToSmsConsent?: boolean
  timeOfSmsConsent?: Date
}

export type CommunicationPreferences = {
  finalizeInvoice?: CommunicationSetting
  invoiceReminder?: CommunicationSetting

  // Shift SMS Codes
  shiftCodesSmsEnabled?: boolean
  onlyReceiveSupervisedShifts?: boolean

  // Shift Email Codes
  shiftCodesEmailEnabled?: boolean
  onlyReceiveSupervisedShiftsEmail?: boolean
}

export type CommunicationPermissions = {
  // Used for both SMS and Email: Needs refactor
  receiveAllShiftCodesSms?: boolean
}

type CommunicationSetting = {
  email: boolean
}

//Need to distinguish between these two types and maybe consolidate?
export interface UserData {
  phoneNumber?: string
  email?: string
  firstName?: string
  lastName?: string
  password?: string
  photoUrl?: string
  uid?: string
  companyId?: string
  role?: UserRole
  onboarding?: {
    hasCompleted?: boolean
    timeOfCompletion?: Date
  }
  communicationPermissions?: CommunicationPermissions
  userAccessLevel: UserAccessLevel
  locations?: LocationResponse[]
}

export interface UserWithRole extends UserData {
  role: UserRole
}

export type UserState = {
  userProfile: UserProfile | null
}

export type PaymentStatus = string

export type TermsOfUse = {
  agreedToTerms: boolean
  timeOfAgreement: Date
}

export type PaymentAccountStatus = {
  setupStatus: PaymentStatus
  updatedAt: Date
}
export type Onboarding = {
  hasCompleted: boolean
  timeOfCompletion: Date
}

export type StripeAccount = {
  charges_enabled: boolean
  payouts_enabled: boolean
}

export type ICodePayload = {
  [key: string]: boolean
}

export type TimeSheetInfo = {
  shiftLengthString: string
  grossPay: Money
  totalTrustAndSafetyFee: Money
  netPay: Money
}

export type QualifiedIncentives = {
  totalSignUpBonus?: Money
  initialBonus?: Money
  shiftsRequired?: number
  prefixText?: Money
  endDateString?: string
  referralBonus?: Money
}

export type Navigation = {
  navigate: Navigate
  goBack: () => void
}

export type Navigate = (
  routeName: NavigationRoute,
  params?: { [key: string]: any },
) => void

export type NavigationRoute =
  | 'ShiftCompleteScreen'
  | 'PaymentScreen'
  | 'TermsOfUse'
  | 'ExploreShiftsScreen '
  | 'ScheduleScreen'
  | 'RequirementsScreen'
  | 'PaymentScreen'
  | 'ShiftDetails'
  | 'PastShiftScreen'
  | 'OnShiftScreen'
  | 'Login'
  | 'Signup'
  | 'ForgotPassword'
  | 'StripeConnectScreen'
  | 'PaymentScreen'
  | 'DevActions'
  | 'BusinessReferralScreen'

export interface CreateRoleData {
  roleName: RoleData['roleName']
  roleDescription: RoleData['roleDescription']
  requiredAttire: RoleData['requiredAttire']
  requiredCertifications: string[]
  requiredAttributes: ShiftAttribute[]
  defaultPayRate: number
  genderPreference?: GenderPreference | null
  location?: RoleData['location']
  locationId?: string
}

export type RoleData = {
  roleId: string
  roleName: string
  roleDescription: string
  requiredAttire: string
  requiredCertifications?: string[]
  requiredAttributes?: ShiftAttribute[]
  freeformAttributes?: Partial<Record<RoleAttributeCategory, string>>
  videoIds?: string[]
  requiredTrainingIds?: string[]
  defaultPayRate: number
  genderPreference?: GenderPreference | null
  location?: LocationResponse
}

export interface CreateRoleData extends Omit<RoleData, 'roleId'> {}

export interface UpdateRoleDto extends Partial<CreateRoleData> {}

export interface UpdateRoleResponseDto {
  role?: RoleData
  previousRoleId?: string
  updatedShiftIds?: string[]
  failedToUpdateShiftIds?: string[]
}

export type Company = {
  companyId?: string
  category?: CompanyCategory
  referralCode?: string
  employerName: string
  createdAt?: Date
  address?: Address
  companyDescription?: string
  companyPhoneNumber?: string
  rating?: number
  baseMarkup?: number
  calculatedMarkup?: number
  workerMedia?: WorkerMedia[]
  bannerUrl?: string
  companyLogo?: string
  agreements?: {
    acceptedCustomerAgreement?: Date
    acceptedFeesAndTerms?: Date
  }
  breakType?: BreakType
}

export enum ConnectionType {
  BLOCK = 'BLOCK',
  FAVORITE = 'FAVORITE',
  INELIGIBLE = 'INELIGIBLE',
  SCHEDULED_BLOCK = 'SCHEDULED_BLOCK',
}

export type CreateConnection = {
  connectionType: ConnectionType
  workerId: string
  connectionReasons?: ConnectionReason[]
}

export type Connection = {
  id: string
  companyId: string
  connectionType: ConnectionType
  source: 'COMPANY'
  workerId: string
}

export type Address = {
  city: string
  location?: Location
  postalCode: string
  state: string
  street1: string
  street2?: string
  shortLocation?: string
}

export type LocationRequest = Omit<
  LocationResponse,
  | 'locationId'
  | 'companyId'
  | 'timezone'
  | 'recordStatus'
  | 'createdAt'
  | 'regionId'
  | 'activeLocationId'
> & {
  locationMedia?: string[]
  userIds?: string[]
  replacementSupervisorsForUsers?: ReplacementSupervisorForUser[]
}

export type Coordinates = {
  latitude: number
  longitude: number
}

export type InvoiceStatus =
  | 'deleted'
  | 'draft'
  | 'open'
  | 'paid'
  | 'uncollectible'
  | 'void'
  | 'unpaid' // this is the only status not defined on stripe, this was our original default status

export type Invoice = {
  companyId: string
  stripeInvoiceId: string
  stripeInvoiceNumber: string
  totalCharge: Money
  status: InvoiceStatus
  startDate: Date
  endDate: Date
  dueDate: Date
  totalShifts: number
  lineItems: LineItem[]
  hostedInvoiceUrl: string
  invoiceId: string
  createdAt: Date
  invoiceGroupId?: string
  updatedAt?: Date
}

export type LineItem = {
  shiftId: string
  type: 'SHIFT' | 'WORKER_SHIFT' | 'PENALTY' | 'BONUS'
  chargeToBusiness: Money
  workerId?: string
  grossPayToWorker?: Money
  totalTimeWorked?: number
  totalUnitsWorked?: number
  description: string
  date: Date
}

export enum InvoiceChargeCycle {
  GROUPED = 'GROUPED',
  WEEKLY = 'WEEKLY',
}

export type GoogleMapsPrediction = {
  placeId: string
  description: string
  formattedDescription: {
    main: string
    secondary: string
  }
}

type PaymentSettings = {
  stripeCustomerId: string
  email: string
  phoneNumber: string
}

type Card = {
  brand: SvgIconName
  expMonth: number
  expYear: number
  last4: string
}

export type PaymentMethod = {
  id: string
  card: Card
}

export type Billing = {
  billingId: string
  companyId: string
  paymentSettings: PaymentSettings
  automaticChargesEnabled: boolean
  paymentMethods: PaymentMethod[]
  additionalEmails?: string[]
}

export type DateRange = {
  startDate?: Date
  endDate?: Date
}

export type BusinessReferral = {
  referralCode: string
  sellerId?: string
  validityPeriods?: DateRange[]
  used: boolean
  usedOn?: Date
  reusable: boolean // if true, the token can be used for multiple companies. Probably shouldn't use this with full customer information.
  baseMarkup?: number
  calculatedMarkup?: number
}

export type Id = {
  id: string
}

export type SearchParams = {
  startBefore?: Date
  startAfter?: Date
  endBefore?: Date
  endAfter?: Date
  startAt?: string
  startAtIndex?: string
  sortBy?: string
  sortDir?: 'asc' | 'desc'
  timeApproved?: string
  totalCharge?: string
  id?: string
  limit?: number
  regionId?: string
  status?: ShiftStatus
  statuses?: ShiftStatus[]
  supervisorId?: string
  locationId?: string
  activeLocationIds?: string[]
  workerId?: string
  workerIds?: string[]
  workerShiftStatuses?: JobStatus[]
  shiftRequestParentIds?: string[]
  shiftIds?: string[]
}

export type UpdateShiftData = {
  shiftId: string
} & UpdateShift

export interface Certification {
  type: string
  name: string
}

export enum UserRole {
  Admin = 'ADMIN',
  Accounting = 'ACCOUNTING',
  LimitedShiftCreator = 'LIMITED_SHIFT_CREATOR',
  ShiftCreator = 'SHIFT_CREATOR',
  Supervisor = 'SUPERVISOR',
}

export const USER_ROLES = Object.values(UserRole)

export const USER_ROLE_OPTIONS = USER_ROLES.map((value) => ({
  value,
  label: USER_ROLE_NAMES[value],
}))

// NOTE: Viewing / managing the following resources is considered part of
// viewing / managing shifts, so the appropriate shift permission is used
// to determine access to them: roles, locations, POCs.
export enum UserRolePermission {
  ViewShifts = 'VIEW_SHIFTS',
  ManageShifts = 'MANAGE_SHIFTS',
  ViewWorkers = 'VIEW_WORKERS',
  ManageWorkers = 'MANAGE_WORKERS',
  ViewTimesheets = 'VIEW_TIMESHEETS',
  ManageTimesheets = 'MANAGE_TIMESHEETS',
  ViewInvoices = 'VIEW_INVOICES',
  ManageInvoices = 'MANAGE_INVOICES',
  ViewCompanySettings = 'VIEW_COMPANY_SETTINGS',
  ManageCompanySettings = 'MANAGE_COMPANY_SETTINGS',
  ViewPaymentSettings = 'VIEW_PAYMENT_SETTINGS',
  ManagePaymentSettings = 'MANAGE_PAYMENT_SETTINGS',
  ViewUserRoles = 'VIEW_USER_ROLES',
  ManageUserRoles = 'MANAGE_USER_ROLES',
  ViewPay = 'VIEW_PAY',
}

export interface UserRoleSetting {
  role: UserRole
  permissions: UserRolePermission[]
}

export type TrackingEvent = {
  message: string
  properties?: Record<string, unknown>
}

export type CompanyInvitationCreationData = Pick<
  CompanyInvitation,
  'email' | 'role' | 'invitedUserId' | 'userAccessLevel'
> & { locationIds?: string[] }

export type CompanyInvitationAcceptanceData = Pick<
  CompanyInvitation,
  'invitationId' | 'companyId'
> &
  Pick<Company, 'employerName' | 'companyLogo'>

export type CompanyInvitationAcceptanceDataWithSecret =
  CompanyInvitationAcceptanceData & {
    secret: string
  }

export interface TrainingVideo {
  createdAt: Date
  updatedAt: Date
  companyId: string
  requiredForAll: boolean
  name: string
  videoLink: string
  id: string
}

export interface CreateOrUpdateTrainingVideoData
  extends Omit<TrainingVideo, 'id' | 'createdAt' | 'companyId' | 'updatedAt'> {}
