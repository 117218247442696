import { MINIMUM_MINUTES_BEFORE_SHIFT } from '@traba/consts'
import { useAlert } from '@traba/context'
import {
  CancellationSource,
  CreateSchedule,
  CreateShiftRequest,
  CreateShiftRequestMetadata,
  RoleInfoForCreateShiftRequest,
  Shift,
  ShiftRequest,
  ShiftRequestEditInput,
  ShiftRequestEditType,
  ShiftRequestParentWithShiftRequest,
  ShiftStatus,
  UserAccessLevel,
} from '@traba/types'
import {
  combineTwoDatesForDateAndTime,
  getNextStartAndEndTime,
  isNewShiftDateAlignWithScheduleA,
  isObject,
} from '@traba/utils'
import { addMilliseconds, differenceInMilliseconds } from 'date-fns'
import { useEffect, useReducer, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useQueryParams } from 'src/helpers'
import { getRoleInfoForCreateShiftRequest } from 'src/screens/AddToExistingSchedule/utils'
import { BookShiftsProps } from 'src/screens/BookShifts/BookShiftsScreen'
import { BookShiftsConfirmContentShiftDataModel } from 'src/screens/BookShifts/steps/BookShiftsConfirmContentShiftDataModel'
import { BookShiftsWorkersContentShiftDataModel } from 'src/screens/BookShifts/steps/BookShiftsWorkersContentShiftDataModel'
import {
  getEndDateError,
  getMinHourlyPayRate,
  MIN_WORKER_HOURLY_PAY_DEFAULT,
  validateWorkersStepShiftDataModel,
} from 'src/screens/BookShifts/validation'
import { EditScheduleEndDate } from 'src/screens/EditSchedule/EditScheduleEndDate'
import { useCompany } from './useCompany'
import { createInvitation, useMembers } from './useMembers'
import useMobile from './useMobile'
import { useScheduleInvitations } from './useScheduleInvitations'
import {
  UpdatedShiftRequestInvitation,
  useBizShiftRequestParentEndDateMutation,
  useShiftRequestParent,
} from './useShiftRequestParent'
import { useHotSettings } from './useSystem'
import { useUser } from './useUser'
import { useWorkersByIds } from './useWorker'

type Step = {
  title: string
  Component: (props: BookShiftsProps) => JSX.Element
  validate?: (
    data: CreateShiftRequest,
    metadata: CreateShiftRequestMetadata,
  ) =>
    | {
        message: string
        title: string
      }
    | undefined
  hideInSideBar?: boolean
  type: StepType
}

export enum StepType {
  DETAILS = 'DETAILS',
  SCHEDULE = 'SCHEDULE',
  WORKERS = 'WORKERS',
  INVOICE = 'INVOICE',
  CONFIRM = 'CONFIRM',
}

function isRoleUpdated(
  updatedRole: RoleInfoForCreateShiftRequest,
  originalRole: RoleInfoForCreateShiftRequest,
): boolean {
  const keys = Object.keys(updatedRole)
  for (const key of keys) {
    const k = key as keyof RoleInfoForCreateShiftRequest
    if (k === 'shiftInvitations') {
      continue // we dont care about shift invitations for this flow
    } else if (k === 'scheduleInvitations') {
      const newScheduleInvitations = new Set(updatedRole[k]?.workerIds)
      const oldScheduleInvitations = new Set(originalRole[k]?.workerIds)
      if (newScheduleInvitations.size !== oldScheduleInvitations.size) {
        return true
      }
      for (const workerId of newScheduleInvitations) {
        if (!oldScheduleInvitations.has(workerId)) {
          return true
        }
      }
    } else if (isObject(originalRole[k]) || isObject(updatedRole[k])) {
      throw new Error(
        `Unhandled non-primitive type on RoleInfoForCreateShiftRequest key '${k}'`,
      )
    } else if (updatedRole[k] !== originalRole[k]) {
      return true
    }
  }
  return false
}

function getRoleUpdates(
  updatedRole: RoleInfoForCreateShiftRequest,
  originalRole: RoleInfoForCreateShiftRequest,
): Partial<ShiftRequest> {
  const diff: Partial<ShiftRequest> = {}

  const keys = Object.keys(updatedRole)
  for (const key of keys) {
    const k = key as keyof RoleInfoForCreateShiftRequest
    if (
      k === 'shiftInvitations' ||
      k === 'scheduleInvitations' ||
      k === 'minSlotsRequested' // this field is deprecated so we dont want to include it
    ) {
      continue // we don't care about invitations for creating the edit input object
    } else if (isObject(originalRole[k]) || isObject(updatedRole[k])) {
      throw new Error(
        `Unhandled non-primitive type on RoleInfoForCreateShiftRequest key '${k}'`,
      )
    } else if (updatedRole[k] !== originalRole[k]) {
      // TODO(gavin): there is a weird type error, will fix later
      // @ts-ignore
      diff[k] = updatedRole[k]
    }
  }

  return diff
}

export function useEditSchedule({
  shiftRequestParent,
  rolesTemplate,
  recurringShiftRequests,
}: {
  shiftRequestParent: ShiftRequestParentWithShiftRequest
  rolesTemplate: RoleInfoForCreateShiftRequest[]
  recurringShiftRequests: ShiftRequest[]
}) {
  const { editShiftRequestParent, refetch: refetchShiftRequestParent } =
    useShiftRequestParent(shiftRequestParent.shiftRequestParentId)
  const navigate = useNavigate()
  const scrollContainer = useRef<HTMLDivElement>(null)
  const { showError } = useAlert()
  const { user } = useUser()
  const { company } = useCompany()
  const { members } = useMembers()
  const { hotSettings } = useHotSettings()
  const { isReactNativeApp } = useMobile()
  const { updateShiftRequestParentEndDate } =
    useBizShiftRequestParentEndDateMutation()
  const location = useLocation()
  const [showSelectModal, setShowSelectModal] = useState<boolean>(false)
  const [isCreatingOrUpdating, setIsCreatingOrUpdating] = useState(false)
  const [isBookingInDraft, setIsBookingInDraft] = useState(true)
  const [selectedEffectiveDate, setSelectedEffectiveDate] =
    useState<Date | null>(null)
  const [activeStep, setActiveStep] = useState(0)
  const activeStepRef = useRef(activeStep)
  const isBookingInDraftRef = useRef(isBookingInDraft)
  const minHourlyPay = getMinHourlyPayRate({
    platformMinHourlyPay:
      hotSettings?.platformMinHourlyPayRate ?? MIN_WORKER_HOURLY_PAY_DEFAULT,
    companyMinHourlyPay: company?.minHourlyPayRate,
  })
  const [endDateChanged, setEndDateChanged] = useState<boolean>(false)
  const [dateReliantUpdate, setDateReliantUpdate] = useState<boolean>(false)

  const [recurringRoles, setRecurringRoles] = useState<
    RoleInfoForCreateShiftRequest[]
  >(
    rolesTemplate.map((shiftRequest) =>
      getRoleInfoForCreateShiftRequest({
        shiftRequest: shiftRequest,
        copyRoleId: true,
      }),
    ),
  )

  const [shiftRequest, setShiftRequest] = useReducer(
    (
      state: CreateShiftRequest,
      value: Partial<CreateShiftRequest>,
    ): CreateShiftRequest => ({
      ...state,
      ...value,
    }),
    recurringShiftRequests[0] as CreateShiftRequest, // TODO(gavin): figure out how to not cast this
  )

  const [shiftRequestMetadata, setShiftRequestMetadata] = useReducer(
    (
      state: CreateShiftRequestMetadata,
      value: Partial<CreateShiftRequestMetadata>,
    ): CreateShiftRequestMetadata => ({
      ...state,
      ...value,
    }),
    {
      scheduleExpanded: true,
      invitedContactRole: '',
      acceptedFeesAndTerms: undefined,
      activeRegion: null,
      activeParkingRegion: null,
      parkingLocationExpanded: !!recurringShiftRequests[0].parkingLocationId,
      shiftRequestParentTitle: shiftRequestParent.title ?? '',
      roleName: '',
      locationName: recurringShiftRequests[0].location?.name ?? '',
      recurringRoles: rolesTemplate ?? [],
      minutesAheadForShiftPosting:
        company?.minutesAheadForShiftPosting ?? MINIMUM_MINUTES_BEFORE_SHIFT,
      companyUsers: members,
    },
  )

  const query = useQueryParams()
  const dateParam = query.get('date')
  const preSelectedDate = dateParam ? new Date(dateParam) : undefined

  const EDIT_SCHEDULE_STEPS: Step[] = [
    {
      title: 'Summary',
      Component: BookShiftsConfirmContentShiftDataModel,
      type: StepType.CONFIRM,
    },
    // TODO(gavin): this will probably be asked for eventually
    // {
    //   title: 'Location',
    //   Component: BookShiftsDetailsContentShiftDataModel,
    //   validate: validateSiteStepShiftDataModel,
    //   type: StepType.DETAILS,
    // },

    ...(!preSelectedDate
      ? [
          {
            title: 'Schedule',
            Component: EditScheduleEndDate,
            validate: getEndDateError,
            type: StepType.SCHEDULE,
          },
        ]
      : []),
    {
      title: 'Workers',
      Component: BookShiftsWorkersContentShiftDataModel,
      validate: (data: CreateShiftRequest) =>
        validateWorkersStepShiftDataModel({
          data,
          minHourlyPay,
          recurringRoles,
          companyUsers: members,
        }),
      type: StepType.WORKERS,
    },
    // TODO(gavin): this will probably be asked for eventually
    // {
    //   title: 'Invoicing',
    //   Component: BookShiftsInvoiceContent,
    //   validate: validateInvoiceStep,
    //   type: StepType.INVOICE,
    // },
  ]

  // TODO(gavin): make this use enum instead of number
  function onNavigate(step: number) {
    // Too far back
    if (step < 0) {
      return
    }
    // Too far forward
    if (step >= EDIT_SCHEDULE_STEPS.length) {
      return onNavigate(0)
    }
    // Validate if advancing completion or active step already completed
    const shouldValidate = step > activeStep
    if (shouldValidate) {
      const { validate } = EDIT_SCHEDULE_STEPS[activeStep]
      if (validate) {
        const error = validate(shiftRequest, {
          ...shiftRequestMetadata,
          recurringRoles,
        })
        if (error) {
          showError(error.message, error.title)
          return
        }
      }
    }
    // Update the step and completion point, if needed
    setActiveStep(step)
    // Scroll to top upon step change
    scrollContainer.current?.scroll(0, 0)
    // Track the user's progress
    window.analytics.track(
      `User Navigated to Edit Schedule ${EDIT_SCHEDULE_STEPS[step].title} Step`,
    )
  }

  function onContinue() {
    onNavigate(activeStep + 1)
    window.scrollTo(0, 0)
  }

  function onPrevious() {
    setActiveStep(activeStep - 1)
    window.scrollTo(0, 0)
  }

  useEffect(() => {
    activeStepRef.current = activeStep
  }, [activeStep])

  useEffect(() => {
    isBookingInDraftRef.current = isBookingInDraft
  }, [isBookingInDraft])

  function addNewRole() {
    const newRole = getRoleInfoForCreateShiftRequest({ shiftRequest })
    setRecurringRoles((prev) => [...prev, newRole])
    setDateReliantUpdate(true)
  }

  function removeRole(roleId: string) {
    setRecurringRoles((prev) => prev.filter((r) => r.roleId !== roleId))
    setDateReliantUpdate(true)
  }

  function updateRoleInfoForCreateShiftRequest(
    updatedRoleInfoForCreateShiftRequest: RoleInfoForCreateShiftRequest,
    originalRoleId: string,
  ) {
    setDateReliantUpdate(true)
    setRecurringRoles((prevRecurringRoles) => {
      if (originalRoleId) {
        return prevRecurringRoles.map((r) =>
          r.roleId === originalRoleId
            ? updatedRoleInfoForCreateShiftRequest
            : r,
        )
      } else {
        let replaced = false
        return prevRecurringRoles.map((r) => {
          if (!r.roleId && !replaced) {
            replaced = true
            return updatedRoleInfoForCreateShiftRequest
          }
          return r
        })
      }
    })
  }

  function mapScheduleWithRecurringEndDate(
    schedule: CreateSchedule,
    firstShiftStartAndEndTime: { endTime: Date },
  ): CreateSchedule {
    return {
      ...schedule,
      ...(schedule.recurringSchedule &&
        preSelectedDate && {
          recurringSchedule: {
            ...schedule.recurringSchedule,
            endDate: firstShiftStartAndEndTime.endTime,
          },
        }),
    }
  }

  const { scheduleInvitations: existingScheduleInvitations = [] } =
    useScheduleInvitations(shiftRequestParent.shiftRequestParentId)

  const { getWorkerById } = useWorkersByIds(
    existingScheduleInvitations.flatMap(({ invitations }) =>
      invitations.map((i) => i.workerId),
    ),
  )

  async function onSaveChanges(shifts?: Shift[]) {
    // TODO(gavin): validate shifts
    // const shiftUpdates = validateShiftUpdates()

    // TODO(gavin): this will be needed if we need to kick workers off of shifts
    // if (shiftsRequiringWorkerRemoval.length > 0) {
    //   setIsEditSlotsModalOpen(true)
    //   return
    // }
    let effectiveShiftStartTime: Date | undefined
    let effectiveShiftEndTime: Date | undefined
    if (shifts) {
      const shift = shifts[0]
      const lengthOfShift = differenceInMilliseconds(
        shift.endTime,
        shift.startTime,
      )
      effectiveShiftStartTime = shift.originalStartTime
      effectiveShiftEndTime = addMilliseconds(
        shift.originalStartTime,
        lengthOfShift,
      )
    } else if (selectedEffectiveDate) {
      effectiveShiftStartTime = combineTwoDatesForDateAndTime(
        selectedEffectiveDate,
        recurringShiftRequests[0].schedules[0].startTime,
      )

      effectiveShiftEndTime = combineTwoDatesForDateAndTime(
        selectedEffectiveDate,
        recurringShiftRequests[0].schedules[0].endTime,
      )
    }
    if (!effectiveShiftStartTime || !effectiveShiftEndTime) {
      return
    }
    setIsCreatingOrUpdating(true)

    const prevInvitedWorkersByRoleId = new Map<
      string,
      { shiftRequestId: string; workerIds: string[] }
    >()

    for (const recurringShiftRequest of recurringShiftRequests) {
      const existingScheduleInvitation = existingScheduleInvitations.find(
        (i) => i.roleId === recurringShiftRequest.roleId,
      )
      if (existingScheduleInvitation) {
        const prevInvitedWorkerIds = existingScheduleInvitation.invitations.map(
          (i) => i.workerId,
        )
        prevInvitedWorkersByRoleId.set(recurringShiftRequest.roleId, {
          workerIds: prevInvitedWorkerIds,
          shiftRequestId: existingScheduleInvitation.shiftRequestId,
        })
      }
    }

    // check if any roles have been added, removed, or modified
    const newShiftRequests: CreateShiftRequest[] = []
    const removedRecurringRoles: RoleInfoForCreateShiftRequest[] = [
      ...rolesTemplate,
    ]
    const shiftRequestEdits: ShiftRequestEditInput[] = []
    const updatedShiftRequestInvitations: UpdatedShiftRequestInvitation[] = []

    for (const recurringRole of recurringRoles) {
      const existingRecurringRoleIndex = removedRecurringRoles.findIndex(
        (r) => r.roleId === recurringRole.roleId,
      )

      if (existingRecurringRoleIndex !== -1) {
        const existingRecurringRole =
          removedRecurringRoles[existingRecurringRoleIndex]

        if (isRoleUpdated(recurringRole, existingRecurringRole)) {
          const recurringShiftRequest = recurringShiftRequests.find(
            (rsr) => rsr.roleId === recurringRole.roleId,
          )

          if (!recurringShiftRequest) {
            // this should never happen
            throw new Error('ShiftRequest Does Not Exist when Editing Schedule')
          }

          shiftRequestEdits.push({
            ...getRoleUpdates(recurringRole, existingRecurringRole),
            shiftRequestId: recurringShiftRequest.shiftRequestId,
            editType: preSelectedDate
              ? ShiftRequestEditType.SINGLE
              : ShiftRequestEditType.ALL_FUTURE,
            originalStartTime: effectiveShiftStartTime,
          })

          const prevInvitedWorkers = prevInvitedWorkersByRoleId.get(
            recurringRole.roleId,
          )

          if (prevInvitedWorkers && recurringRole.scheduleInvitations) {
            const workerIdsToInvite =
              recurringRole.scheduleInvitations.workerIds.filter(
                (workerId) => !prevInvitedWorkers.workerIds.includes(workerId),
              )

            const shiftRequestToUpdate = shiftRequestParent.shiftRequests
              .filter((sr) => sr.schedules.some((s) => s.isRecurringSchedule))
              .find((sr) => sr.roleId === recurringRole.roleId)

            if (shiftRequestToUpdate) {
              updatedShiftRequestInvitations.push({
                workerIds: workerIdsToInvite,
                shiftRequestId: shiftRequestToUpdate.shiftRequestId,
              })
            }
          }
        }
        // Remove role from removedRecurringRoles array
        removedRecurringRoles.splice(existingRecurringRoleIndex, 1)
      } else {
        const templateShiftRequest = { ...shiftRequest }
        const firstShiftStartAndEndTime = {
          startTime: effectiveShiftStartTime,
          endTime: effectiveShiftEndTime,
        }
        templateShiftRequest.schedules = templateShiftRequest.schedules.map(
          (s) => mapScheduleWithRecurringEndDate(s, firstShiftStartAndEndTime),
        )
        const recurringSchedules = templateShiftRequest.schedules.filter(
          (s) => s.isRecurringSchedule,
        )

        if (recurringSchedules.length === 2) {
          const [scheduleA, scheduleB] = recurringSchedules
          const shouldUseSameSchedules = isNewShiftDateAlignWithScheduleA(
            effectiveShiftStartTime,
            scheduleA.startTime,
            scheduleB.startTime,
          )
          // This is when the new shift date is aligning with schedule A so the first week of the shift request should be scheduleA
          if (shouldUseSameSchedules) {
            const newScheduleA = {
              ...scheduleA,
              ...firstShiftStartAndEndTime,
            }
            const { startTime, endTime } = getNextStartAndEndTime(
              newScheduleA,
              scheduleB,
            )
            templateShiftRequest.schedules = [
              newScheduleA,
              { ...scheduleB, startTime, endTime },
            ]
          } else {
            // This is the case where the new shift date is aligning with schedule B so the first week of the shift request should be scheduleB
            const newScheduleB = {
              ...scheduleB,
              ...firstShiftStartAndEndTime,
            }
            const { startTime, endTime } = getNextStartAndEndTime(
              newScheduleB,
              scheduleA,
            )
            templateShiftRequest.schedules = [
              newScheduleB,
              { ...scheduleA, startTime, endTime },
            ]
          }
        } else {
          // Weekly case - schedules will be a 1-item array
          templateShiftRequest.schedules = [
            {
              ...templateShiftRequest.schedules[0],
              ...firstShiftStartAndEndTime,
            },
          ]
        }
        newShiftRequests.push({
          ...templateShiftRequest,
          ...recurringRole,
        })
      }
    }

    const canceledAt = new Date()
    for (const { roleId, shiftRequestId } of recurringShiftRequests) {
      if (!removedRecurringRoles.some((rrr) => rrr.roleId === roleId)) {
        continue
      }
      shiftRequestEdits.push({
        shiftRequestId,
        editType: preSelectedDate
          ? ShiftRequestEditType.SINGLE
          : ShiftRequestEditType.ALL_FUTURE,
        cancellationSource: CancellationSource.Business,
        cancellationReason: 'Cancelled by biz user',
        canceledAt,
        status: ShiftStatus.CANCELED,
        originalStartTime: effectiveShiftStartTime,
      })
    }

    if (shiftRequestMetadata.invitedContactRole) {
      const member = members.find((m) => m.uid === shiftRequest.supervisorId)
      if (member?.email) {
        window.analytics.track('User Invited Contact During Schedule Edit', {
          member: member.uid,
          role: shiftRequestMetadata.invitedContactRole,
        })
        await createInvitation({
          email: member.email,
          role: shiftRequestMetadata.invitedContactRole,
          invitedUserId: member.uid,
          userAccessLevel: UserAccessLevel.COMPANY_WIDE, // TODO(gail): ENG-11938 Update to add selector for access level & locations
          locationIds: [], // TODO(gail): ENG-11938 Update to add selector for access level & locations
        })
      }
    }

    await editShiftRequestParent({
      shiftRequestParentId: shiftRequestParent.shiftRequestParentId,
      newShiftRequests,
      shiftRequestEdits,
      updatedShiftRequestInvitations,
      shiftRequestParentMetadata: {
        title: shiftRequestMetadata.shiftRequestParentTitle,
      },
    })

    if (endDateChanged) {
      await updateShiftRequestParentEndDate({
        shiftRequestParentId: shiftRequestParent.shiftRequestParentId,
        endDate: shiftRequest.schedules[0].recurringSchedule?.endDate,
      })
    }
    refetchShiftRequestParent()
    setIsCreatingOrUpdating(false)
    // show success and error and log window analytics
    setIsBookingInDraft(false)
  }

  return {
    user,
    company,
    recurringRoles,
    setRecurringRoles,
    addNewRole,
    removeRole,
    updateRoleInfoForCreateShiftRequest,
    getWorkerById,
    existingScheduleInvitations,
    rolesTemplate,
    onSaveChanges,
    // navigation
    activeStep,
    onNavigate,
    onContinue,
    onPrevious,
    navigate,
    location,
    activeStepRef,
    isBookingInDraftRef,
    isBookingInDraft,
    scrollContainer,
    // other
    EDIT_SCHEDULE_STEPS,
    isReactNativeApp,
    showSelectModal,
    setShowSelectModal,
    isCreatingOrUpdating,
    // even more other?
    setSelectedEffectiveDate,
    shiftRequest,
    setShiftRequest,
    shiftRequestMetadata,
    setShiftRequestMetadata,
    preSelectedDate,
    endDateChanged,
    setEndDateChanged,
    dateReliantUpdate,
  }
}
