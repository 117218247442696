import { theme } from '@traba/theme'
import { IconName, SvgIconProps } from '@traba/types'
import Abandon from './svgs/Abandon'
import Add from './svgs/Add'
import AddChat from './svgs/AddChat'
import AddInverse from './svgs/AddInverse'
import Alert from './svgs/Alert'
import Archived from './svgs/Archived'
import { ArrowRight } from './svgs/ArrowRight'
import Award from './svgs/Award'
import { Back } from './svgs/Back'
import Block from './svgs/Block'
import Briefcases from './svgs/Briefcase'
import CabinetLight from './svgs/CabinetLight'
import Calendar from './svgs/Calendar'
import CalendarDay from './svgs/CalendarDay'
import CalendarLight from './svgs/CalendarLight'
import Cancel from './svgs/Cancel'
import CancelFilled from './svgs/CancelFilled'
import Certification from './svgs/Certification'
import Check from './svgs/Check'
import Checklist from './svgs/Checklist'
import { ChevronDown } from './svgs/ChevronDown'
import { ChevronLeft } from './svgs/ChevronLeft'
import { ChevronRight } from './svgs/ChevronRight'
import { ChevronUp } from './svgs/ChevronUp'
import CircleCheck from './svgs/CircleCheck'
import Clock from './svgs/Clock'
import ClockIn from './svgs/ClockIn'
import ClockOut from './svgs/ClockOut'
import ClockPurple from './svgs/ClockPurple'
import CloseChat from './svgs/CloseChat'
import Company from './svgs/Company'
import CrossDock from './svgs/CrossDock'
import Delivery from './svgs/Delivery'
import DistributionCenters from './svgs/DistributionCenters'
import Dollar from './svgs/Dollar'
import DollarPurple from './svgs/DollarPurple'
import Dot from './svgs/Dot'
import { DoubleBack } from './svgs/DoubleBack'
import { DoubleForward } from './svgs/DoubleForward'
import { DownArrow } from './svgs/DownArrow'
import Download from './svgs/Download'
import { DragHandle } from './svgs/DragHandle'
import DrinkLight from './svgs/DrinkLight'
import Dumbbell from './svgs/Dumbbell'
import Edit from './svgs/Edit'
import Ellipses from './svgs/Ellipses'
import EllipsesFilled from './svgs/EllipsesFilled'
import Equipment from './svgs/Equipment'
import Eye from './svgs/Eye'
import EyeSlash from './svgs/EyeSlash'
import FilledCheck from './svgs/FilledCheck'
import Filter from './svgs/Filter'
import Flash from './svgs/Flash'
import FoodPrepAndService from './svgs/FoodPrepAndService'
import FoodProduction from './svgs/FoodProduction'
import FormLight from './svgs/FormLight'
import ForwardArrow from './svgs/ForwardArrow'
import GraduationCap from './svgs/GraduationCap'
import Heart from './svgs/Heart'
import HeartHollow from './svgs/HeartHollow'
import Info from './svgs/Info'
import Language from './svgs/Language'
import Left from './svgs/Left'
import Lifting from './svgs/Lifting'
import Lightning from './svgs/Lightning'
import Link from './svgs/Link'
import Lipstick from './svgs/Lipstick'
import Location from './svgs/Location'
import LocationPin from './svgs/LocationPin'
import { Logo } from './svgs/Logo'
import ManuAndProd from './svgs/ManuAndProd'
import Manufacturing from './svgs/Manufacturing'
import Menu from './svgs/Menu'
import Message from './svgs/Message'
import MiniCheck from './svgs/MiniCheck'
import { Minus } from './svgs/Minus'
import NoShow from './svgs/NoShow'
import { Notification } from './svgs/Notification'
import PackageLight from './svgs/PackageLight'
import Pencil from './svgs/Pencil'
import PinLight from './svgs/PinLight'
import Plus from './svgs/Plus'
import Refresh from './svgs/Refresh'
import Reject from './svgs/Reject'
import Reset from './svgs/Reset'
import Right from './svgs/Right'
import RotateLeft from './svgs/RotateLeft'
import ScheduledBlock from './svgs/ScheduledBlock'
import Search from './svgs/Search'
import Send from './svgs/Send'
import Settings from './svgs/Settings'
import Share from './svgs/Share'
import ShieldCheck from './svgs/ShieldCheck'
import SimpleMessage from './svgs/SimpleMessage'
import Star from './svgs/Star'
import Subtract from './svgs/Subtract'
import SubtractInverse from './svgs/SubtractInverse'
import ThreePL from './svgs/ThreePL'
import ThumbsDown from './svgs/ThumbsDown'
import ThumbsUp from './svgs/ThumbsUp'
import Tire from './svgs/Tire'
import Training from './svgs/Training'
import Trash from './svgs/Trash'
import TwoUser from './svgs/TwoUser'
import Unblock from './svgs/Unblock'
import Undo from './svgs/Undo'
import Unfavorite from './svgs/Unfavorite'
import { UpArrow } from './svgs/UpArrow'
import User from './svgs/User'
import UserPlus from './svgs/UserPlus'
import UserSettings from './svgs/UserSettings'
import Warehousing from './svgs/Warehousing'

const DEFAULT_STROKE_WIDTH = 1.5
const DEFAULT_ICON_SIZE = 20

export const iconSvgAssets: Record<IconName, any> = {
  add: Add,
  addInverse: AddInverse,
  alert: Alert,
  back: Back,
  doubleBack: DoubleBack,
  doubleForward: DoubleForward,
  calendar: Calendar,
  calendarDay: CalendarDay,
  cancel: Cancel,
  cancelFilled: CancelFilled,
  check: Check,
  checklist: Checklist,
  edit: Edit,
  info: Info,
  plus: Plus,
  left: Left,
  location: Location,
  right: Right,
  rotateLeft: RotateLeft,
  eye: Eye,
  eyeSlash: EyeSlash,
  trash: Trash,
  download: Download,
  circleCheck: CircleCheck,
  chevronDown: ChevronDown,
  chevronUp: ChevronUp,
  chevronRight: ChevronRight,
  chevronLeft: ChevronLeft,
  dot: Dot,
  dumbbell: Dumbbell,
  star: Star,
  link: Link,
  menu: Menu,
  message: Message,
  pencil: Pencil,
  forwardArrow: ForwardArrow,
  subtract: Subtract,
  subtractInverse: SubtractInverse,
  thumbsUp: ThumbsUp,
  thumbsDown: ThumbsDown,
  twoUser: TwoUser,
  userPlus: UserPlus,
  user: User,
  search: Search,
  ellipses: Ellipses,
  noShow: NoShow,
  reject: Reject,
  abandon: Abandon,
  notification: Notification,
  arrowRight: ArrowRight,
  unfavorite: Unfavorite,
  clock: Clock,
  clockIn: ClockIn,
  clockOut: ClockOut,
  heart: Heart,
  unblock: Unblock,
  block: Block,
  addChat: AddChat,
  reset: Reset,
  closeChat: CloseChat,
  filter: Filter,
  archived: Archived,
  send: Send,
  locationPin: LocationPin,
  certification: Certification,
  warehousing: Warehousing,
  foodPrepAndService: FoodPrepAndService,
  manuAndProd: ManuAndProd,
  training: Training,
  language: Language,
  lifting: Lifting,
  equipment: Equipment,
  briefcase: Briefcases,
  miniCheck: MiniCheck,
  simpleMessage: SimpleMessage,
  shieldCheck: ShieldCheck,
  share: Share,
  flash: Flash,
  ellipsesFilled: EllipsesFilled,
  graduationCap: GraduationCap,
  refresh: Refresh,
  distributionCenters: DistributionCenters,
  foodProduction: FoodProduction,
  manufacturing: Manufacturing,
  userSettings: UserSettings,
  dollar: Dollar,
  company: Company,
  scheduledBlock: ScheduledBlock,
  award: Award,
  clockPurple: ClockPurple,
  dollarPurple: DollarPurple,
  heartHollow: HeartHollow,
  lightning: Lightning,
  drinkLight: DrinkLight,
  delivery: Delivery,
  formLight: FormLight,
  cabinetLight: CabinetLight,
  calendarLight: CalendarLight,
  pinLight: PinLight,
  threePL: ThreePL,
  tire: Tire,
  packageLight: PackageLight,
  crossDock: CrossDock,
  lipstick: Lipstick,
  logo: Logo,
  settings: Settings,
  undo: Undo,
  upArrow: UpArrow,
  downArrow: DownArrow,
  minus: Minus,
  filledCheck: FilledCheck,
  dragHandle: DragHandle,
}

export function SvgIcon(props: SvgIconProps) {
  const {
    name,
    color,
    size = DEFAULT_ICON_SIZE,
    strokeWidth = DEFAULT_STROKE_WIDTH,
    style,
  } = props
  let iconColor = color
  if (color === 'brand' || !color) {
    iconColor = theme.colors.Violet
  }
  const Component = iconSvgAssets[name]

  return (
    <Component
      size={size}
      color={iconColor}
      strokeWidth={strokeWidth}
      {...props}
      style={{
        ...style,
        ...(props.onClick && { cursor: 'pointer' }),
      }}
    />
  )
}
