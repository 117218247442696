import {
  EditMemberModalContent,
  EditMemberModalContentUpdateUser,
} from '@traba/react-components'
import {
  assignedLocationIdsForMember,
  getStartOfRecentFiveMinBlock,
} from '@traba/utils'
import { useCallback } from 'react'
import { useValidRegionsMap } from 'src/hooks/useLocationRegions'
import { useRegionLocationMap } from 'src/hooks/useLocations'
import { useMemberIdToMemberMap, useMembers } from 'src/hooks/useMembers'
import { useShiftsLocationsForSupervisor } from 'src/hooks/useShiftsLocationsForSupervisor'
import { UserWithRole } from 'src/types'

export interface EditMemberModalProps {
  member: UserWithRole
  handleClose: () => void
}

export function EditMemberModal({ member, handleClose }: EditMemberModalProps) {
  const {
    members,
    isLoading: isLoadingMembers,
    updateMember,
    isUpdateMemberLoading,
  } = useMembers()
  const { memberIdToMemberMap } = useMemberIdToMemberMap()
  const { regionMap, isLoading: isLoadingRegionMap } = useValidRegionsMap()
  const { activeRegionsWithLocationsMap, isLoading: loadingRegionLocationMap } =
    useRegionLocationMap()

  // using the start of the 5min block to take advantage of cached query with 5min stale-time
  const startOfLastFiveMinBlock = getStartOfRecentFiveMinBlock(
    new Date(),
  ).toISOString()

  const { locations } = useShiftsLocationsForSupervisor({
    supervisorId: member.uid,
    minShiftStartTime: startOfLastFiveMinBlock,
  })

  const onUpdateMember = useCallback(
    async (updatedMemberProps: EditMemberModalContentUpdateUser) => {
      await updateMember(
        {
          uid: member.uid,
          ...updatedMemberProps,
          locationIds:
            updatedMemberProps.locationIds ||
            assignedLocationIdsForMember(member),
          userAccessLevel:
            updatedMemberProps.userAccessLevel || member.userAccessLevel,
        },
        {
          onSuccess: handleClose,
        },
      )
    },
    [updateMember, handleClose, member],
  )

  return (
    <EditMemberModalContent
      member={member}
      handleClose={handleClose}
      onUpdateMember={onUpdateMember}
      loading={
        isLoadingMembers ||
        loadingRegionLocationMap ||
        isLoadingRegionMap ||
        isUpdateMemberLoading
      }
      replacementMembers={members}
      locationsForFutureShifts={locations}
      regionMap={regionMap}
      regionToLocationsMap={activeRegionsWithLocationsMap}
      memberIdToMemberMap={memberIdToMemberMap}
    />
  )
}
