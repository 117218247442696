import { FormControl, MenuItem, Select } from '@mui/material'
import { useAlert } from '@traba/context'
import {
  Card,
  Col,
  Input,
  LoadingSpinner,
  ModalPaddingContainer,
  RadioButton,
} from '@traba/react-components'
import {
  CancellationSource,
  Company,
  Shift,
  ShiftCancellationCostResponse,
  ShiftRequest,
  ShiftRequestEditType,
  ShiftStatus,
} from '@traba/types'
import { formatShiftDateWithTimezone } from '@traba/utils'
import { sum } from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Button,
  ButtonVariant,
  InlineBanner,
  Modal,
  Row,
  SvgIcon,
} from 'src/components/base'
import { Text } from 'src/components/base/Text'
import { CancelShiftLineItem } from 'src/components/CancelShiftLineItem'
import { createShiftRequestEdits } from 'src/hooks/useShiftRequestEdits'
import {
  useBizShiftRequestParentEndDateMutation,
  useShiftRequestParent,
} from 'src/hooks/useShiftRequestParent'
import {
  getShiftCancellationCost,
  useShiftsByShiftRequestId,
} from 'src/hooks/useShifts'
import { theme } from 'src/libs/theme'
import { ScheduleTile } from 'src/screens/Schedules/ScheduleTile/ScheduleTile'
import { BusinessCancellationReasons } from 'src/types'
import CancellationCost from '../Modals/CancelShiftModal/CancellationCost'

export type CancelScheduleModalProps = {
  allShifts: Shift[]
  firstRecurringShiftRequest: ShiftRequest
  handleClose: () => void
  company: Company
  shiftRequestParentId: string
  isOpen: boolean
}

export const CancelScheduleModal: React.FC<CancelScheduleModalProps> = (
  props: CancelScheduleModalProps,
) => {
  const {
    company,
    allShifts,
    firstRecurringShiftRequest,
    handleClose,
    shiftRequestParentId,
    isOpen,
  } = props

  const { showSuccess, showError } = useAlert()
  const { shiftRequestParent } = useShiftRequestParent(shiftRequestParentId)
  const { shifts: shiftsOfFirstSR } = useShiftsByShiftRequestId(
    firstRecurringShiftRequest.shiftRequestId,
    true,
  )
  const [loading, setLoading] = useState(false)
  const [isLoadingCancellationData, setIsLoadingCancellationData] =
    useState(false)
  const [reasonDropdownValue, setReasonDropdownValue] = useState<
    BusinessCancellationReasons | string
  >(BusinessCancellationReasons.CREATED_BY_MISTAKE)
  const [customReasonInput, setCustomReasonInput] = useState('')

  const firstShiftStartTime = useMemo(() => {
    if (!shiftsOfFirstSR) {
      return ''
    }
    const originalStartTime = shiftsOfFirstSR[0]?.originalStartTime
    const timezone = shiftsOfFirstSR[0]?.timezone
    if (!originalStartTime || !timezone) {
      return ''
    }
    return formatShiftDateWithTimezone(
      shiftsOfFirstSR[0]?.originalStartTime,
      shiftsOfFirstSR[0]?.timezone,
    )
  }, [shiftsOfFirstSR])

  const validShifts = useMemo(() => {
    return allShifts.filter((s) => s.status !== ShiftStatus.CANCELED)
  }, [allShifts])

  const [selectedOriginalStartTime, setSelectedOriginalStartTime] = useState<
    Date | undefined
  >()
  const [affectedShifts, setAffectedShifts] = useState<Shift[]>(validShifts)

  const [cancellationData, setCancellationData] =
    useState<ShiftCancellationCostResponse[]>()

  const { cancellationSettings, cancellationChargeSummary } =
    cancellationData?.length
      ? cancellationData[0]
      : {
          cancellationSettings: undefined,
          cancellationChargeSummary: ``,
        }

  const timeZone = affectedShifts?.[0]?.timezone

  // the reduce exists to deduplicate the start dates without losing time
  const uniqueOriginalStartTimes = useMemo(() => {
    const startDates: Date[] = []
    Array.from(
      validShifts.reduce((acc, s) => {
        const formattedDate = formatShiftDateWithTimezone(
          s.originalStartTime,
          timeZone,
        )
        if (!acc.has(formattedDate)) {
          startDates.push(s.originalStartTime)
          acc.add(formattedDate)
        }
        return acc
      }, new Set()),
    )
    return startDates
  }, [validShifts, timeZone])

  const { updateShiftRequestParentEndDate } =
    useBizShiftRequestParentEndDateMutation()

  const handleConfirm = async () => {
    setLoading(true)
    const cancellationReason =
      reasonDropdownValue === BusinessCancellationReasons.OTHER
        ? customReasonInput
        : reasonDropdownValue

    const canceledAt = new Date()
    if (!shiftRequestParent || !selectedOriginalStartTime) {
      return showError('There was an issue canceling, please try again')
    }
    try {
      await Promise.all(
        shiftRequestParent.shiftRequests.map((sr) =>
          createShiftRequestEdits({
            edit: {
              originalStartTime: selectedOriginalStartTime,
              shiftRequestId: sr.shiftRequestId,
              editType: ShiftRequestEditType.ALL_FUTURE,
              cancellationSource: CancellationSource.Business,
              cancellationReason: cancellationReason,
              canceledAt,
              status: ShiftStatus.CANCELED,
            },
          }),
        ),
      )
      await updateShiftRequestParentEndDate({
        shiftRequestParentId: shiftRequestParent.shiftRequestParentId,
        endDate: selectedOriginalStartTime,
      })
      showSuccess('Successfully canceled schedule')
    } catch (error) {
      showError('There was an issue canceling, please try again')
    } finally {
      setLoading(false)
      handleClose()
    }
  }

  const calculateShiftCancellationData = useCallback(
    (shiftId: string) => {
      const shiftCancellationData = cancellationData?.find(
        ({ shiftId: id }) => id === shiftId,
      )
      const { cancellations = [] } = shiftCancellationData || {}
      const numberWorkersAffected = cancellations.filter(
        (c) => c.totalCharge && c.totalCharge.amount > 0,
      ).length
      const [baseWorkerCancellation] = cancellations
      const singleWorkerCharge =
        baseWorkerCancellation && baseWorkerCancellation.totalCharge
          ? baseWorkerCancellation.totalCharge.amount / 100
          : 0

      const totalBusinessCharge =
        sum(cancellations.map((c) => c.totalCharge?.amount)) / 100

      return {
        numberWorkersAffected,
        singleWorkerCharge,
        totalBusinessCharge,
      }
    },
    [cancellationData],
  )

  const { singleWorkerCharge, totalCancellationCost, totalWorkersAffected } =
    useMemo(() => {
      const cancellationCosts = affectedShifts?.map((shift) =>
        calculateShiftCancellationData(shift.id),
      )
      const singleWorkerCharge =
        cancellationCosts?.find((s) => s.singleWorkerCharge > 0)
          ?.singleWorkerCharge || 0

      const totalCancellationCost = cancellationCosts?.reduce((acc, cost) => {
        const { totalBusinessCharge = 0 } = cost
        return acc + totalBusinessCharge
      }, 0)

      const totalWorkersAffected = cancellationCosts?.reduce((acc, cost) => {
        const { numberWorkersAffected = 0 } = cost
        return acc + numberWorkersAffected
      }, 0)

      return { singleWorkerCharge, totalCancellationCost, totalWorkersAffected }
    }, [affectedShifts, calculateShiftCancellationData])

  const renderSelectedCardTiles = () => {
    return affectedShifts.map((curShift) => {
      const { totalBusinessCharge, singleWorkerCharge } =
        calculateShiftCancellationData(curShift.id)

      if (singleWorkerCharge === 0) {
        return <></>
      }
      return (
        <CancelShiftLineItem
          shift={curShift}
          company={company}
          totalFee={totalBusinessCharge || 0}
          singleWorkerFee={singleWorkerCharge || 0}
          cancellationChargeSummary={cancellationChargeSummary}
          cancellationSettings={cancellationSettings}
        />
      )
    })
  }

  const fetchCancellationData = useCallback(async () => {
    setIsLoadingCancellationData(true)
    const promises = affectedShifts?.map(({ id = '' }) => {
      return getShiftCancellationCost(id)
    })
    const cancellations = await Promise.all(promises || [])
    setCancellationData(cancellations)
    setIsLoadingCancellationData(false)
  }, [affectedShifts])

  useEffect(() => {
    fetchCancellationData()
  }, [affectedShifts, fetchCancellationData])

  useEffect(() => {
    if (!selectedOriginalStartTime && shiftsOfFirstSR?.[0]) {
      setSelectedOriginalStartTime(shiftsOfFirstSR?.[0].originalStartTime)
    }
  }, [selectedOriginalStartTime, shiftsOfFirstSR])

  return (
    <Modal handleClose={handleClose} isOpen={isOpen}>
      <ModalPaddingContainer>
        <Row alignCenter justifyBetween mt={theme.space.xs} mb={theme.space.xs}>
          <Text variant="h4">Cancel schedule</Text>
          <SvgIcon
            name="cancel"
            color={theme.colors.MidnightBlue}
            onClick={handleClose}
          />
        </Row>

        <div>
          <ScheduleTile
            shiftRequestParent={shiftRequestParent}
            hideActionButtons
          />
          <Col gap={theme.space.xxs} mt={theme.space.sm}>
            <Row wrap>
              <Text variant="h5">
                Select after which date this schedule should be canceled
              </Text>
            </Row>
            <InlineBanner
              text={`For example, if you want to cancel all shifts on and after ${firstShiftStartTime}, select ${firstShiftStartTime}`}
            />
            <Card>
              {uniqueOriginalStartTimes?.map((originalStartTime) => (
                <Row
                  key={`shift-date-${originalStartTime}`}
                  alignCenter
                  gap={theme.space.xxs}
                  mt={theme.space.xxs}
                  onClick={() => {
                    const shiftsAffected = validShifts.filter(
                      (s) => s.originalStartTime >= originalStartTime,
                    )
                    setAffectedShifts(shiftsAffected)
                    setSelectedOriginalStartTime(originalStartTime)
                  }}
                >
                  <RadioButton
                    selected={originalStartTime === selectedOriginalStartTime}
                    size={20}
                  />
                  <Text>
                    {formatShiftDateWithTimezone(originalStartTime, timeZone)}
                  </Text>
                </Row>
              ))}
            </Card>
          </Col>

          <Text variant="h5" mt={theme.space.med} mb={theme.space.xxs}>
            Cancellation reason
          </Text>
          <FormControl fullWidth>
            <Select
              labelId="cancellation-dropdown"
              id="cancellation-dropdown"
              value={reasonDropdownValue}
              onChange={(evt) => {
                setReasonDropdownValue(evt.target.value)
              }}
              placeholder="Reason"
            >
              {Object.values(BusinessCancellationReasons).map((rsn) => (
                <MenuItem value={rsn}>{rsn}</MenuItem>
              ))}
            </Select>
            {reasonDropdownValue === BusinessCancellationReasons.OTHER && (
              <Input
                label="Reason"
                width="100%"
                value={customReasonInput}
                onChange={(e) => {
                  setCustomReasonInput(e.target.value)
                }}
              />
            )}
          </FormControl>
          {isLoadingCancellationData ? (
            <LoadingSpinner
              style={{
                margin: '40px 0px 16px 0px',
                paddingBottom: '24px',
                width: '100%',
              }}
            />
          ) : (
            <div style={{ marginTop: theme.space.med }}>
              <CancellationCost
                isPaidCancellation={totalCancellationCost > 0}
                totalCancellationCost={totalCancellationCost}
                singleWorkerCharge={singleWorkerCharge}
                renderSelectedCardTiles={renderSelectedCardTiles}
                cancellationSettings={cancellationSettings}
                cancellationChargeSummary={cancellationChargeSummary}
                totalWorkersAffected={totalWorkersAffected}
              />
            </div>
          )}
        </div>

        <Row justifyBetween mt={theme.space.sm}>
          <Button variant={ButtonVariant.OUTLINED} onClick={handleClose}>
            Discard
          </Button>
          <Button
            variant={ButtonVariant.CANCEL}
            onClick={handleConfirm}
            loading={loading}
          >
            Confirm cancellation
          </Button>
        </Row>
      </ModalPaddingContainer>
    </Modal>
  )
}
