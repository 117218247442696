import { Skeleton } from '@mui/material'
import { useHotSettings } from '@traba/hooks'
import { CardTile, Col, Row, Badge } from '@traba/react-components'
import { DotMenu } from '@traba/react-components'
import { COMPANY_WIDE_TEXT } from '@traba/types'
import { isEmpty } from 'lodash'
import React, { useCallback } from 'react'
import { Text } from 'src/components/base'
import { theme } from 'src/libs/theme'
import { RoleData } from 'src/types'
import { getMoneyString } from 'src/utils/moneyUtils'

import { Icon } from '../base'
import GenderBadge from '../GenderBadge'
import { RoleBadges } from '../RoleBadges'
import { DescriptionText } from './Role.styles'

// This is where the logic meets the UI. This separation allows use to pass the data directly to the UI in StoryBook

export type RoleProps = {
  role: RoleData
  edit?: (role: RoleData) => void
  archive?: (role: RoleData) => void
  duplicate?: (role: RoleData) => void
  userCanEdit?: boolean
  userCanViewWages?: boolean
  selected?: boolean
  radioButton?: boolean
  condensed?: boolean
  loading?: boolean
  handleSelect?: (roleId: string) => void
  showGenderPreference?: boolean
}

export const Role: React.FC<RoleProps> = (props) => {
  const { role, condensed, loading, handleSelect, edit, archive, duplicate } =
    props

  const { hotSettings } = useHotSettings()

  const roleActionMenuItems = []

  if (edit) {
    roleActionMenuItems.push({
      title: 'Edit role',
      onClick: () => {
        edit(role)
        window.analytics.track(`User Clicked Edit Role`, {
          role: role,
        })
      },
    })
  }

  if (hotSettings?.enableRegionalAccessPhase1 && duplicate) {
    roleActionMenuItems.push({
      title: 'Duplicate role',
      onClick: () => {
        duplicate(role)
        window.analytics.track(`User Clicked Duplicate Role`, {
          role: role,
        })
      },
    })
  }

  if (archive) {
    roleActionMenuItems.push({
      title: 'Archive role',
      onClick: () => {
        archive(role)
        window.analytics.track(`User Clicked Archive Role`, {
          role: role,
        })
      },
      color: theme.colors.Red60,
    })
  }

  const onClick = useCallback(() => {
    if (handleSelect) {
      handleSelect(role.roleId)
    }
  }, [handleSelect, role.roleId])

  if (loading || !role) {
    return <Skeleton />
  }

  if (condensed) {
    return (
      <Row alignCenter justifyBetween>
        <Row alignCenter justifyStart gap={theme.space.sm}>
          <CardTile size="56px">
            <Icon name="backPack" />
          </CardTile>
          <span>
            <Row alignCenter>
              <Text variant="h5">{role.roleName}</Text>
              <RoleBadges
                requiredCertifications={role.requiredCertifications}
              />
            </Row>
            <Text variant="body2">{role.roleDescription}</Text>
            {!isEmpty(role.requiredAttributes) && (
              <RoleBadges requiredAttributes={role.requiredAttributes} />
            )}
          </span>
        </Row>
      </Row>
    )
  }

  const actionable = props.userCanEdit && roleActionMenuItems.length > 0

  const locationBadgeText = role.location
    ? role.location.name || role.location.address.street1
    : COMPANY_WIDE_TEXT

  return (
    <div style={{ cursor: handleSelect ? 'pointer' : '' }} onClick={onClick}>
      <Col gap={theme.space.xs}>
        <Row alignCenter justifyBetween>
          <Row alignCenter justifyStart gap={theme.space.sm}>
            <CardTile size="56px">
              <Icon name="backPack" />
            </CardTile>
            <Col gap={theme.space.xxs}>
              <Row alignCenter style={{ flexWrap: 'wrap' }}>
                <Text variant="h5">{role.roleName}</Text>
                <RoleBadges
                  requiredCertifications={role.requiredCertifications}
                />
              </Row>
              {hotSettings?.enableRegionalAccessPhase1 && (
                <Row>
                  <Badge
                    iconName="location"
                    title={locationBadgeText}
                    variant={role.location ? 'business' : 'darkOrange'}
                  />
                </Row>
              )}
            </Col>
          </Row>
          {actionable && (
            <DotMenu
              type="role"
              dotMenuKey={role.roleId}
              menuItems={roleActionMenuItems}
            />
          )}
        </Row>
        <div>
          <Text variant="caption">DESCRIPTION</Text>
          <DescriptionText>{role.roleDescription}</DescriptionText>
        </div>
        {roleActionMenuItems.length > 0 && (
          <>
            <div>
              <Text variant="caption">REQUIRED ATTIRE</Text>
              <DescriptionText>{role.requiredAttire}</DescriptionText>
            </div>
            {!isEmpty(role.requiredAttributes) && (
              <div>
                <Text variant="caption">REQUIREMENTS</Text>
                <RoleBadges requiredAttributes={role.requiredAttributes} />
              </div>
            )}
            {props.showGenderPreference && !!role.genderPreference && (
              <div>
                <Text variant="caption">GENDER PREFERENCE</Text>
                <GenderBadge gender={role.genderPreference} />
              </div>
            )}
            {props.userCanViewWages && (
              <div>
                <Text variant="caption">DEFAULT PAY RATE</Text>
                <DescriptionText>
                  {getMoneyString(role.defaultPayRate)}/hr
                </DescriptionText>
              </div>
            )}
          </>
        )}
      </Col>
    </div>
  )
}
