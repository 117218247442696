import {
  Text,
  Button,
  ButtonVariant,
  DevelopmentFallbackComponent,
  SvgIcon,
  Row,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import React from 'react'
import { FallbackProps } from 'react-error-boundary'
import { BUSINESS_SUPPORT_EMAIL } from 'src/utils/supportUtils'
import { AuthScreenWrapper } from '../auth'
import { Badge } from '../base'

export function ErrorBoundaryFallback({
  error,
  resetErrorBoundary,
}: FallbackProps) {
  console.error(error) // log to dd
  return import.meta.env.VITE_ENV !== 'production' ? (
    <DevelopmentFallbackComponent
      error={error}
      resetErrorBoundary={resetErrorBoundary}
    />
  ) : (
    <AuthScreenWrapper
      leftCol={
        <>
          <Badge variant="danger" title="ERROR" />
          <Text variant="h2" mt={theme.space.xs}>
            Something went wrong.
          </Text>
          <Text variant="body2" mt={theme.space.xs}>
            We're experiencing technical difficulties. Please contact support if
            you need assistance.
          </Text>
          <Row>
            <Button
              variant={ButtonVariant.OUTLINED}
              style={{ marginTop: theme.space.sm }}
              onClick={() => {
                window.open(`mailto:${BUSINESS_SUPPORT_EMAIL}`)
              }}
            >
              <SvgIcon name="simpleMessage" color={theme.colors.Grey50} />
              <Text variant="h6" ml={theme.space.xxs}>
                Contact support
              </Text>
            </Button>
          </Row>
        </>
      }
    />
  )
}
