import { Col, MODAL_SIZE, Row, SearchBar } from '@traba/react-components'
import { Card } from '@traba/react-components'
import { LocationResponse } from '@traba/types'
import { getAddressString } from '@traba/utils'
import React, { useMemo, useState } from 'react'
import {
  Button,
  ButtonVariant,
  EmptyPlaceholderTile,
  Modal,
  Text,
} from 'src/components/base'
import { useUserPermissions } from 'src/hooks/useUser'
import { theme } from 'src/libs/theme'

import { UserRolePermission } from '../../types'
import { Plus } from '../assets/svg'
import { Location } from '../Location'
import { ArchiveLocationModal } from '../Modals/ArchiveLocationModal'
import { CreateOrEditLocationModal } from '../Modals/CreateOrEditLocationModal/CreateOrEditLocationModal'

export type LocationsProfileProps = {
  locations: LocationResponse[]
}

export const LocationsProfileUI: React.FC<LocationsProfileProps> = (
  props: LocationsProfileProps,
) => {
  const { locations } = props
  const [showCreateModal, setShowCreateModal] = useState(false)
  const userCanEditLocations = useUserPermissions([
    UserRolePermission.ManageShifts,
  ])

  const [showArchiveModal, setShowArchiveModal] = useState(false)
  const [locationToArchive, setLocationToArchive] = useState<
    LocationResponse | undefined
  >(undefined)
  const [searchFilter, setSearchFilter] = useState<string>('')
  const handleArchiveModalClose = () => {
    setShowArchiveModal(false)
    setLocationToArchive(undefined)
  }

  const handleArchive = (location: LocationResponse) => {
    setShowArchiveModal(true)
    setLocationToArchive(location)
  }

  const [showEditModal, setShowEditModal] = useState(false)
  const [locationToEdit, setLocationToEdit] = useState<
    LocationResponse | undefined
  >(undefined)

  const handleEditModalClose = () => {
    setShowEditModal(false)
    setLocationToEdit(undefined)
  }

  const handleEdit = (location: LocationResponse) => {
    setShowEditModal(true)
    setLocationToEdit(location)
  }

  const filteredLocations = useMemo(
    () =>
      locations.filter((location) => {
        if (!location) {
          return false
        }
        const lowerCaseFilter = searchFilter.toLowerCase()
        return (
          getAddressString(location.address)
            .toLocaleLowerCase()
            .includes(lowerCaseFilter) ||
          location.name?.toLowerCase().includes(lowerCaseFilter) ||
          location.shortLocation?.toLowerCase().includes(lowerCaseFilter) ||
          location.address.postalCode?.toLowerCase().includes(lowerCaseFilter)
        )
      }),
    [locations, searchFilter],
  )

  const locationsExistButNotInFilteredResults =
    filteredLocations.length === 0 && locations.length > 0
  const emptyListTitle = locationsExistButNotInFilteredResults
    ? 'No locations matching the filters'
    : 'Your locations will show up here'
  const emptyListSubtitle = locationsExistButNotInFilteredResults
    ? 'Change filtering to see your locations.'
    : 'You can add a location above.'

  return (
    <Col gap={theme.space.xs}>
      <Row alignStart justifyBetween>
        <Col>
          <Text mb={theme.space.xxs} variant="h5">
            Locations
          </Text>
          <Text variant="body2">
            {userCanEditLocations ? 'Manage' : 'View'} your company‘s locations.
          </Text>
        </Col>

        {userCanEditLocations && (
          <Button
            leftIcon={<Plus />}
            onClick={() => {
              setShowCreateModal(true)
            }}
            variant={ButtonVariant.FILLED}
          >
            Create new location
          </Button>
        )}
      </Row>

      <SearchBar
        searchPlaceholder="Search locations..."
        filtersKey="location"
        searchFilterText={searchFilter}
        setSearchFilterText={setSearchFilter}
      />

      {filteredLocations.length ? (
        filteredLocations
          .sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime())
          .map((location: LocationResponse) => (
            <Card
              key={location.locationId}
              borderWidth="1px"
              style={{ width: '100%' }}
            >
              <Location
                location={location}
                archive={handleArchive}
                edit={handleEdit}
                userCanEdit={userCanEditLocations}
              />
            </Card>
          ))
      ) : (
        <EmptyPlaceholderTile
          title={emptyListTitle}
          subtitle={emptyListSubtitle}
          iconName="folder"
        />
      )}

      <Modal
        size={MODAL_SIZE.LARGE}
        isOpen={showCreateModal}
        handleClose={() => setShowCreateModal(false)}
      >
        <CreateOrEditLocationModal setShowModal={setShowCreateModal} />
      </Modal>
      <Modal
        size={MODAL_SIZE.MEDIUM}
        isOpen={showArchiveModal}
        handleClose={handleArchiveModalClose}
      >
        {locationToArchive && (
          <ArchiveLocationModal
            location={locationToArchive}
            handleClose={handleArchiveModalClose}
          />
        )}
      </Modal>
      <Modal
        size={MODAL_SIZE.LARGE}
        isOpen={showEditModal}
        handleClose={handleEditModalClose}
      >
        {locationToEdit && (
          <CreateOrEditLocationModal
            location={locationToEdit}
            setShowModal={setShowEditModal}
          />
        )}
      </Modal>
    </Col>
  )
}
