import { Popper } from '@mui/material'
import React from 'react'
import { Helmet } from 'react-helmet'
import { useMediaQuery } from 'react-responsive'
import { useLocation } from 'react-router-dom'
import { Header, Sidebar } from 'src/components'
import { Row } from 'src/components/base'
import { useAppContext } from 'src/context/appContext/AppContext'
import { useUserContext } from 'src/context/user/UserContext'
import useMobile from 'src/hooks/useMobile'
import { MEDIA, theme } from 'src/libs/theme'

import { BackButton } from '../auth'
import { RegionalFilter } from '../base/RegionalFilterSelect/RegionalFilter'
import * as S from './MainLayout.styles'

type MainLayoutProps = {
  children: React.ReactNode
  hideSidebar?: boolean
  title?: string
  contentStyle?: React.CSSProperties
}

const baseScreenPaths = [
  '/dashboard',
  '/calendar',
  '/find-new-workers',
  '/workers',
  '/timesheet',
  '/billing',
  '/account-settings',
  '/refer',
]

export function MainLayout({
  children,
  hideSidebar,
  title,
  contentStyle,
}: MainLayoutProps) {
  const {
    isExclusivelyMobileView,
    isReactNativeApp,
    isMobileViewOrReactNative,
  } = useMobile()

  const userContext = useUserContext()
  const { userProfile } = userContext.state

  const appContext = useAppContext()
  const reactNativeHeaderRef = React.useRef<HTMLDivElement>(null)
  const {
    state: { isSideBarOpen, isRegionalFilterOpened },
  } = appContext
  const location = useLocation()
  const isPwa = window?.matchMedia('(display-mode: standalone)').matches
  const showBackButton = isPwa && !baseScreenPaths.includes(location.pathname)
  const isSmallView = useMediaQuery({
    maxWidth: MEDIA.SMALL,
  })

  return (
    <div style={{ height: '100%' }}>
      <Helmet defer={false}>
        <title>{title ?? 'Traba - Business'}</title>
      </Helmet>
      {/* ************* */}
      {/* Mobile Header */}
      {/* ************* */}
      <div ref={reactNativeHeaderRef}></div>
      {isExclusivelyMobileView && (
        <Header
          style={{
            position: isSmallView ? 'fixed' : undefined,
            background: 'white',
          }}
          userProfile={userProfile}
          className="xs-12"
        />
      )}

      <S.MainWrapper isReactNativeApp={isReactNativeApp}>
        {/* ********* */}
        {/* DT Header */}
        {/* ********* */}
        {showBackButton && (
          <BackButton
            className="xs-show md-hide"
            style={{ marginTop: theme.space.xs, marginLeft: theme.space.sm }}
            buttonText="Back"
          />
        )}
        {!isMobileViewOrReactNative && (
          <Header userProfile={userProfile} className="xs-hide md-12" />
        )}
        {!hideSidebar ? (
          <Row style={{ flex: 1, overflow: 'hidden', flexGrow: 1 }}>
            {/* *********** */}
            {/* DT Side Bar */}
            {/* *********** */}
            {!isReactNativeApp && (
              <S.LeftCol className="xs-hide sm-hide md-show">
                <Sidebar />
              </S.LeftCol>
            )}
            <S.RightCol style={{ flex: 1 }}>
              {/* ******************* */}
              {/* Mobile + DT Content */}
              {/* ******************* */}
              <S.Content isCalendar={title === 'Calendar'} style={contentStyle}>
                {children}
              </S.Content>
            </S.RightCol>
          </Row>
        ) : (
          children
        )}
      </S.MainWrapper>

      {isReactNativeApp && (
        <Popper
          anchorEl={reactNativeHeaderRef.current}
          open={isRegionalFilterOpened}
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            borderRadius: 10,
            boxShadow: `0px 0px 10px 0px ${theme.colors.Violet30}`,
            backgroundColor: theme.colors.White,
            overflow: 'hidden',
            paddingRight: theme.space.xxs,
            paddingLeft: theme.space.xxs,
            paddingTop: theme.space.xs,
            zIndex: 100, // Popper should be on top of everything
          }}
          placement="bottom"
          modifiers={[
            {
              name: 'offset',
              options: {
                offset: [
                  0,
                  isMobileViewOrReactNative ? theme.space.xxs : theme.space.xs,
                ],
              },
            },
          ]}
        >
          <RegionalFilter />
        </Popper>
      )}

      {/* *************** */}
      {/* Mobile Side Bar */}
      {/* *************** */}
      <S.DrawerCol
        className="xs-12 sm-12 md-hide"
        style={{ height: `100%`, position: 'fixed' }}
        isOpen={isSideBarOpen}
      >
        <Sidebar />
      </S.DrawerCol>
    </div>
  )
}
