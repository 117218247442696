import { Row, SearchBar } from '@traba/react-components'
import { theme } from '@traba/theme'
import { useCallback } from 'react'
import { LocationSingleSelectFilter } from './LocationSingleSelectFilter'

interface SearchWithLocationSelectionRowProps {
  filtersKey: string
  analyticsKey?: string
  searchFilterText: string
  setSearchFilterText: (newString: string) => void
  searchPlaceholder?: string
  selectedLocation?: string
  setSelectedLocation: (newString: string) => void
  allLocationsSelectedText?: string
  hideLocationSearch?: boolean
  useAllLocationsForSearch?: boolean
}

export function SearchWithLocationSelectionRow({
  searchPlaceholder = 'Search...',
  filtersKey,
  analyticsKey,
  searchFilterText,
  setSearchFilterText,
  selectedLocation,
  setSelectedLocation,
  allLocationsSelectedText,
  hideLocationSearch,
  useAllLocationsForSearch,
}: SearchWithLocationSelectionRowProps) {
  const onFocusSearchFilter = useCallback(() => {
    if (analyticsKey) {
      window.analytics.track(`User Clicked On ${analyticsKey} Search Filter`)
    }
  }, [])

  return (
    <Row justifyBetween alignCenter wrap gap={theme.space.xs}>
      <SearchBar
        searchFilterText={searchFilterText}
        setSearchFilterText={setSearchFilterText}
        filtersKey={filtersKey}
        searchPlaceholder={searchPlaceholder}
        onFocus={onFocusSearchFilter}
      />

      {!hideLocationSearch && (
        <LocationSingleSelectFilter
          selectedLocation={selectedLocation}
          setSelectedLocation={setSelectedLocation}
          allLocationsSelectedText={allLocationsSelectedText}
          useAllLocationsForSearch={useAllLocationsForSearch}
        />
      )}
    </Row>
  )
}
