import React from 'react'

import { useAccountSettings } from './AccountSettings.hooks'
import { AccountSettingsUI } from './AccountSettings.ui'

// This is where the logic meets the UI. This separation allows use to pass the data directly to the UI in StoryBook

export const AccountSettings = () => {
  const logic = useAccountSettings()
  return <AccountSettingsUI {...logic} />
}
