import { AccordionDetails } from '@mui/material'
import { makePlural } from '@traba/string-utils'
import { CSSProperties } from 'react'
import { Col, Icon, Text } from 'src/components/base'
import { ShiftAndAddress } from 'src/hooks/useShifts'
import { theme } from 'src/libs/theme'

import Row from '../base/Row'
import RightArrow from '../svgs/RightArrow'
import {
  CurrentShiftAccordion,
  CurrentShiftAccordionLink,
  CurrentShiftAccordionSummary,
} from './Sidebar.styles'

const DEFAULT_EXPANDED_NUM_SHIFTS_THRESHOLD = 5

const OnGoingShiftRow = ({
  shift,
  setIsSideBarOpen,
}: {
  shift: ShiftAndAddress
  setIsSideBarOpen: (value: boolean) => void
}) => {
  const overFlowStyle: CSSProperties = {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  }
  return (
    <CurrentShiftAccordionLink
      to={`/calendar/${shift.id}`}
      onClick={() => {
        setIsSideBarOpen(false)
      }}
    >
      <Row style={{ alignItems: 'center', padding: '12px 16px' }}>
        <Col
          style={{
            flexGrow: 1,
            overflow: 'hidden',
            marginRight: theme.space.sm,
          }}
        >
          <Text variant="h6" style={overFlowStyle}>
            {shift.shiftRole}
          </Text>
          <Row>
            <Col style={{ paddingRight: theme.space.us }}>
              <Icon name="location" style={{ width: theme.space.xs }} />
            </Col>
            <Col style={{ overflow: 'hidden' }}>
              <Text
                variant="body3"
                color={theme.palette.primary.main}
                style={overFlowStyle}
              >
                {shift.locationName || shift.shortLocation || ''}
              </Text>
            </Col>
          </Row>
        </Col>
        <Col>
          <RightArrow
            name="right"
            width={theme.space.xs}
            color={theme.palette.primary.main}
          />
        </Col>
      </Row>
    </CurrentShiftAccordionLink>
  )
}

export const SidebarCurrentShiftsAccordion = ({
  currentShifts,
  setIsSideBarOpen,
}: {
  currentShifts: ShiftAndAddress[]
  setIsSideBarOpen: (value: boolean) => void
}) => {
  const expandIcon = <Icon name="arrowBottom_primary" />

  return (
    <CurrentShiftAccordion
      defaultExpanded={
        currentShifts.length <= DEFAULT_EXPANDED_NUM_SHIFTS_THRESHOLD
      }
      disableGutters
    >
      <CurrentShiftAccordionSummary expandIcon={expandIcon}>
        <Text variant="h6" color={theme.colors.Violet}>
          Ongoing shift{makePlural(currentShifts.length)} (
          {currentShifts.length})
        </Text>
      </CurrentShiftAccordionSummary>
      <AccordionDetails
        sx={{
          borderTop: '1px solid',
          borderTopColor: theme.colors.Violet20,
          padding: 0,
        }}
      >
        {currentShifts.map((shift) => (
          <OnGoingShiftRow
            shift={shift}
            key={shift.shiftId}
            setIsSideBarOpen={setIsSideBarOpen}
          />
        ))}
      </AccordionDetails>
    </CurrentShiftAccordion>
  )
}
