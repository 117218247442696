import { GroupedLocationSingleSearchSelector } from '@traba/react-components'
import { useValidRegionsMap } from 'src/hooks/useLocationRegions'
import { useRegionLocationMap } from 'src/hooks/useLocations'

interface Props {
  selectedLocationId?: string
  // If filterLocationId is provided, the options will be limited to the company-wide option
  // and the location with the provided filterLocationId
  filterLocationId?: string
  disabled?: boolean
  onChange?: (locationId: string | undefined) => void
  errorMessage?: string
  onBlur?: () => void
  placeholder?: string
  label?: string
  hideCompanyWideOption?: boolean
  style?: React.CSSProperties
  selectStyle?: React.CSSProperties
}

export function LocationSingleSearchSelector({
  selectedLocationId,
  filterLocationId,
  disabled,
  onChange,
  errorMessage,
  onBlur,
  placeholder,
  label,
  hideCompanyWideOption,
  style,
  selectStyle,
}: Props) {
  const { isLoading: isLocationsLoading, activeRegionsWithLocationsMap } =
    useRegionLocationMap()
  const { isLoading: isRegionsLoading, regionMap } = useValidRegionsMap()

  return (
    <GroupedLocationSingleSearchSelector
      selectedLocationId={selectedLocationId}
      disabled={disabled}
      onChange={onChange}
      errorMessage={errorMessage}
      onBlur={onBlur}
      regionMap={regionMap}
      regionToLocationsMap={activeRegionsWithLocationsMap}
      isLoading={isLocationsLoading || isRegionsLoading}
      filterLocationId={filterLocationId}
      companyWideOptionSecondaryLabel="Default Setting. The role can be used in any location."
      placeholder={placeholder}
      label={label}
      hideCompanyWideOption={hideCompanyWideOption}
      style={style}
      selectStyle={selectStyle}
    />
  )
}
