import { useHotSettings } from '@traba/hooks'
import {
  Col,
  DotMenu,
  UserAccessLevelOrLocationsStringWithTooltip,
} from '@traba/react-components'
import {
  COMPANY_WIDE_ID,
  CompanyInvitation,
  USER_ROLE_NAMES,
} from '@traba/types'
import {
  assignedActiveLocationsForInvitation,
  containsText,
  isBizInvitationCompanyWide,
  isInvitationExpired,
  shouldDisplayInvitation,
} from '@traba/utils'
import { useCallback, useMemo, useState } from 'react'
import { Table, Td, Tr } from 'src/components'
import { useInvitations } from 'src/hooks/useMembers'
import { useUserCanManageUsers } from 'src/hooks/useUser'
import { theme } from 'src/libs/theme'
import { sortByDate } from 'src/shared/utils/dateUtils'
import { SearchWithLocationSelectionRow } from '../SearchWithLocationSelectionRow'
import { ALL_SELECTED_ID } from '../SearchWithLocationSelectionRow/LocationSingleSelectFilter'
import { MEMBERS_COPY } from './constants'
import { StyledTdEditMenuContainer } from './MembersProfile.styles'
import { MembersSubheader } from './MembersSubheader'

// Sort invitations by expiration date. Filter out invitations that have been
// accepted, rescinded, expired for more than two weeks, or have a more recent
// invitation for the same email.
function getDisplayInvitations(
  invitations?: CompanyInvitation[],
): CompanyInvitation[] {
  if (!invitations) {
    return []
  }
  invitations.sort((a, b) => sortByDate(a.expiresAt, b.expiresAt, 'DESC'))
  const displayInvitations = []
  const emails = new Set<string>()
  for (const i of invitations) {
    if (!shouldDisplayInvitation(i)) {
      continue
    }
    if (emails.has(i.email)) {
      continue
    }
    displayInvitations.push(i)
    emails.add(i.email)
  }
  return displayInvitations
}

interface InvitationsTableMenuProps {
  invitation: CompanyInvitation
  onResend: (invitationId: CompanyInvitation['invitationId']) => void
  onRescind: (invitationId: CompanyInvitation['invitationId']) => void
}

function InvitationsTableMenu({
  invitation,
  onResend,
  onRescind,
}: InvitationsTableMenuProps) {
  const expired = +invitation.expiresAt < Date.now()
  const menuItems = [
    {
      title: 'Resend invitation',
      onClick: () => {
        onResend(invitation.invitationId)
        window.analytics.track(`User Clicked Resend Invitation`, {
          invitation,
          expired,
        })
      },
    },
    ...(!expired
      ? [
          {
            title: 'Revoke invitation',
            onClick: () => {
              onRescind(invitation.invitationId)
              window.analytics.track(`User Clicked Revoke Invitation`, {
                invitation,
              })
            },
          },
        ]
      : []),
  ]
  return (
    <StyledTdEditMenuContainer>
      <DotMenu
        type="invitations"
        dotMenuKey={invitation.invitationId}
        menuItems={menuItems}
      />
    </StyledTdEditMenuContainer>
  )
}

interface InvitationsTableProps
  extends Omit<InvitationsTableMenuProps, 'invitation'> {
  invitations: CompanyInvitation[]
  userCanEdit: boolean
}

function InvitationsTable({
  invitations,
  userCanEdit,
  onResend,
  onRescind,
}: InvitationsTableProps) {
  const { hotSettings } = useHotSettings()
  return (
    <Table
      headers={[
        'Email',
        'Role',
        'Expires on',
        ...(hotSettings?.enableRegionalAccessPhase2 ? ['Locations'] : []),
        ...(userCanEdit ? [''] : []),
      ]}
      style={{
        marginBottom: invitations.length === 0 ? undefined : theme.space.lg,
      }}
      itemType="invitations"
      showEmptyState
    >
      {invitations.map((i) => {
        const expired = isInvitationExpired(i)
        return (
          <Tr key={i.invitationId}>
            <Td>{i.email}</Td>
            <Td>{USER_ROLE_NAMES[i.role]}</Td>
            <Td style={{ color: expired ? theme.colors.Red60 : undefined }}>
              {expired ? 'Expired ' : ''}
              {i.expiresAt.toLocaleDateString('en-us')}
            </Td>
            {hotSettings?.enableRegionalAccessPhase2 && (
              <Td>
                <UserAccessLevelOrLocationsStringWithTooltip
                  isCompanyWide={isBizInvitationCompanyWide(i)}
                  assignedLocations={assignedActiveLocationsForInvitation(i)}
                />
              </Td>
            )}
            {userCanEdit && (
              <InvitationsTableMenu
                invitation={i}
                onResend={onResend}
                onRescind={onRescind}
              />
            )}
          </Tr>
        )
      })}
    </Table>
  )
}

export function InvitationsSection() {
  const { hotSettings } = useHotSettings()
  const userCanEditActiveMembersAndInvitations = useUserCanManageUsers()

  const { invitations, resendInvitation, rescindInvitation } = useInvitations()
  const recentInvitations = getDisplayInvitations(invitations)

  const [searchFilterText, setSearchFilterText] = useState('')
  const [selectedLocation, setSelectedLocation] = useState(ALL_SELECTED_ID)

  const isAllLocationsSelected = selectedLocation === ALL_SELECTED_ID
  const isCompanyWideSelected = selectedLocation === COMPANY_WIDE_ID
  const selectedLocationId =
    !isAllLocationsSelected && !isCompanyWideSelected
      ? selectedLocation
      : undefined

  const filteredInvitations = useMemo(
    () =>
      recentInvitations.filter((i) => {
        const isMemberCompanyWide = isBizInvitationCompanyWide(i)
        const locationIdsForMember = new Set(
          assignedActiveLocationsForInvitation(i).map((loc) => loc.locationId),
        )

        const isMemberInSelectedLocation =
          isAllLocationsSelected ||
          (isMemberCompanyWide && isCompanyWideSelected) ||
          (selectedLocationId && locationIdsForMember.has(selectedLocationId))
        const showMemberBasedOnLocation = isMemberInSelectedLocation

        if (
          hotSettings?.enableRegionalAccessPhase2 &&
          !showMemberBasedOnLocation
        ) {
          return false
        }

        const textMatches = `${i.email} ${i.role ? USER_ROLE_NAMES[i.role] : ''}`
        return containsText(textMatches, searchFilterText)
      }),
    [
      recentInvitations,
      hotSettings?.enableRegionalAccessPhase2,
      searchFilterText,
      isAllLocationsSelected,
      isCompanyWideSelected,
      selectedLocationId,
    ],
  )

  const onResend = useCallback(
    (i: CompanyInvitation['invitationId']) => {
      resendInvitation(i)
    },
    [resendInvitation],
  )

  const onRescind = useCallback(
    (i: CompanyInvitation['invitationId']) => {
      rescindInvitation(i)
    },
    [rescindInvitation],
  )

  return (
    <Col gap={theme.space.xs}>
      <MembersSubheader
        title={MEMBERS_COPY.INVITATIONS_TITLE}
        description={
          userCanEditActiveMembersAndInvitations
            ? MEMBERS_COPY.INVITATIONS_EDITOR_DESCRIPTION
            : MEMBERS_COPY.INVITATIONS_VIEWER_DESCRIPTION
        }
      />
      <SearchWithLocationSelectionRow
        filtersKey="invitations"
        analyticsKey="Invitations"
        searchFilterText={searchFilterText}
        setSearchFilterText={setSearchFilterText}
        searchPlaceholder="Search by invitation email or role"
        selectedLocation={selectedLocation}
        setSelectedLocation={setSelectedLocation}
        hideLocationSearch={!hotSettings?.enableRegionalAccessPhase2}
        allLocationsSelectedText="All invitations"
        useAllLocationsForSearch
      />

      <div>
        <InvitationsTable
          invitations={filteredInvitations}
          userCanEdit={userCanEditActiveMembersAndInvitations}
          onResend={onResend}
          onRescind={onRescind}
        />
      </div>
    </Col>
  )
}
