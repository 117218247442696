import { MainLayout } from 'src/components'
import { AccountSettings } from 'src/components/AccountSettings'

export default function AccountSettingsScreen() {
  return (
    <MainLayout title="Account Settings">
      <AccountSettings />
    </MainLayout>
  )
}
