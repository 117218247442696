import { useAlert } from '@traba/context'
import { useHotSettings } from '@traba/hooks'
import {
  AssignMembersForLocationAndReplaceSupervisorsSection,
  Col,
  InputInfoCalloutRow,
} from '@traba/react-components'
import {
  FullAddress as Address,
  AddressDto,
  CoordinatesDto,
  InputStatus,
  LocationResponse,
  ReplacementSupervisorForUser,
  WorkerImage,
  WorkerMediaType,
} from '@traba/types'
import {
  allCompanyWideMemberIds,
  allMemberIdsForLocation,
  getReplacementSupervisorsForUsers,
  getStartOfRecentFiveMinBlock,
  getSupervisorsToReplaceAtLocation,
} from '@traba/utils'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { Input, Text } from 'src/components/base'
import PlacesAutocomplete from 'src/components/PlacesAutocomplete/PlacesAutocomplete'
import { useMemberIdToMemberMap, useMembers } from 'src/hooks/useMembers'
import { useShiftsSupervisorsForLocation } from 'src/hooks/useShiftsSupervisorsForLocation'
import { REQUIRED_FIELD_MESSAGE, VALID_ADDRESS } from 'src/libs/constants'
import { theme } from 'src/libs/theme'
import { LocationRequest } from 'src/types'
import * as yup from 'yup'
import MultiImageUploader from '../base/MultiImageUploader/MultiImageUploader'

export type LocationCreateOrEditFormData = {
  shortLocation: string
  name?: string
  address: AddressDto
  coords: CoordinatesDto
  locationInstructions: string
  existingMediaFiles: string[]
  newMediaFiles: File[]
  userIds?: string[]
  replacementSupervisorsForUsers?: ReplacementSupervisorForUser[]
}

export type LocationCreateOrEditFormProps = {
  onChange: (submitData: LocationCreateOrEditFormData, error?: boolean) => void
  onCancel: () => void
  location?: LocationResponse
}

export const LocationCreateOrEditForm: React.FC<
  LocationCreateOrEditFormProps
> = (props: LocationCreateOrEditFormProps) => {
  const { hotSettings } = useHotSettings()
  const { onChange, location } = props
  const { showError } = useAlert()
  const [newMediaFiles, setNewMediaFiles] = useState<File[]>([])
  const [touchedPlacesAutoComplete, setTouchedPlacesAutoComplete] =
    useState(false) // formik's touch handler does not apply to non-native inputs, so we have to use an explicit state
  const [existingMediaFiles, setExistingMediaFiles] = useState<string[]>(
    props.location?.media
      ? props.location?.media
          ?.filter((media) => media.type === WorkerMediaType.IMAGE)
          .map((media) => (media as WorkerImage).imageUrl)
      : [],
  )

  const { members, isLoading: isLoadingMembers } = useMembers()
  const { memberIdToMemberMap } = useMemberIdToMemberMap()

  // using the start of the 5min block to take advantage of cached query with 5min stale-time
  const startOfLastFiveMinBlock = getStartOfRecentFiveMinBlock(
    new Date(),
  ).toISOString()

  const { supervisorsForFutureShiftAtLocation } =
    useShiftsSupervisorsForLocation({
      locationId: location?.locationId,
      minShiftStartTime: startOfLastFiveMinBlock,
    })
  const [assignedMemberIds, setAssignedMemberIds] = useState<Set<string>>(
    new Set(
      allMemberIdsForLocation({
        locationId: location?.locationId,
        members,
      }),
    ),
  )
  const [replacementSupervisorMap, setReplacementSupervisorMap] = useState<
    Record<string, string>
  >({})

  async function onAddFile(f: File) {
    setNewMediaFiles((currentMediaFiles) => [...currentMediaFiles, f])
  }

  async function onDeleteFile(i: number) {
    if (i > existingMediaFiles.length - 1) {
      i -= existingMediaFiles.length
      setNewMediaFiles((currentMediaFiles) =>
        currentMediaFiles.filter((_, index) => i !== index),
      )
    } else {
      setExistingMediaFiles((currentMediaFiles) =>
        currentMediaFiles.filter((_, index) => i !== index),
      )
    }
  }
  const initialFormValues: LocationRequest = {
    name: location?.name ?? '',
    address: {
      city: location?.address.city ?? '',
      street1: location?.address.street1 ?? '',
      street2: location?.address.street2,
      postalCode: location?.address.postalCode ?? '',
      state: location?.address.state ?? '',
    },
    coords: {
      latitude: location?.coords.latitude ?? 0,
      longitude: location?.coords.longitude ?? 0,
    },
    shortLocation: location?.shortLocation ?? '',
    locationInstructions: location?.locationInstructions ?? '',
  }

  const validationSchema = yup.object({
    name: yup.string(),
    address: yup.object({
      city: yup.string().required(REQUIRED_FIELD_MESSAGE),
      street1: yup.string().required(REQUIRED_FIELD_MESSAGE),
      street2: yup.string(),
      postalCode: yup.string().required(REQUIRED_FIELD_MESSAGE),
      state: yup.string().required(REQUIRED_FIELD_MESSAGE),
    }),
    coords: yup.object({
      latitude: yup
        .number()
        .required(VALID_ADDRESS)
        .notOneOf([0], VALID_ADDRESS),
      longitude: yup
        .number()
        .required(VALID_ADDRESS)
        .notOneOf([0], VALID_ADDRESS),
    }),
    locationInstructions: yup.string(),
  })

  function handleChangePlacesAutocomplete(val: Address) {
    formik.setFieldValue('address', val)
    formik.setFieldValue('coords', val.location)
    formik.setFieldValue('shortLocation', val.shortLocation)
    setTimeout(formik.validateForm, 0) // Delay validation until after state update
  }

  const formik = useFormik({
    initialValues: initialFormValues,
    validationSchema,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: () => {
      //do nothing
    },
  })

  const { errors, touched, values } = formik
  useEffect(() => {
    const companyWideMemberIds = new Set(allCompanyWideMemberIds(members))
    const userIds = [...assignedMemberIds].filter(
      (memberId) => !companyWideMemberIds.has(memberId),
    )

    const supervisorIdsNeedingReplacement = new Set(
      getSupervisorsToReplaceAtLocation({
        assignedMemberIdsAtLocation: assignedMemberIds,
        supervisorsForFutureShiftAtLocation,
      }).map((supervisor) => supervisor.uid),
    )

    const replacementSupervisorsForUsers = getReplacementSupervisorsForUsers({
      replacementSupervisorMap,
      supervisorIdsNeedingReplacement,
    })

    const supervisorsAreNotFullyReplaced =
      supervisorIdsNeedingReplacement.size !==
      replacementSupervisorsForUsers.length

    onChange(
      {
        shortLocation: values.shortLocation || '',
        name: values.name,
        address: {
          city: values.address.city,
          street1: values.address.street1,
          street2: values.address.street2,
          postalCode: values.address.postalCode,
          state: values.address.state,
        },
        coords: values.coords,
        locationInstructions: values.locationInstructions,
        existingMediaFiles,
        newMediaFiles,
        userIds,
        replacementSupervisorsForUsers,
      },
      !!(
        errors.name ||
        errors.address ||
        errors.coords ||
        supervisorsAreNotFullyReplaced
      ),
    )
  }, [
    values,
    errors,
    existingMediaFiles,
    newMediaFiles,
    assignedMemberIds,
    members,
    replacementSupervisorMap,
    supervisorsForFutureShiftAtLocation,
  ])

  return (
    <div>
      <form onChange={formik.handleChange}>
        <Col mt={theme.space.xs} mb={theme.space.sm} gap={theme.space.xxs}>
          <Input
            full
            label="Location name (optional)"
            {...formik.getFieldProps('name')}
            inputStatus={touched.name && errors.name && formik.touched ? 3 : 1}
            errorMessage={errors.name}
          />

          <InputInfoCalloutRow text="This will help you when assigning team members, roles and rosters later on" />
        </Col>
        <Text variant="h5" style={{ marginBottom: theme.space.xs }}>
          Address
        </Text>
        <PlacesAutocomplete
          onSelect={handleChangePlacesAutocomplete}
          onChange={(val) => {
            setTouchedPlacesAutoComplete(true)
            formik.setFieldValue('address.street1', val)
          }}
          value={formik.values?.address?.street1}
          label="Street Address"
          errorMessage={
            touchedPlacesAutoComplete
              ? errors.address?.street1 ||
                errors.coords?.latitude ||
                errors.coords?.longitude
              : null
          }
        />
        <Input
          full
          label="Floor/Suite (optional)"
          {...formik.getFieldProps('address.street2')}
          inputStatus={
            touched.address?.street2 &&
            errors.address?.street2 &&
            formik.touched
              ? InputStatus.error
              : InputStatus.default
          }
          errorMessage={errors.address?.street2}
          containerStyle={{ marginTop: theme.space.xs }}
        />
        <Input
          full
          label="City"
          {...formik.getFieldProps('address.city')}
          inputStatus={
            touched.address?.city && errors.address?.city && formik.touched
              ? InputStatus.error
              : InputStatus.default
          }
          errorMessage={errors.address?.city}
          containerStyle={{ marginTop: theme.space.xs }}
        />
        <Input
          full
          label="State / Province"
          {...formik.getFieldProps('address.state')}
          inputStatus={
            touched.address?.state && errors.address?.state
              ? InputStatus.error
              : InputStatus.default
          }
          errorMessage={errors.address?.state}
          containerStyle={{ marginTop: theme.space.xs }}
        />
        <Input
          type="text"
          pattern="\d*"
          maxLength={5}
          full
          label="ZIP Code"
          {...formik.getFieldProps('address.postalCode')}
          inputStatus={
            touched.address?.postalCode && errors.address?.postalCode ? 3 : 1
          }
          errorMessage={errors.address?.postalCode}
          containerStyle={{ marginTop: theme.space.xs }}
        />
        <Text variant="h5" style={{ marginTop: theme.space.sm }}>
          Arrival instructions
        </Text>
        <Text variant="body3" style={{ marginTop: theme.space.xxs }}>
          Help workers get to the shift more easily by adding location or
          parking instructions and/or photos.
        </Text>
        <Text variant="body2"></Text>
        <Input
          placeholder="i.e. Once you park, enter the building through the door next to the sign and..."
          name="locationInstructions"
          containerStyle={{
            marginTop: theme.space.xs,
            marginBottom: theme.space.xxs,
          }}
          rows={3}
          type="textarea"
          defaultValue=""
          width="100%"
          value={formik.values.locationInstructions}
          onChange={formik.handleChange}
          style={{
            minHeight: 72,
          }}
        />
        <MultiImageUploader
          onAdd={onAddFile}
          onDelete={onDeleteFile}
          onError={showError}
          previewFiles={existingMediaFiles}
          maxFileSize={2}
        />

        {hotSettings?.enableRegionalAccessPhase2 && (
          <Col mt={theme.space.sm}>
            <AssignMembersForLocationAndReplaceSupervisorsSection
              locationId={location?.locationId}
              members={members}
              supervisorsForLocation={supervisorsForFutureShiftAtLocation}
              assignedMemberIds={assignedMemberIds}
              setAssignedMemberIds={setAssignedMemberIds}
              replacementSupervisorMap={replacementSupervisorMap}
              setReplacementSupervisorMap={setReplacementSupervisorMap}
              memberIdToMemberMap={memberIdToMemberMap}
              loading={isLoadingMembers}
            />
          </Col>
        )}
      </form>
    </div>
  )
}
