import * as Sentry from '@sentry/react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { trabaApi } from '@traba/api-utils'
import { HALF_MINUTE_IN_MS } from '@traba/consts'
import { useAlert } from '@traba/context'
import { makePlural } from '@traba/string-utils'
import { InvitedShift } from '@traba/types'
import { getErrorMessage } from '@traba/utils'

const INVITED_SHIFTS_QUERY_KEY = 'invited-shifts'

type InvitedShiftsParams = {
  workerId: string
  startAfter: Date | null
  startBefore: Date | null
}

type InvitedShiftsResponse = {
  invitations: InvitedShift[]
}

type BulkRescindInvitationsParams = {
  workerId: string
  shiftInvitationIds: string[]
}

const getInvitedShifts = async ({
  workerId,
  startAfter,
  startBefore,
}: InvitedShiftsParams) => {
  if (startAfter === null || startBefore === null) {
    return { invitations: [] }
  }
  try {
    const res = await trabaApi.get(
      `/my-company/workers/${workerId}/invitations`,
      {
        params: {
          startAfter: startAfter.toISOString(),
          startBefore: startBefore.toISOString(),
        },
      },
    )
    return res.data
  } catch (error) {
    const errorMessage = getErrorMessage(error)
    const fullErrorMessage = `getInvitedShifts ERROR: ${errorMessage}`
    Sentry.captureException(fullErrorMessage)
    throw error
  }
}

const bulkRescindInvitations = async ({
  workerId,
  shiftInvitationIds,
}: BulkRescindInvitationsParams) => {
  try {
    const res = await trabaApi.post(
      `/my-company/workers/${workerId}/invitations/rescind`,
      {
        shiftInvitationIds,
      },
    )
    return res.data
  } catch (error) {
    const errorMessage = getErrorMessage(error)
    const fullErrorMessage = `bulkRescindInvitations ERROR: ${errorMessage}`
    Sentry.captureException(fullErrorMessage)
    throw error
  }
}

export function useInvitedShifts({
  workerId,
  startAfter,
  startBefore,
}: {
  workerId: string
  startAfter: Date | null
  startBefore: Date | null
}) {
  const {
    isLoading,
    isError,
    isFetched,
    data: response,
    error,
    refetch,
  } = useQuery<InvitedShiftsResponse, Error>({
    queryKey: [INVITED_SHIFTS_QUERY_KEY, workerId, startAfter, startBefore],
    queryFn: () => getInvitedShifts({ workerId, startAfter, startBefore }),
    staleTime: HALF_MINUTE_IN_MS,
  })

  const { showSuccess, showError } = useAlert()
  const bulkRescindInvitationsMutation = useMutation<
    void,
    Error,
    BulkRescindInvitationsParams
  >({
    mutationFn: bulkRescindInvitations,
    onSuccess: (_res, { shiftInvitationIds }) => {
      refetch()
      showSuccess(`Invitation${makePlural(shiftInvitationIds)} rescinded`)
    },
    onError: (error, { shiftInvitationIds }) => {
      console.error(error)
      showError(`Failed to rescind invitation${makePlural(shiftInvitationIds)}`)
    },
  })

  return {
    isLoading,
    isError,
    isFetched,
    invitedShifts: response?.invitations || [],
    error,
    refetch,
    bulkRescindInvitations: bulkRescindInvitationsMutation.mutate,
  }
}
