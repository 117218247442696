import { theme } from '@traba/theme'
import {
  COMPANY_WIDE_TEXT,
  IconName,
  InputStatus,
  LocationResponse,
  User,
} from '@traba/types'
import {
  getLocationNameOrTruncatedAddress,
  getUserFullName,
  isBizMemberCompanyWide,
} from '@traba/utils'
import React, { useCallback, useState } from 'react'
import { LoadingSpinner, Row, SearchSelect, Col } from '../base-components'
import { IMenuItem } from '../base-components/Select/Select'
import { SvgIcon } from '../base-components/SvgIcon'
import { Text } from '../base-components/Text'

export type ReplaceSupervisorForLocationsSectionProps = {
  member: Partial<User>
  replacementMembers: Partial<User>[]
  memberIdToMemberMap: Map<string, Partial<User>>
  locations: LocationResponse[]
  replacementSupervisorMap: Record<string, string> // this map represents the locationIdToReplaceThisMember -> replacementMemberId
  setReplacementSupervisorMap: React.Dispatch<
    React.SetStateAction<Record<string, string>>
  >
  loading?: boolean
}

const createItemFromMember = (member: Partial<User>): IMenuItem => {
  const tagsRow = isBizMemberCompanyWide(member)
    ? [
        {
          title: COMPANY_WIDE_TEXT,
          iconName: 'location' as IconName,
          variant: 'darkOrange',
        },
      ]
    : (member.locations || []).map((loc) => ({
        title: getLocationNameOrTruncatedAddress(loc),
        iconName: 'location' as IconName,
        variant: 'business',
      }))
  return {
    value: member.uid as string,
    label: getUserFullName(member),
    tagsRow,
  }
}

export function ReplaceSupervisorForLocationsSection({
  member,
  replacementMembers,
  memberIdToMemberMap,
  locations,
  replacementSupervisorMap,
  setReplacementSupervisorMap,
  loading,
}: ReplaceSupervisorForLocationsSectionProps) {
  const [replacementErrors, setReplacementErrors] = useState<
    Record<string, string | undefined>
  >({})

  const handleSetReplaceUserForLocation = useCallback(
    (locationId: string) => (menuItem: IMenuItem | undefined) => {
      const newUserId = menuItem?.value
      setReplacementSupervisorMap((prevMap) => ({
        ...prevMap,
        [locationId]: newUserId || '',
      }))
      setReplacementErrors((prevErrors) => ({
        ...prevErrors,
        [locationId]: undefined,
      }))
    },
    [setReplacementSupervisorMap],
  )

  const getReplacementMembersForLocation = useCallback(
    (locationId: string) => {
      return replacementMembers
        .filter((m) => {
          const isNotCurrentUser = m.uid !== member.uid
          const isUserInLocation =
            isBizMemberCompanyWide(m) ||
            m.locations?.find((loc) => locationId === loc.locationId)
          return isNotCurrentUser && isUserInLocation
        })
        .map(createItemFromMember)
    },
    [replacementMembers, member.uid],
  )

  const onBlurSelect = (locationId: string) => () => {
    if (!replacementSupervisorMap[locationId]) {
      setReplacementErrors((prevErrors) => ({
        ...prevErrors,
        [locationId]: 'You must select a replacement member.',
      }))
    }
  }

  if (loading) {
    return <LoadingSpinner />
  }

  const emptyItem = { label: `--`, value: '' }

  return (
    <Col gap={theme.space.sm}>
      {locations.map((loc) => {
        const selectedMemberId = replacementSupervisorMap[loc.locationId]
        const selectedMember = memberIdToMemberMap.get(selectedMemberId)
        const selectedMemberItem = selectedMember
          ? createItemFromMember(selectedMember)
          : emptyItem

        return (
          <Col key={loc.locationId} gap={theme.space.xxs}>
            <Row gap={theme.space.xxxs} alignCenter>
              <SvgIcon name="location" size={16} color={theme.colors.Grey50} />
              <Text variant="h6">{getLocationNameOrTruncatedAddress(loc)}</Text>
            </Row>
            <SearchSelect
              placeholder="Select a replacement supervisor"
              selectItem={selectedMemberItem}
              handleSelect={handleSetReplaceUserForLocation(loc.locationId)}
              options={[
                emptyItem,
                ...getReplacementMembersForLocation(loc.locationId),
              ]}
              style={{ width: '100%' }}
              menuListStyle={{ maxHeight: '300px' }}
              onlyShowLabel
              onBlur={onBlurSelect(loc.locationId)}
              inputStatus={
                replacementErrors[loc.locationId]
                  ? InputStatus.error
                  : undefined
              }
              errorMessage={replacementErrors[loc.locationId]}
            />
          </Col>
        )
      })}
    </Col>
  )
}
