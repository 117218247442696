import { theme } from '@traba/theme'
import {
  LocationResponse,
  RegionIdToRegionMap,
  ReplacementSupervisorUserResponse,
  User,
  UserAccessLevel,
} from '@traba/types'
import {
  assignedLocationIdsForMember,
  getLocationsToReplaceSupervisorsForMember,
} from '@traba/utils'
import { useCallback, useMemo } from 'react'
import { Col, LoadingSpinner, Row, SvgIcon, Text } from '../base-components'
import { RegionIdToPartialLocationMap } from '../base-components/selectors/GroupedLocationSearchSelector/types'
import { AssignAccessLevelAndLocationsRow } from './AssignAccessLevelAndLocationsRow'
import { ReplaceSupervisorForLocationsSection } from './ReplaceSupervisorForLocationsSection'

export type AssignLocationsForMemberAndReplaceSupervisorsSectionProps = {
  member: Pick<
    User,
    'role' | 'firstName' | 'lastName' | 'userAccessLevel' | 'locations'
  >
  userAccessLevel?: UserAccessLevel
  setUserAccessLevel: (newUserAccessLevel: UserAccessLevel) => void
  assignedLocationIds?: Set<string>
  setAssignedLocationIds: (newIds: Set<string>) => void
  replacementSupervisorMap: Record<string, string>
  setReplacementSupervisorMap: React.Dispatch<
    React.SetStateAction<Record<string, string>>
  >
  loading?: boolean
  replacementMembers: ReplacementSupervisorUserResponse[]
  locationsForFutureShifts?: LocationResponse[]
  regionMap: RegionIdToRegionMap
  regionToLocationsMap: RegionIdToPartialLocationMap
  memberIdToMemberMap: Map<string, ReplacementSupervisorUserResponse>
}

export const AssignLocationsForMemberAndReplaceSupervisorsSection = ({
  member,
  userAccessLevel,
  setUserAccessLevel,
  assignedLocationIds = new Set(assignedLocationIdsForMember(member)),
  setAssignedLocationIds,
  replacementSupervisorMap,
  setReplacementSupervisorMap,
  loading,
  replacementMembers,
  regionMap,
  regionToLocationsMap,
  locationsForFutureShifts = [],
  memberIdToMemberMap,
}: AssignLocationsForMemberAndReplaceSupervisorsSectionProps) => {
  const locationsToReplaceSupervisors = useMemo(
    () =>
      getLocationsToReplaceSupervisorsForMember({
        locationsForFutureShifts,
        userAccessLevel,
        assignedLocationIds,
      }),

    [locationsForFutureShifts, userAccessLevel, assignedLocationIds],
  )

  const onAssignLocationIds = useCallback(
    (newAssignedLocationIds: Set<string>) => {
      setAssignedLocationIds(newAssignedLocationIds)
      // if the supervisor map contains a replacement supervisor for a location
      // that has just been assigned to this current member, we no longer need
      // that replacement supervisor -> so remove them from the map
      const currLocationIdsToReplace = locationsToReplaceSupervisors.map(
        (loc) => loc.locationId,
      )
      const locationIdsToRemove = currLocationIdsToReplace.filter((id) =>
        newAssignedLocationIds.has(id),
      )
      setReplacementSupervisorMap(
        (currSupervisorMap: Record<string, string>) => {
          const newReplacementSupervisorMap = { ...currSupervisorMap }
          for (const id of locationIdsToRemove) {
            delete newReplacementSupervisorMap[id]
          }

          return newReplacementSupervisorMap
        },
      )
    },
    [
      locationsToReplaceSupervisors,
      setAssignedLocationIds,
      setReplacementSupervisorMap,
    ],
  )

  if (loading) {
    return <LoadingSpinner />
  }

  return (
    <Col gap={theme.space.sm}>
      <Row fullWidth>
        <AssignAccessLevelAndLocationsRow
          userAccessLevel={userAccessLevel}
          setUserAccessLevel={setUserAccessLevel}
          assignedLocationIds={assignedLocationIds}
          setAssignedLocationIds={onAssignLocationIds}
          regionMap={regionMap}
          regionToLocationsMap={regionToLocationsMap}
          loading={loading}
        />
      </Row>

      {locationsToReplaceSupervisors.length > 0 && (
        <Col gap={theme.space.sm}>
          <Col gap={theme.space.xs}>
            <Row gap={theme.space.xxs}>
              <SvgIcon name="info" size={24} color={theme.colors.Red60} />
              <Text variant="h5" color={theme.colors.Red60}>
                Add Replacement Supervisor
              </Text>
            </Row>
            <Text>
              Please select a replacement supervisor for current and upcoming
              shifts where this user is assigned as the supervisor.
            </Text>
          </Col>
          <ReplaceSupervisorForLocationsSection
            member={member}
            locations={locationsToReplaceSupervisors}
            replacementMembers={replacementMembers}
            replacementSupervisorMap={replacementSupervisorMap}
            setReplacementSupervisorMap={setReplacementSupervisorMap}
            memberIdToMemberMap={memberIdToMemberMap}
          />
        </Col>
      )}
    </Col>
  )
}
