import { LoadingSpinner } from '@traba/react-components'
import {
  CompanyWorkerFeedback,
  ConnectionReason,
  CreateCompanyWorkerFeedback,
  Worker,
  WorkerShift,
} from '@traba/types'
import { useState } from 'react'
import { useRoleAttributes } from 'src/hooks/useAttributes'
import { useBlocks } from 'src/hooks/useBlocks'
import { theme } from 'src/libs/theme'
import { ConnectionType, JobStatus } from 'src/types'

import {
  Button,
  ButtonVariant,
  Checkbox,
  Divider,
  InlineBanner,
  Row,
  Text,
} from '../base'
import { ButtonIcon } from '../PersonalProfile/PersonalProfile.styles'
import { ReviewWorkerShiftDisplay } from '../ReviewWorker/components/ReviewWorkerShiftDisplay'
import { ReviewWorkerForm } from '../ReviewWorker/ReviewWorkerForm'

const CONNECTION_REASONS: Partial<Record<ConnectionReason, string>> = {
  [ConnectionReason.UNABLE_TO_PERFORM_REQUIREMENTS]:
    ' was not able to perform the requirements of this role.',
  [ConnectionReason.UNPROFESSIONAL_UNCOOPERATIVE]:
    ' was unprofessional or unwilling to work as instructed.',
  [ConnectionReason.SERIOUS_INCIDENT]:
    ' caused or was involved in a serious incident while on shift.',
}

function reviewHasRequiredContent(review: CreateCompanyWorkerFeedback) {
  return review.attributesFeedback?.length && review.note
}

export interface BlockWorkerFormProps {
  worker: Worker | undefined
  workerShift: WorkerShift | undefined
  onClose: () => void
}

export function BlockWorkerForm({
  worker,
  workerShift,
  onClose,
}: BlockWorkerFormProps) {
  const { roleAttributes } = useRoleAttributes()
  const { blockAndReviewWorker } = useBlocks()
  const [review, setReview] = useState<
    CreateCompanyWorkerFeedback | CompanyWorkerFeedback | undefined
  >()
  const [blocking, setBlocking] = useState(false)
  const [connectionReasons, setConnectionReasons] = useState<
    ConnectionReason[]
  >([])

  function toggleConnectionReason(reason: ConnectionReason) {
    if (connectionReasons.includes(reason)) {
      setConnectionReasons(connectionReasons.filter((r) => r !== reason))
    } else {
      setConnectionReasons(connectionReasons.concat([reason]))
    }
  }

  const valid =
    workerShift?.workerId &&
    connectionReasons.length > 0 &&
    review &&
    reviewHasRequiredContent(review)

  async function onBlock() {
    if (!valid) {
      return
    }
    setBlocking(true)
    // TODO: remove this when backend supports
    try {
      await blockAndReviewWorker(
        workerShift.workerId,
        connectionReasons,
        review,
      )
    } catch (err) {
      console.log(err)
    }
    setBlocking(false)
    onClose()
  }

  const name = worker?.firstName || 'this worker'
  return (
    <div style={{ width: '100%' }}>
      <Row justifyBetween alignCenter style={{ margin: theme.space.sm }}>
        <Text variant="h4">Block from future shifts</Text>
        <ButtonIcon name="cancel" onClick={onClose} />
      </Row>
      <Divider />
      {!roleAttributes || !worker || !workerShift ? (
        <div
          style={{
            height: 300,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <LoadingSpinner />
        </div>
      ) : (
        <div style={{ margin: theme.space.sm }}>
          <ReviewWorkerShiftDisplay
            worker={worker}
            workerShift={workerShift}
            style={{ marginBottom: theme.space.xs }}
          />
          {workerShift &&
            [JobStatus.InProgress, JobStatus.OnBreak].includes(
              workerShift.jobStatus,
            ) && (
              <InlineBanner
                style={{
                  marginTop: theme.space.xs,
                  marginBottom: theme.space.xs,
                }}
                severity="warning"
                text="This worker may currently be on a shift."
                subTitle={`If they are on a shift, they will not be informed of the block until after the shift ends. Please exercise caution discussing the block with the worker during the shift to prevent any unintended reactions.`}
              />
            )}

          <Text variant="h5">Select a reason for this block</Text>
          <Text
            variant="body2"
            style={{
              fontSize: 12,
              marginTop: theme.space.xxs,
              marginBottom: theme.space.xs,
            }}
          >
            Adding {name} to the list of blocked workers will prevent them from
            signing up for future shifts at your business and will remove them
            from any shifts they are currently signed up for.
          </Text>
          <div style={{ marginBottom: theme.space.sm }}>
            {Object.entries(CONNECTION_REASONS).map(([reason, label]) => (
              <Checkbox
                key={reason}
                value={connectionReasons.includes(reason as ConnectionReason)}
                onChange={() =>
                  toggleConnectionReason(reason as ConnectionReason)
                }
                label={
                  <Text variant="body1" style={{ fontSize: 12 }}>
                    {name}
                    {label}
                  </Text>
                }
                labelStyle={{ marginBottom: theme.space.xxs }}
              />
            ))}
            {connectionReasons.includes(ConnectionReason.SERIOUS_INCIDENT) ? (
              <InlineBanner text="Thanks for letting us know. Please share more detail below and our team will reach out to you as soon as possible." />
            ) : null}
          </div>
          <ReviewWorkerForm
            workerName={name}
            workerId={workerShift.workerId}
            shiftId={workerShift.shiftId}
            requiredAttributes={workerShift.shiftInfo.requiredAttributes || []}
            variation={ConnectionType.BLOCK}
            onChangeReview={setReview}
            noAutoSave
            additionalInfoProps={{
              alwaysShowInputField: true,
              placeholderText: 'Tell us why you are blocking this worker',
            }}
          />
        </div>
      )}
      <Divider />
      <Row justifyEnd alignCenter style={{ margin: theme.space.sm }}>
        <Button
          disabled={!valid}
          loading={blocking}
          variant={ButtonVariant.FILLED}
          onClick={onBlock}
        >
          Confirm
        </Button>
      </Row>
    </div>
  )
}
