import { useAlert } from '@traba/context'
import { theme } from '@traba/theme'
import {
  LocationResponse,
  RegionIdToRegionMap,
  ReplacementSupervisorForLocation,
  ReplacementSupervisorUserResponse,
  User,
  UserAccessLevel,
  UserRole,
} from '@traba/types'
import {
  assignedLocationIdsForMember,
  getLocationsToReplaceSupervisorsForMember,
} from '@traba/utils'
import { useCallback, useMemo, useState } from 'react'
import {
  Button,
  ButtonVariant,
  Col,
  LoadingSpinner,
} from '../../../base-components'
import { ModalPaddingContainer } from '../../../base-components/Modal/Modal.styles'
import Row from '../../../base-components/Row'
import { RegionIdToPartialLocationMap } from '../../../base-components/selectors/GroupedLocationSearchSelector/types'
import { SvgIcon } from '../../../base-components/SvgIcon'
import { Text } from '../../../base-components/Text'
import { AssignLocationsForMemberAndReplaceSupervisorsSection } from '../../AssignLocationsForMemberAndReplaceSupervisorsSection'
import { ChangeMemberRoleRow } from '../ChangeMemberRoleModalContent/ChangeMemberRoleRow'

type EditMemberModalMemberType = Pick<
  User,
  'role' | 'firstName' | 'lastName' | 'userAccessLevel' | 'locations'
>

function getUpdatedContentForMember({
  member,
  newAssignedLocationIds,
  newUserAccessLevel,
  newRole,
}: {
  member: EditMemberModalMemberType
  newAssignedLocationIds?: Set<string>
  newUserAccessLevel?: UserAccessLevel
  newRole?: UserRole
}) {
  const memberAssignedLocations = member.locations || []
  const hasAssignedLocationsChanged =
    newAssignedLocationIds &&
    !(
      memberAssignedLocations.length === newAssignedLocationIds.size &&
      memberAssignedLocations.every((loc) =>
        newAssignedLocationIds.has(loc.locationId),
      )
    )

  return {
    userAccessLevel:
      newUserAccessLevel && newUserAccessLevel !== member.userAccessLevel
        ? newUserAccessLevel
        : undefined,
    locationIds: hasAssignedLocationsChanged
      ? [...newAssignedLocationIds]
      : undefined,
    role: newRole && newRole !== member.role ? newRole : undefined,
  }
}

export interface EditMemberModalContentUpdateUser {
  role?: UserRole
  userAccessLevel?: UserAccessLevel
  locationIds?: string[]
  replacementSupervisorsForLocations?: ReplacementSupervisorForLocation[]
}

export interface EditMemberModalContentProps {
  member: EditMemberModalMemberType
  handleClose: () => void
  onUpdateMember: (
    updatedMemberParams: EditMemberModalContentUpdateUser,
  ) => void
  loading?: boolean
  replacementMembers: ReplacementSupervisorUserResponse[]
  locationsForFutureShifts?: LocationResponse[]
  regionMap: RegionIdToRegionMap
  regionToLocationsMap: RegionIdToPartialLocationMap
  memberIdToMemberMap: Map<string, ReplacementSupervisorUserResponse>
}

export function EditMemberModalContent(props: EditMemberModalContentProps) {
  const {
    member,
    handleClose,
    onUpdateMember,
    loading,
    replacementMembers,
    locationsForFutureShifts,
    regionMap,
    regionToLocationsMap,
    memberIdToMemberMap,
  } = props
  const { showError } = useAlert()
  const [role, setRole] = useState<UserRole>(member.role)
  const [assignedLocationIds, setAssignedLocationIds] = useState<Set<string>>(
    new Set(assignedLocationIdsForMember(member)),
  )
  const [replacementSupervisorMap, setReplacementSupervisorMap] = useState<
    Record<string, string>
  >({})
  const [userAccessLevel, setUserAccessLevel] = useState(member.userAccessLevel)

  const updatedMemberData = useMemo(() => {
    return getUpdatedContentForMember({
      member,
      newUserAccessLevel: userAccessLevel,
      newRole: role,
      newAssignedLocationIds: assignedLocationIds,
    })
  }, [
    member.locations,
    member.userAccessLevel,
    member.role,
    role,
    userAccessLevel,
    assignedLocationIds,
  ])

  const hasNoFieldsChanged =
    updatedMemberData.locationIds === undefined &&
    updatedMemberData.role === undefined &&
    updatedMemberData.userAccessLevel === undefined

  const onSave = useCallback(() => {
    const locationsNeedingReplacement = new Set(
      getLocationsToReplaceSupervisorsForMember({
        locationsForFutureShifts,
        userAccessLevel,
        assignedLocationIds,
      }).map((loc) => loc.locationId),
    )
    const replacementSupervisorsForLocations: ReplacementSupervisorForLocation[] =
      Object.entries(replacementSupervisorMap)
        .filter(
          ([locationId, newSupervisorId]) =>
            locationsNeedingReplacement.has(locationId) && !!newSupervisorId,
        )
        .map(([locationId, newSupervisorId]) => ({
          locationId,
          newSupervisorId,
        }))

    if (
      locationsNeedingReplacement.size >
      replacementSupervisorsForLocations.length
    ) {
      showError(
        'You must replace this member with a supervisor in all locations they have future shifts',
      )
      return
    }

    onUpdateMember({ ...updatedMemberData, replacementSupervisorsForLocations })
  }, [
    updatedMemberData,
    onUpdateMember,
    assignedLocationIds,
    locationsForFutureShifts,
    replacementSupervisorMap,
    showError,
    userAccessLevel,
  ])

  if (loading) {
    return <LoadingSpinner />
  }

  return (
    <ModalPaddingContainer>
      <Row justifyBetween pb={theme.space.xs}>
        <Text variant="h2">Edit user</Text>
        <SvgIcon
          size={24}
          color={theme.colors.Grey50}
          name="cancel"
          onClick={handleClose}
        />
      </Row>

      <Text variant="body2">
        Edit role and locations assigned to the selected member below.
      </Text>

      <Col gap={theme.space.sm} pt={theme.space.sm}>
        <ChangeMemberRoleRow
          member={member}
          role={role}
          changeMemberRole={setRole}
        />

        <AssignLocationsForMemberAndReplaceSupervisorsSection
          member={member}
          replacementMembers={replacementMembers}
          locationsForFutureShifts={locationsForFutureShifts}
          assignedLocationIds={assignedLocationIds}
          setAssignedLocationIds={setAssignedLocationIds}
          replacementSupervisorMap={replacementSupervisorMap}
          setReplacementSupervisorMap={setReplacementSupervisorMap}
          loading={loading}
          regionMap={regionMap}
          regionToLocationsMap={regionToLocationsMap}
          memberIdToMemberMap={memberIdToMemberMap}
          userAccessLevel={userAccessLevel}
          setUserAccessLevel={setUserAccessLevel}
        />

        <Row justifyBetween>
          {handleClose && (
            <Button variant={ButtonVariant.OUTLINED} onClick={handleClose}>
              Cancel
            </Button>
          )}
          <Button
            onClick={onSave}
            loading={loading}
            disabled={hasNoFieldsChanged}
          >
            Save changes
          </Button>
        </Row>
      </Col>
    </ModalPaddingContainer>
  )
}
