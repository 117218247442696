import {
  InputLabel as MaterialInputLabel,
  Select as MaterialSelect,
} from '@mui/material'
import styled, { css } from 'styled-components'

export const SelectContainer = styled.div``

export const Select = styled(MaterialSelect)`
  padding: 5.5px 7px;

  > .MuiOutlinedInput-input {
    color: ${({ theme }) => theme.colors.MidnightBlue};
  }

  &:hover > .MuiOutlinedInput-notchedOutline {
    ${({ disabled, theme }) =>
      !disabled
        ? css`
            border-color: ${theme.colors.Grey50};
          `
        : ''}
  }
`

export const InputLabel = styled(MaterialInputLabel)`
  z-index: 0;
`

export const StyledCheckboxContainer = styled.div<{
  checked?: boolean
  disabled?: boolean
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 24px;
  border: 1px solid
    ${({ checked, disabled, theme }) =>
      checked
        ? disabled
          ? theme.colors.Grey
          : theme.colors.brand
        : theme.colors.Grey40};
  border-radius: 4px;
  background-color: ${({ checked, disabled, theme }) =>
    checked
      ? disabled
        ? theme.colors.Grey
        : theme.colors.brand
      : theme.colors.White};
`
