import { Tooltip } from '@mui/material'
import { makePlural } from '@traba/string-utils'
import { theme } from '@traba/theme'
import { COMPANY_WIDE_TEXT, LocationResponse } from '@traba/types'
import {
  getLocationNameOrTruncatedAddress,
  sortLocationsByName,
} from '@traba/utils'
import { Row, SvgIcon } from '../base-components'

interface Props {
  isCompanyWide?: boolean
  assignedLocations?: Pick<LocationResponse, 'name' | 'address'>[]
}

export function UserAccessLevelOrLocationsStringWithTooltip({
  isCompanyWide,
  assignedLocations = [],
}: Props) {
  const locationNamesString = sortLocationsByName(assignedLocations)
    .map((loc) => getLocationNameOrTruncatedAddress(loc))
    .join(', ')

  return (
    <Tooltip title={isCompanyWide ? undefined : locationNamesString}>
      <div>
        <Row gap={theme.space.xxs} alignCenter>
          <Row alignCenter>
            <SvgIcon
              name="location"
              size={16}
              color={theme.colors.MidnightBlue}
            />
          </Row>
          {isCompanyWide
            ? COMPANY_WIDE_TEXT
            : `${assignedLocations.length} location${makePlural(assignedLocations.length)}`}
        </Row>
      </div>
    </Tooltip>
  )
}
