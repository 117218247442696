import { WorkerShiftWithWorkerDetails } from '@traba/types'
import { Text } from 'src/components/base/Text'
import { useConnections } from 'src/hooks/useConnections'
import { theme } from 'src/libs/theme'
import { CondensedWorker } from 'src/types'

import { ButtonVariant, Col } from '../base'
import { Dialog } from '../base/Dialog/Dialog'
import { SelectedWorkersCard } from '../SelectedWorkersCard'

// This is where the logic meets the UI. This separation allows use to pass the data directly to the UI in StoryBook

export type RemoveConnectionConfirmationModalProps = {
  isOpen: boolean
  handleClose: () => void
  worker?: CondensedWorker
  workers?: WorkerShiftWithWorkerDetails[]
  type?: 'favorite' | 'blocked'
}

export const RemoveConnectionConfirmationModal = (
  props: RemoveConnectionConfirmationModalProps,
) => {
  const { removeConnection } = useConnections()
  const { workers, worker, type } = props

  const selectedWorkers = workers?.length
    ? workers.map(({ worker }) => worker)
    : worker
      ? [worker]
      : null

  if (!selectedWorkers) {
    return null
  }

  const multipleWorkersSelected = selectedWorkers.length > 1

  const handleConfirm = () => {
    const eventProperties = {
      ...(multipleWorkersSelected ? { workers } : { worker }),
    }
    const connectionType = type === 'favorite' ? 'favorited' : 'blocked'
    window.analytics.track(
      `Business Un${connectionType} Worker${multipleWorkersSelected && `s`}`,
      eventProperties,
    )
    selectedWorkers.map((worker) => removeConnection(worker.uid))
  }

  let removeWorkerTitleText: string, removeWorkerBodyText: string

  if (multipleWorkersSelected) {
    removeWorkerTitleText = `Remove ${selectedWorkers.length} workers from the list of ${type} workers?`
    removeWorkerBodyText = `Are you sure you want to remove these workers the list of ${type}
        workers?`
  } else {
    const [worker] = selectedWorkers
    removeWorkerTitleText = `Remove ${worker.firstName} ${worker.lastName} from the list of ${type} workers?`
    removeWorkerBodyText = `Are you sure you want to remove this worker from the list of ${type}
        workers?`
  }

  return (
    <Dialog
      dialogTitle={removeWorkerTitleText}
      dialogTitleIcon={type === 'blocked' ? 'userProfile_active' : 'heart'}
      onConfirm={() => {
        handleConfirm()
        props.handleClose()
      }}
      confirmButtonVariant={
        type === 'blocked' ? ButtonVariant.FILLED : ButtonVariant.CANCEL
      }
      onClose={() => props.handleClose()}
      onConfirmCTA="Confirm"
      open={props.isOpen}
    >
      <Col gap={theme.space.xs}>
        <Text variant="body2">{removeWorkerBodyText}</Text>
        {multipleWorkersSelected && <SelectedWorkersCard workers={workers} />}
      </Col>
    </Dialog>
  )
}
