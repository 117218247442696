import { theme } from '@traba/theme'
import React from 'react'
import { SvgIcon } from '../SvgIcon'
import { StyledCheckboxContainer } from './Select.styles'

export interface StyledSelectCheckboxProps {
  checked?: boolean
  disabled?: boolean
}

export function StyledSelectCheckbox({
  checked,
  disabled,
}: StyledSelectCheckboxProps) {
  return (
    <StyledCheckboxContainer checked={checked} disabled={disabled}>
      {checked && (
        <SvgIcon
          name="check"
          size={16}
          color={disabled ? theme.colors.Grey40 : theme.colors.White}
        />
      )}
    </StyledCheckboxContainer>
  )
}
