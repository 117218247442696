import { FormControlLabel, FormGroup, Switch } from '@mui/material'
import { ShiftStatus } from '@traba/types'
import useMobile from 'src/hooks/useMobile'
import { theme } from 'src/libs/theme'

import { Text } from '../base'
import * as S from '../ShiftCalendar/ShiftCalendar.styles'
import { ShiftCalendarProps } from '../ShiftCalendar/ShiftCalendar.ui'
import { FilterDropdown } from './FilterDropdown'

interface ShiftFiltersProps
  extends Omit<
    ShiftCalendarProps,
    | 'shifts'
    | 'isShiftsFetched'
    | 'page'
    | 'onPageLeft'
    | 'onPageRight'
    | 'isShowWorkersToggled'
    | 'setIsShowWorkersToggled'
    | 'regions'
  > {
  className?: string
  fullWidth?: boolean
  style?: React.CSSProperties
  hideCanceledFilter?: boolean
  fieldToUse?: 'status' | 'statuses'
}

export function ShiftFilters(props: ShiftFiltersProps) {
  const {
    className,
    filters,
    dispatch,
    members,
    fullWidth,
    hideCanceledFilter,
    style,
  } = props
  const { isReactNativeApp } = useMobile()

  return (
    <S.FiltersContainer
      className={className}
      fullWidth={fullWidth || isReactNativeApp}
      style={style}
    >
      {members.length > 1 && (
        <FilterDropdown
          label="Onsite contact"
          selected={filters.supervisorId}
          items={members
            .map((m) => ({
              value: m.uid || '',
              label: `${m.firstName} ${m.lastName}`,
            }))
            .sort((a, b) => (a.label < b.label ? -1 : 1))}
          onSelectItem={(value) =>
            dispatch({ type: 'SET_SUPERVISOR', supervisorId: value })
          }
          style={{ marginRight: theme.space.xxs }}
        />
      )}
      {!hideCanceledFilter && !isReactNativeApp && (
        <FormGroup style={{ marginRight: theme.space.xs }}>
          <FormControlLabel
            labelPlacement="start"
            style={{ marginLeft: theme.space.xxs }}
            control={
              <Switch
                checked={filters.statuses?.includes(ShiftStatus.CANCELED)}
                onChange={() =>
                  dispatch({
                    type: 'SET_STATUSES',
                    statuses: filters.statuses?.includes(ShiftStatus.CANCELED)
                      ? filters.statuses.filter(
                          (status) => status !== ShiftStatus.CANCELED,
                        )
                      : [...(filters.statuses || []), ShiftStatus.CANCELED],
                  })
                }
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label={
              <Text variant="h6" color={theme.colors.MidnightBlue}>
                Show canceled shifts
              </Text>
            }
          />
        </FormGroup>
      )}
    </S.FiltersContainer>
  )
}
