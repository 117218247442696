import { datadogRum } from '@datadog/browser-rum'
import * as Sentry from '@sentry/react'
import { createContext, useContext, useReducer } from 'react'

import { UserAction, UserDispatch, UserReducer, UserState } from './types'

const initialState: UserState = {
  userProfile: null,
  isLoadingUser: true,
  isUserLoggedIn: false,
}

function userReducer(state: UserState, action: UserAction): UserState {
  switch (action.type) {
    case 'SET_USER':
      return { ...state, userProfile: action.value }
    case 'EDIT_USER':
      return {
        ...state,
        userProfile: { ...state.userProfile, ...action.value },
      }
    case 'SET_IS_LOADING':
      return { ...state, isLoadingUser: action.value }
    case 'USER_LOGIN':
      datadogRum.setGlobalContextProperty('company', {
        userEmail: action.value?.email,
        companyId: action.value?.companyId,
        userId: action.value?.uid,
      })
      Sentry.setUser({
        id: action.value?.uid,
      })
      return {
        ...state,
        isLoadingUser: false,
        isUserLoggedIn: true,
        userProfile: action.value,
      }
    case 'USER_LOGOUT':
      return {
        ...state,
        isLoadingUser: false,
        isUserLoggedIn: false,
        userProfile: null,
      }
    default:
      throw new Error()
  }
}

const UserContext = createContext<{
  state: UserState
  dispatch: UserDispatch
}>({
  state: initialState,
  dispatch: () => null,
})

export function useUserContext() {
  return useContext(UserContext)
}

export default function UserContextProvider({
  children,
}: {
  children: JSX.Element
}) {
  const [state, dispatch] = useReducer<UserReducer>(userReducer, initialState)

  const exportedContext = {
    state,
    dispatch,
  }

  return (
    <UserContext.Provider value={exportedContext}>
      {children}
    </UserContext.Provider>
  )
}
