import { OUTSIDE_REGION_ID } from '@traba/consts'
import { LocationResponse, Locations, RecordStatus } from '@traba/types'

export function isLocationArchived(location: LocationResponse | Locations) {
  return location.recordStatus === RecordStatus.Archived
}

export function isLocationActive(location: LocationResponse | Locations) {
  return location.recordStatus === RecordStatus.Active
}

export function isLocationValid(location: LocationResponse | Locations) {
  return location.regionId !== OUTSIDE_REGION_ID
}
