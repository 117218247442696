import { trabaApi } from '@traba/api-utils'
import { LocationResponse } from '@traba/types'
import { useQuery } from 'react-query'

const getLocations = async (companyId?: string) => {
  try {
    const path = companyId
      ? `/companies/${companyId}/locations`
      : `/my-company/locations`
    const response = await trabaApi.get(path)
    return response.data
  } catch (error) {
    console.log(error)
  }
}

/*
 * Locations of most recent active ones (if the location was edited, it will contain most recent one
 * and if it was archived, it will contain the location that was archived  (archived not by edit)
 * For instance, if location1.0 was edited to location1.1, location1.1 will be included
 * If location1.1 was archived, location1.1 will be included
 */
const getUniqueLocations = (locations: LocationResponse[] | undefined) => {
  return (
    locations?.filter(
      (location) => location.activeLocationId === location.locationId,
    ) ?? []
  )
}

export const useLocations = (companyId?: string) => {
  const {
    isLoading,
    isError,
    data: locations,
    error,
    isFetched: isLocationsFetched,
    refetch,
  } = useQuery<LocationResponse[], Error>(['locations', companyId], () =>
    getLocations(companyId),
  )
  const uniqueLocations = getUniqueLocations(locations)
  return {
    isLoading,
    isError,
    error,
    isLocationsFetched,
    locations: locations || [],
    uniqueLocations,
    refetch,
  }
}
