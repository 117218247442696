import { Region } from '@traba/types'
import { getShiftDateString, getShiftTimeString } from '@traba/utils'
import React, { Dispatch, useState } from 'react'
import { NavigateFunction } from 'react-router-dom'
import {
  Col,
  Divider,
  EmptyPlaceholderTile,
  Input,
  Row,
  Text,
} from 'src/components/base'
import useMobile from 'src/hooks/useMobile'
import { ShiftAndAddress } from 'src/hooks/useShifts'
import { theme } from 'src/libs/theme'
import { Address, UserData } from 'src/types'

import { getAddressString } from 'src/utils/stringUtils'
import {
  CalendarFilters,
  FiltersAction,
} from '../../hooks/useShiftFilters.hooks'
import { EmptyDataLocationSubtitle } from '../base/RegionalFilterSelect/EmptyDataLocationSubtitle'
import { ShiftFilters } from '../ShiftFilters/ShiftFilters'
import Skeletons from '../Skeletons/Skeletons'
import { WorkersOnShiftTable } from '../WorkersOnShiftTable'

export type TodaysWorkersProps = {
  shifts: ShiftAndAddress[]
  isShiftsFetched: boolean
  regions: Region[]
  isRegionsFetched: boolean
  members: UserData[]
  isMembersFetched: boolean
  filters: CalendarFilters
  dispatch: Dispatch<FiltersAction>
  showDesktopCard: boolean
  navigate: NavigateFunction
}

type ShiftHeaderProps = {
  startTime: Date
  endTime: Date
  timezone: string
  shiftRole: string
  shiftId: string
  address: Address
  locationName?: string
  showDesktopCard: boolean
  navigate: NavigateFunction
}

const ShiftHeader: React.FC<ShiftHeaderProps> = ({
  startTime,
  endTime,
  timezone,
  shiftRole,
  shiftId,
  address,
  locationName,
  showDesktopCard,
  navigate,
}) => {
  return (
    <Col
      pt={theme.space.sm}
      pb={showDesktopCard ? theme.space.xs : 0}
      px={showDesktopCard ? theme.space.sm : theme.space.xs}
    >
      <Text
        variant="h5"
        onClick={() => {
          navigate(`/calendar/${shiftId}`)
        }}
        style={{ cursor: 'pointer' }}
      >
        {shiftRole}
      </Text>
      {showDesktopCard ? (
        <Text>
          {`${getShiftDateString(startTime, endTime, timezone, {
            year: !showDesktopCard ? undefined : 'numeric',
            weekday: showDesktopCard ? 'long' : 'short',
          })}, ${getShiftTimeString(
            startTime,
            endTime,
            timezone,
          )}, ${getAddressString(address, locationName)}`}
        </Text>
      ) : (
        <Text>{`${getShiftTimeString(startTime, endTime, timezone)}`}</Text>
      )}
    </Col>
  )
}

export const TodaysWorkersUI: React.FC<TodaysWorkersProps> = (props) => {
  const {
    shifts,
    isShiftsFetched,
    isRegionsFetched,
    isMembersFetched,
    showDesktopCard,
    navigate,
  } = props

  const { isMobileViewOrReactNative } = useMobile()

  const [shiftSearchFilter, setShiftSearchFilter] = useState('')

  const shiftSearchInput = (
    <Input
      placeholder="Search shifts..."
      leftIconName="search"
      name="workerSearch"
      containerStyle={{
        margin: 0,
        marginRight: showDesktopCard ? theme.space.xxs : 0,
      }}
      type="text"
      defaultValue=""
      width={showDesktopCard ? '200px' : '100%'}
      value={shiftSearchFilter}
      onChange={(e) => {
        e.preventDefault()
        setShiftSearchFilter(e.target.value)
      }}
      onClear={() => setShiftSearchFilter('')}
    />
  )

  const filteredShifts = shifts.filter((shift) => {
    const locationMatches = getAddressString(shift.address, shift.locationName)
      .toLowerCase()
      .includes(shiftSearchFilter.toLowerCase())
    const roleMatches = shift.shiftRole
      .toLowerCase()
      .includes(shiftSearchFilter.toLowerCase())

    return !!shift.slotsFilled && (locationMatches || roleMatches)
  })

  if (!isShiftsFetched || !isRegionsFetched || !isMembersFetched) {
    return (
      <Col>
        <Row alignCenter>
          {shiftSearchInput}
          {showDesktopCard && <ShiftFilters {...props} hideCanceledFilter />}
        </Row>
        <Row fullWidth style={{ marginTop: theme.space.sm }}>
          <Skeletons count={3} component="SHIFT_TILE" />
        </Row>
      </Col>
    )
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        overflow: 'hidden',
      }}
    >
      <Row>
        {shiftSearchInput}
        {showDesktopCard && <ShiftFilters {...props} hideCanceledFilter />}
      </Row>
      {filteredShifts.length ? (
        <>
          {filteredShifts
            .sort(
              (shiftA, shiftB) =>
                (shiftA.businessStartTime ?? shiftA.startTime).getTime() -
                (shiftB.businessStartTime ?? shiftB.startTime).getTime(),
            )
            .map((shift: ShiftAndAddress, i) => (
              <div key={`todaysWorkers_shift_${i}`}>
                <ShiftHeader
                  shiftId={shift.id}
                  startTime={shift.businessStartTime ?? shift.startTime}
                  endTime={shift.endTime}
                  timezone={shift.timezone}
                  shiftRole={shift.shiftRole}
                  address={shift.address}
                  locationName={shift.locationName}
                  showDesktopCard={showDesktopCard}
                  navigate={navigate}
                />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    overflowY: 'auto',
                    width: '100%',
                  }}
                >
                  <WorkersOnShiftTable
                    shift={shift}
                    hideHeader
                    isUpcoming
                    isReactNativeApp={!showDesktopCard}
                  />
                </div>

                <Divider />
              </div>
            ))}
        </>
      ) : (
        <EmptyPlaceholderTile
          title={
            shiftSearchFilter
              ? 'No shifts found'
              : 'You currently have no workers scheduled for today in the selected locations.'
          }
          subtitle={
            <EmptyDataLocationSubtitle
              isMobileView={isMobileViewOrReactNative}
              extraText="to see workers on shifts in those locations"
            />
          }
          iconName="folder"
          style={{ width: '100%', marginTop: theme.space.sm }}
        />
      )}
    </div>
  )
}
