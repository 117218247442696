import { createContext, Reducer, useContext, useReducer } from 'react'
import { styleCombos } from 'src/screens/Calendar/Calender.styles'

import { ShiftAndAddress } from './useShifts'

export interface ColorSet {
  backgroundColor: string
  borderColor: string
}
export interface RolesMap {
  [roleId: string]: {
    color: ColorSet
    roleId: string
    name: string
    hideFromView?: boolean
  }
}

export interface RoleCalendarMapState {
  colorMap?: {
    [roleId: string]: { backgroundColor: string; borderColor: string }
  }
  rolesMap: RolesMap
}

const initialState: RoleCalendarMapState = {
  colorMap: {},
  rolesMap: {},
}

type ColorMapAction =
  | {
      type: 'GET_ROLE_MAP'
      payload: ShiftAndAddress[]
    }
  | { type: 'RESET_COLOR_MAP' }
  | { type: 'RESET_FILTERS' }
  | { type: 'TOGGLE_ROLE_FOR_ALL' }
  | {
      type: 'HIDE_ROLE'
      payload: { roleId: string; hideFromView: boolean }
    }

const roleMapReducer = (
  state: RoleCalendarMapState,
  action: ColorMapAction,
): RoleCalendarMapState => {
  switch (action.type) {
    case 'GET_ROLE_MAP': {
      const newMap = {
        ...state.rolesMap,
      }
      const newColorMap = { ...state.colorMap }

      action.payload.forEach((shift: ShiftAndAddress) => {
        if (!newMap[shift.roleId]) {
          if (!newColorMap[shift.roleId]) {
            // Assign a color if not already assigned
            newColorMap[shift.roleId] =
              styleCombos[Object.keys(newColorMap).length % styleCombos.length]
          }

          newMap[shift.roleId] = {
            color: newColorMap[shift.roleId],
            name: shift.shiftRole,
            roleId: shift.roleId,
          }
        }
      })

      return { colorMap: newColorMap, rolesMap: newMap }
    }

    case 'HIDE_ROLE': {
      const { roleId, hideFromView } = action.payload

      return {
        ...state,
        rolesMap: {
          ...state.rolesMap,
          [roleId]: {
            ...state.rolesMap[roleId],
            hideFromView,
          },
        },
      }
    }
    case 'TOGGLE_ROLE_FOR_ALL': {
      const newMap = Object.keys(state.rolesMap).reduce((acc, roleId) => {
        acc[roleId] = {
          ...state.rolesMap[roleId],
          hideFromView: !state.rolesMap[roleId].hideFromView,
        }
        return acc
      }, {} as RolesMap)

      return {
        ...state,
        rolesMap: newMap, // Update rolesMap with the new values
      }
    }
    case 'RESET_FILTERS': {
      // Find filters by showing all shifts in view
      const newMap = { ...state.rolesMap }
      for (const key of Object.keys(newMap)) {
        newMap[key].hideFromView = false
      }
      return { ...state, rolesMap: newMap }
    }
    case 'RESET_COLOR_MAP':
      return {
        colorMap: {},
        rolesMap: {},
      }

    default:
      return state
  }
}
export type CalendarContextReducer = Reducer<
  RoleCalendarMapState,
  ColorMapAction
>
export type CalendarContextDispatch = React.Dispatch<ColorMapAction>

const CalendarContext = createContext<{
  state: RoleCalendarMapState
  dispatch: CalendarContextDispatch
}>({
  state: initialState,
  dispatch: () => null,
})

const CalendarContextProvider = ({ children }: { children: JSX.Element }) => {
  const [state, dispatch] = useReducer<CalendarContextReducer>(
    roleMapReducer,
    initialState,
  )
  return (
    <CalendarContext.Provider value={{ state, dispatch }}>
      {children}
    </CalendarContext.Provider>
  )
}
const useCalendarContext = () => {
  const context = useContext(CalendarContext)
  if (!context) {
    throw new Error(
      'useCalendarContext must be used within a CalendarContextProvider',
    )
  }
  return context
}

export { CalendarContextProvider, useCalendarContext }
