import { LoadingSpinner, MODAL_SIZE } from '@traba/react-components'
import { ShiftPayType, ShiftRequest } from '@traba/types'
import {
  getFilteredShiftRequestsByAssignedLocations,
  getShiftTimeString,
} from '@traba/utils'
import { upperFirst } from 'lodash'
import { Dispatch, SetStateAction } from 'react'
import {
  Button,
  ButtonVariant,
  Modal,
  Row,
  SvgIcon,
  Text,
} from 'src/components'
import { RadioButton } from 'src/components/RadioButton'
import { useActiveCompanyLocationsForUser } from 'src/hooks/useUser'
import { theme } from 'src/libs/theme'
import { getMoneyString, getPayUnitString } from 'src/utils/moneyUtils'
import { getAddressString } from 'src/utils/stringUtils'
import { REPEAT_ON_OPTIONS } from './components/RecurringScheduleSelector'
import * as S from './SelectShiftRequestModal.styles'

function ShiftRequestItem({
  shiftRequest,
  selected,
  onClickItem,
  showLocation,
}: {
  shiftRequest: ShiftRequest
  selected: boolean
  onClickItem: () => void
  showLocation: boolean
}) {
  const locationString =
    showLocation && shiftRequest.location
      ? shiftRequest.location.name ||
        getAddressString(shiftRequest.location.address)
      : undefined
  return (
    <S.ShiftRequestItemContainer alignCenter onClick={onClickItem}>
      <RadioButton selected={selected} size={theme.space.sm} />
      <Row flexCol>
        <Text
          variant="body2"
          style={{
            marginLeft: theme.space.xs,
            color: theme.colors.MidnightBlue,
          }}
        >
          {shiftRequest.shiftRole}
          {' · '}
          {shiftRequest.payType === ShiftPayType.UNIT
            ? `${shiftRequest.numberOfUnits} units`
            : `${shiftRequest.slotsRequested} workers`}
          {' @ '}
          {`${getMoneyString(shiftRequest.payRate)} ${getPayUnitString(
            shiftRequest.payType,
          )}`}
        </Text>
        <Text
          variant="body2"
          style={{
            marginLeft: theme.space.xs,
          }}
        >
          <span
            style={{
              color: theme.colors.MidnightBlue,
            }}
          >
            {`${getShiftTimeString(
              shiftRequest.schedules[0].startTime,
              shiftRequest.schedules[0].endTime,
              shiftRequest.schedules[0].timeZone,
            )}`}
          </span>
          <span
            style={{
              marginLeft: 4,
              fontStyle: 'italic',
            }}
          >
            {shiftRequest.schedules[0].recurringSchedule &&
              `repeats on ${shiftRequest.schedules[0].recurringSchedule.repeatOn
                .sort(
                  (a, b) =>
                    (REPEAT_ON_OPTIONS.findIndex((opt) => opt.value === a) ||
                      0) -
                    (REPEAT_ON_OPTIONS.findIndex((opt) => opt.value === b) ||
                      0),
                )
                .map((r) => upperFirst(r.toLowerCase()))
                .join(', ')}`}
          </span>
        </Text>
        {locationString && (
          <Text
            variant="body2"
            style={{
              marginLeft: theme.space.xs,
              fontStyle: 'italic',
            }}
          >
            {`${locationString}`}
          </Text>
        )}
      </Row>
    </S.ShiftRequestItemContainer>
  )
}

export function SelectShiftRequestModal(props: {
  shiftRequests?: ShiftRequest[]
  showSelectModal?: boolean
  setShowSelectModal: (showModal?: boolean) => void
  selected?: string
  setSelected: Dispatch<SetStateAction<string | undefined>>
  userHasLimitedShiftManagementAbility: boolean
}) {
  const {
    shiftRequests,
    showSelectModal,
    setShowSelectModal,
    selected,
    setSelected,
    userHasLimitedShiftManagementAbility,
  } = props
  const showLocation =
    new Set(shiftRequests?.map((req) => req.locationId)).size > 1

  // Only show the ability to rebook at locations the user has access to
  const { activeLocationIdsForUser } = useActiveCompanyLocationsForUser()
  const filteredShiftRequestsByUserAccess = shiftRequests
    ? getFilteredShiftRequestsByAssignedLocations({
        shiftRequests: shiftRequests,
        assignedLocationIds: activeLocationIdsForUser,
      })
    : undefined
  const sortedShiftRequests = filteredShiftRequestsByUserAccess?.sort(
    (a, b) => {
      if (!a.createdAt || !b.createdAt) {
        return 0
      }
      return b.createdAt.getTime() - a.createdAt.getTime()
    },
  )

  // Filter out PBU shifts as they are not available to LimitedShiftCreators currently
  // its too difficult to hit min value of $50 without knowing price per unit
  if (userHasLimitedShiftManagementAbility) {
    sortedShiftRequests?.filter(
      (shiftRequest) => shiftRequest.payType === ShiftPayType.HOURLY,
    )
  }

  return (
    <Modal
      size={MODAL_SIZE.MEDIUM}
      isOpen={!!showSelectModal}
      handleClose={() => {
        setShowSelectModal(false)
        window.analytics?.track('User Dismissed Rebook Shift')
      }}
    >
      <div
        style={{
          width: '100%',
          marginLeft: theme.space.med,
          marginRight: theme.space.med,
        }}
      >
        <Row
          justifyBetween
          alignCenter
          style={{
            boxShadow: `0 2px 2px -2px ${theme.colors.Grey40}`,
            paddingTop: theme.space.sm,
            paddingBottom: theme.space.sm,
            width: '100%',
          }}
        >
          <Text variant="h4">Rebook a previous shift</Text>
          <SvgIcon
            name="cancel"
            color={theme.colors.Grey60}
            size={theme.space.sm}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setShowSelectModal(false)
              window.analytics?.track('User Dismissed Rebook Shift')
            }}
          />
        </Row>
        <div
          style={{ paddingTop: theme.space.sm, paddingBottom: theme.space.xxs }}
        >
          <Text variant="h7">
            Would you like to rebook one of your previous shifts?
          </Text>
          <Text variant="body2" style={{ marginTop: 4 }}>
            These are your previous shifts sorted by the date of creation.
          </Text>
          <Text variant="body2" style={{ marginTop: 4 }}>
            You will be able to review all details of the shift before booking.
          </Text>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              margin: `${theme.space.sm / 2}px 0px ${theme.space.sm}px 0px`,
            }}
          >
            {!sortedShiftRequests?.length ? null : (
              <>
                <div
                  style={{
                    paddingBottom: theme.space.xs,
                    boxShadow: `0 2px 2px -2px ${theme.colors.Grey40}`,
                  }}
                >
                  <div
                    style={{
                      backgroundColor: theme.colors.Grey10,
                      maxHeight: 300,
                      overflow: 'scroll',
                      margin: `${theme.space.xs}px 0px`,
                      padding: theme.space.xs,
                      borderRadius: theme.space.xxs,
                    }}
                  >
                    {sortedShiftRequests ? (
                      sortedShiftRequests.map(
                        (shiftRequest: ShiftRequest, index: number) => (
                          <ShiftRequestItem
                            key={index}
                            showLocation={showLocation}
                            shiftRequest={shiftRequest}
                            selected={selected === shiftRequest.shiftRequestId}
                            onClickItem={() => {
                              window.analytics?.track(
                                'User Selected Rebook Item',
                                { shiftRequestId: shiftRequest.shiftRequestId },
                              )
                              setSelected(shiftRequest.shiftRequestId)
                            }}
                          />
                        ),
                      )
                    ) : (
                      <LoadingSpinner />
                    )}
                  </div>
                </div>
                <Row justifyBetween mt={theme.space.med}>
                  <Button
                    onClick={() => {
                      setShowSelectModal(false)
                    }}
                    trackingEvent={{
                      message: 'User Clicked Dismiss Rebook Shift',
                    }}
                    variant={ButtonVariant.OUTLINED}
                  >
                    No thanks
                  </Button>
                  <a
                    style={{ textDecoration: 'none' }}
                    href={selected && `/book-shifts?rebookingId=${selected}`}
                  >
                    <Button
                      variant={ButtonVariant.FILLED}
                      disabled={!selected}
                      trackingEvent={{
                        message: 'User Clicked Rebook Shift',
                        properties: {
                          shiftRequestId: selected,
                        },
                      }}
                    >
                      <Text variant="h7" style={{ color: theme.colors.White }}>
                        Continue to Review
                      </Text>
                    </Button>
                  </a>
                </Row>
              </>
            )}
          </div>
        </div>
      </div>
    </Modal>
  )
}
