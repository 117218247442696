import { useHotSettings } from '@traba/hooks'
import { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useUserContext } from 'src/context/user/UserContext'
import { useBilling } from 'src/hooks/useBilling'
import { useCompanyEmploymentType } from 'src/hooks/useCompanyEmploymentType'
import useMobile from 'src/hooks/useMobile'
import { UserRolePermission } from 'src/types'
import { hasPermissions } from 'src/utils/userUtils'

import { BillingSettings } from '../BillingSettings/BillingSettings'
import { CompanyProfile } from '../CompanyProfile'
import { CostCentersProfile } from '../CostCentersProfile/CostCentersProfile'
import { DeleteAccount } from '../DeleteAccount'
import { MobileDisclaimer } from '../Disclaimer/MobileDisclaimer'
import { LocationsProfile } from '../LocationsProfile'
import { MembersProfile } from '../MembersProfile'
import { NotificationSettings } from '../NotificationSettings/NotificationSettings'
import { PersonalProfile } from '../PersonalProfile'
import { RolesProfile } from '../RolesProfile'
import { TrainingVideosProfile } from '../TrainingVideos'

export interface AccountSettingsTab {
  key: string
  label: string
  Component: React.FC
  permissions: UserRolePermission[]
}

const TABS: AccountSettingsTab[] = [
  {
    key: 'company-profile',
    label: 'Company profile',
    Component: CompanyProfile,
    permissions: [UserRolePermission.ViewCompanySettings],
  },
  {
    key: 'personal-settings',
    label: 'Personal settings',
    Component: PersonalProfile,
    permissions: [],
  },
  {
    key: 'notification-settings',
    label: 'Notification settings',
    Component: NotificationSettings,
    permissions: [],
  },
  {
    key: 'members',
    label: 'Members',
    Component: MembersProfile,
    permissions: [
      UserRolePermission.ViewUserRoles,
      UserRolePermission.ViewShifts,
    ],
  },
  {
    key: 'locations',
    label: 'Locations',
    Component: LocationsProfile,
    permissions: [UserRolePermission.ViewShifts],
  },
  {
    key: 'billing',
    label: 'Billing',
    Component: BillingSettings,
    permissions: [UserRolePermission.ViewPaymentSettings],
  },
  {
    key: 'roles',
    label: 'Roles',
    Component: RolesProfile,
    permissions: [UserRolePermission.ViewShifts],
  },
  {
    key: 'videos',
    label: 'Training Videos',
    Component: TrainingVideosProfile,
    permissions: [
      UserRolePermission.ManageCompanySettings,
      UserRolePermission.ManageShifts,
    ],
  },
  {
    key: 'delete-account',
    label: 'Delete Account',
    Component: DeleteAccount,
    permissions: [UserRolePermission.ViewShifts],
  },
  {
    key: 'cost-centers',
    label: 'Cost Centers',
    Component: CostCentersProfile,
    permissions: [],
  },
]

function getInitialTabIndex(search: string, activeTabs: AccountSettingsTab[]) {
  const query = new URLSearchParams(search)
  const tabQuery = query.get('tab')
  if (!tabQuery) {
    return 0
  }
  const index = activeTabs.findIndex((t) => t.key === tabQuery.toLowerCase())
  if (index === -1) {
    return 0
  }
  return index
}

export const useAccountSettings = () => {
  const { isExclusivelyMobileView } = useMobile()
  const navigate = useNavigate()
  const location = useLocation()
  const userContext = useUserContext()
  const { hotSettings } = useHotSettings()
  const { showInvoicing } = useCompanyEmploymentType()

  const activeTabs = useMemo(
    () =>
      TABS.filter((t) => {
        // On mobile web, show the mobile disclaimer instead of the
        // notification settings view if the user clicks on the notification
        // settings tab. This isn't handled at the component level
        // (<NotificationSettingsTables />) because the component is a shared
        // component and therefore we can't access the business app-specific
        // hooks to determine if the user is on mobile web.
        if (t.key === 'notification-settings') {
          if (isExclusivelyMobileView) {
            t.Component = MobileDisclaimer
          } else {
            // Reset the view back if the user's window expands back into
            // desktop-size
            t.Component = NotificationSettings
          }
        } else if (t.key === 'cost-centers') {
          if (
            !hotSettings?.costCenterEnabledByCompanyIds?.includes(
              userContext.state.userProfile?.companyId ?? '',
            )
          ) {
            return false
          }
        } else if (t.key === 'billing') {
          return showInvoicing
        }

        return hasPermissions(userContext.state.userProfile, t.permissions)
      }),
    [
      userContext.state.userProfile,
      isExclusivelyMobileView,
      hotSettings?.costCenterEnabledByCompanyIds,
    ],
  )

  const [currentTab, setCurrentTab] = useState(
    getInitialTabIndex(location.search, activeTabs),
  )

  useEffect(() => {
    const { key } = activeTabs[currentTab]
    navigate('/account-settings', { replace: true, state: { tab: key } })
    window.analytics.track(`User Navigated to Account Settings Tab`, {
      tab: key,
    })
  }, [navigate, activeTabs, currentTab])

  const { retrieveCheckoutSession } = useBilling()

  const handleStripeRedirectReturn = async (
    search: string,
    setCurrentTab: (arg: number) => void,
  ) => {
    if (search) {
      const params = new URLSearchParams(search)
      const sessionId = params.get('session_id')
      if (sessionId) {
        await retrieveCheckoutSession(sessionId)
        navigate('/account-settings')
        setCurrentTab(4)
      }
    }
  }
  useEffect(() => {
    handleStripeRedirectReturn(location.search, setCurrentTab)
  }, [location])

  return { currentTab, setCurrentTab, activeTabs }
}
