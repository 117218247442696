import { LoadingSpinner, MODAL_SIZE } from '@traba/react-components'
import { ConnectionType, Roster, UserRolePermission } from '@traba/types'
import { useMemo, useState } from 'react'
import { useCompanyWorkersByIds } from 'src/hooks/useCompanyWorkers'
import { useConnections } from 'src/hooks/useConnections'
import useMobile from 'src/hooks/useMobile'
import { RosterWithWorkers, useRoster } from 'src/hooks/useRoster'
import { useUserPermissions } from 'src/hooks/useUser'
import { theme } from 'src/libs/theme'

import { filterWorkersByNameStringSearch } from 'src/utils/searchFilterUtils'
import { Button, ButtonVariant, Col, Input, Modal, Row, Text } from '../base'
import { EditRosterModal } from '../Modals/RosterModal/EditRosterModal'
import {
  ReviewWorkerModalContent,
  ReviewWorkerModalContentProps,
} from '../ReviewWorker/components/ReviewWorkerModalContent.legacy'
import WorkerTable from '../WorkerTable/WorkerTable'

export default function RosterDetails(props: { rosterId: string }) {
  const { isLoading, roster, updateRoster } = useRoster(props.rosterId)
  const userCanManageWorkers = useUserPermissions([
    UserRolePermission.ManageWorkers,
  ])
  const [reviewModal, setReviewModal] = useState<
    Pick<ReviewWorkerModalContentProps, 'worker' | 'workerShift'> | undefined
  >(undefined)
  const [rosterToEdit, setRosterToEdit] = useState<
    Roster | RosterWithWorkers | undefined
  >(undefined)
  const [searchFilter, setSearchFilter] = useState<string>('')

  const { isMobileViewOrReactNative } = useMobile()

  const {
    isBlocked,
    isFavorite,
    isIneligible,
    favoriteWorker,
    isScheduledBlock,
  } = useConnections()
  const { data: allWorkers } = useCompanyWorkersByIds()
  const nonBlockedWorkers = allWorkers?.filter(
    (worker) => !isBlocked(worker.worker.uid),
  )

  const filteredWorkers = useMemo(
    () => filterWorkersByNameStringSearch(roster?.workers, searchFilter),
    [roster?.workers, searchFilter],
  )

  if (isLoading || !roster) {
    return <LoadingSpinner />
  }

  const Header = () => {
    const actions = (className: string, fullWidth = false) => {
      return (
        <Row className={className}>
          <Button
            full={fullWidth}
            variant={ButtonVariant.FILLED}
            style={{ maxHeight: theme.space.xl, marginLeft: theme.space.xxs }}
            onClick={() => {
              window?.analytics.track(`User Clicked Edit Roster`, {
                rosterId: roster.id,
              })
              setRosterToEdit(roster)
            }}
          >
            Edit Roster
          </Button>
        </Row>
      )
    }

    return (
      <>
        <Row
          justifyBetween
          alignCenter
          style={{ padding: `${theme.space.xs}px 0px` }}
          mb={theme.space.xs}
        >
          <Col style={{ flex: 1 }}>
            <Text variant="h2">{roster?.rosterName}</Text>
            <Text
              variant="body2"
              mt="xxs"
            >{`${roster.workers.length} Workers`}</Text>
          </Col>
          {actions('xs-hide md-show')}
        </Row>
        {actions('xs-show md-hide', true)}
      </>
    )
  }

  const searchInput = (
    <Input
      placeholder="Search workers..."
      leftIconName="search"
      name="workerSearch"
      containerStyle={{
        marginBottom: theme.space.sm,
      }}
      type="text"
      defaultValue=""
      width={isMobileViewOrReactNative ? '100%' : '33%'}
      value={searchFilter}
      onChange={(e) => {
        e.preventDefault()
        setSearchFilter(e.target.value)
      }}
      onClear={() => setSearchFilter('')}
    />
  )

  return (
    <>
      <Header />
      <>
        {searchInput}
        <WorkerTable
          data={filteredWorkers}
          userCanManageWorkers={userCanManageWorkers}
          isBlocked={isBlocked}
          isFavorite={isFavorite}
          isScheduledBlock={isScheduledBlock}
          isIneligible={isIneligible}
          setReviewModal={setReviewModal}
          favoriteWorker={favoriteWorker}
        />
      </>
      <Modal
        size={MODAL_SIZE.LARGE}
        isOpen={!!reviewModal}
        handleClose={() => setReviewModal(undefined)}
      >
        <ReviewWorkerModalContent
          onClose={() => setReviewModal(undefined)}
          variation={ConnectionType.FAVORITE}
          {...reviewModal!}
        />
      </Modal>

      {rosterToEdit && (
        <EditRosterModal
          workers={nonBlockedWorkers}
          roster={rosterToEdit}
          setRoster={setRosterToEdit}
          updateRoster={updateRoster}
        />
      )}
    </>
  )
}
