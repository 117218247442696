import {
  createContext,
  Reducer,
  useCallback,
  useContext,
  useReducer,
} from 'react'
import { REGIONAL_FILTER_KEY } from 'src/hooks/useRegionalFilter'
import { WebToRNEventName } from 'src/types/events'

interface AppContextState {
  isSideBarOpen: boolean
  isChatWidgetOpen: boolean
  selectedWorkerId?: string
  isReactNativeApp: boolean
  regionalFilter?: Record<string, string[]>
  regionalFilterStatus: RegionalFilterStatus
  isRegionalFilterOpened: boolean
  selectedLocationsCount: number
}

interface BasicActions {
  type:
    | 'SHOW_SIDEBAR'
    | 'HIDE_SIDEBAR'
    | 'SHOW_REGIONAL_FILTER'
    | 'HIDE_REGIONAL_FILTER'
    | 'RESET_REGIONAL_FILTER_CONTEXT'
}

interface ChatWidgetAction {
  type: 'SHOW_CHAT_WIDGET' | 'HIDE_CHAT_WIDGET'
  payload: { workerId?: string }
}

interface SaveRegionalFilterAction {
  type: 'SAVE_REGIONAL_FILTER'
  value: Record<string, string[]>
  uid: string
}

interface SetRegionalFilterStatusAction {
  type: 'SET_REGIONAL_FILTER_STATUS'
  value: RegionalFilterStatus
}

type AppContextAction =
  | BasicActions
  | ChatWidgetAction
  | SaveRegionalFilterAction
  | SetRegionalFilterStatusAction

export type AppContextDispatch = React.Dispatch<AppContextAction>
export type AppContextReducer = Reducer<AppContextState, AppContextAction>

export enum RegionalFilterStatus {
  HIDE = 'HIDE',
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
}

const initialState: AppContextState = {
  isSideBarOpen: false,
  isChatWidgetOpen: false,
  isReactNativeApp: window.ReactNativeWebView ? true : false,
  regionalFilter: undefined,
  regionalFilterStatus: RegionalFilterStatus.HIDE,
  isRegionalFilterOpened: false,
  selectedLocationsCount: 0,
}

function reducer(
  state: AppContextState,
  action: AppContextAction,
): AppContextState {
  switch (action.type) {
    case 'SHOW_SIDEBAR':
      return {
        ...state,
        isSideBarOpen: true,
      }
    case 'HIDE_SIDEBAR':
      return {
        ...state,
        isSideBarOpen: false,
      }
    case 'SHOW_CHAT_WIDGET':
      return {
        ...state,
        isChatWidgetOpen: true,
        selectedWorkerId: action.payload.workerId,
      }
    case 'HIDE_CHAT_WIDGET':
      return {
        ...state,
        isChatWidgetOpen: false,
        selectedWorkerId: undefined,
      }
    case 'SAVE_REGIONAL_FILTER': {
      // Wrap around with userId in case multiple user log in with same device
      const storedRegionalFilters: Record<
        string,
        Record<string, string[]>
      > = JSON.parse(localStorage.getItem(REGIONAL_FILTER_KEY) ?? '{}')
      storedRegionalFilters[action.uid] = action.value
      localStorage.setItem(
        REGIONAL_FILTER_KEY,
        JSON.stringify(storedRegionalFilters),
      )
      const selectedLocationsCount = Object.values(action.value).reduce(
        (acc, locations) => acc + locations.length,
        0,
      )
      return {
        ...state,
        regionalFilter: action.value,
        selectedLocationsCount,
      }
    }
    case 'SET_REGIONAL_FILTER_STATUS':
      return {
        ...state,
        regionalFilterStatus: action.value,
      }
    case 'SHOW_REGIONAL_FILTER':
      // Hide the header in supervisor app
      window.ReactNativeWebView?.postMessage(
        JSON.stringify({
          event: WebToRNEventName.FULL_SCREEN_MODAL_OPEN,
        }),
      )
      return {
        ...state,
        isRegionalFilterOpened: true,
      }
    case 'HIDE_REGIONAL_FILTER':
      // Display the header in supervisor app
      window.ReactNativeWebView?.postMessage(
        JSON.stringify({
          event: WebToRNEventName.FULL_SCREEN_MODAL_CLOSE,
        }),
      )
      return {
        ...state,
        isRegionalFilterOpened: false,
      }
    case 'RESET_REGIONAL_FILTER_CONTEXT':
      return {
        ...state,
        regionalFilter: undefined,
        regionalFilterStatus: RegionalFilterStatus.ENABLED,
        isRegionalFilterOpened: false,
      }
    default:
      return state
  }
}

const AppContext = createContext<{
  state: AppContextState
  dispatch: AppContextDispatch
  setIsSideBarOpen: (value: boolean) => void
  setIsChatWidgetOpen: (value: boolean, workerId?: string) => void
}>({
  state: initialState,
  dispatch: () => null,
  setIsSideBarOpen: () => null,
  setIsChatWidgetOpen: () => null,
})

export function useAppContext() {
  return useContext(AppContext)
}

export default function AppContextProvider({
  children,
}: {
  children: JSX.Element
}) {
  const [state, dispatch] = useReducer<AppContextReducer>(reducer, initialState)
  const setIsSideBarOpen = useCallback(
    (value: boolean) =>
      value
        ? dispatch({ type: 'SHOW_SIDEBAR' })
        : dispatch({ type: 'HIDE_SIDEBAR' }),
    [],
  )
  const setIsChatWidgetOpen = useCallback(
    (value: boolean, workerId?: string) => {
      return value
        ? dispatch({ type: 'SHOW_CHAT_WIDGET', payload: { workerId } })
        : dispatch({ type: 'HIDE_CHAT_WIDGET', payload: { workerId } })
    },
    [],
  )

  const exportedContext = {
    state,
    dispatch,
    setIsSideBarOpen,
    setIsChatWidgetOpen,
  }

  return (
    <AppContext.Provider value={exportedContext}>
      {children}
    </AppContext.Provider>
  )
}
