import { useQueryClient, UseQueryResult } from '@tanstack/react-query'
import { useState } from 'react'

export interface Pagination {
  startAt: number
  limit: number
}

/** Standard Pagination, for use when backend supports it */
export function usePagination<Request, Response>(
  queryKey: string,
  request: Request,
  useQuery: (
    request: Request,
    pagination: Pagination,
  ) => UseQueryResult<Response, Error>,
  pageSize: number,
  startPage = 0,
) {
  const [page, setPage] = useState(startPage)
  const [startAt, setStartAt] = useState<number>(page * pageSize)
  const { data, refetch, isLoading, isRefetching } = useQuery(request, {
    startAt,
    limit: pageSize,
  })

  const queryClient = useQueryClient()

  const onPageLeft = () => {
    setStartAt((page - 1) * pageSize)
    setPage(page - 1)
  }

  const onPageRight = () => {
    setStartAt((page + 1) * pageSize)
    setPage(page + 1)
  }

  const invalidateQueryClient = async () => {
    await queryClient.invalidateQueries({ queryKey: [queryKey] })
  }

  const resetPagination = () => {
    setPage(0)
    setStartAt(0)
  }

  return {
    page,
    startAt,
    setStartAt,
    onPageLeft,
    onPageRight,
    data,
    refetch,
    isRefetching,
    isLoading,
    invalidateQueryClient,
    resetPagination,
  }
}
