import { CardTile } from '@traba/react-components'
import { isEmpty } from 'lodash'
import { ChangeEvent, useState } from 'react'
import { megabytesToBytes } from 'src/helpers'
import { theme } from 'src/libs/theme'

import { ButtonVariant } from '../Button'
import Icon from '../Icon'
import Row from '../Row'
import SvgIcon from '../SvgIcon'
import { Text } from '../Text'
import * as S from './MultiImageUploader.styles'

type MultiImageUploaderProps = {
  onAdd: (newFile: File) => void
  previewFiles: string[]
  onDelete?: (index: number) => void
  onError?: (message: string) => void
  maxFileSize?: number
}

export const MultiImageUploader = (props: MultiImageUploaderProps) => {
  const [previewFiles, setFiles] = useState<string[]>(props.previewFiles)
  const { onAdd, onDelete, onError, maxFileSize } = props
  const maxFileSizeInBytes = megabytesToBytes(maxFileSize ?? 0)

  function onAddFile(file: string) {
    setFiles((currentFiles) => [...currentFiles, file])
  }

  function onRemoveFile(i: number) {
    setFiles((currentFiles) => currentFiles.filter((_, index) => i !== index))
    onDelete && onDelete(i)
  }

  function handleChange(ev: ChangeEvent<HTMLInputElement>) {
    if (ev.target.files && ev.target.files.length > 0) {
      const _file = ev.target.files[0]

      if (maxFileSize && _file.size > maxFileSizeInBytes) {
        if (typeof onError === 'function') {
          onError(`Image file cannot be over ${maxFileSize} MB`)
        }
        return
      }

      onAdd(_file)
      onAddFile(URL.createObjectURL(_file))
    }
  }

  return (
    <Row>
      {previewFiles.length < 3 && (
        <>
          <label htmlFor="fileUpload">
            <S.AddContentContainer>
              <CardTile size="48px">
                <SvgIcon color={theme.colors.Grey50} name="plus" />
              </CardTile>
              <Text
                variant="h7"
                style={{
                  marginTop: theme.space.xs,
                  marginBottom: theme.space.xxs,
                }}
              >
                Upload image
              </Text>
              <Text variant="body3">{`${
                maxFileSize ? `Max: ${maxFileSize}MB, ` : ''
              }.jpg or .png`}</Text>
            </S.AddContentContainer>
          </label>
          <S.FileUploadInput
            id="fileUpload"
            type="file"
            onChange={handleChange}
            accept="image/*"
          />
        </>
      )}
      <Row style={{ overflowX: 'auto' }}>
        {!isEmpty(previewFiles) &&
          previewFiles.map((file, i) => (
            <S.ImagePreviewWrapper key={i}>
              <S.ImagePreview src={file} />
              <S.DeleteButton
                slim
                variant={ButtonVariant.OUTLINED}
                onClick={(e) => {
                  e.preventDefault()
                  onRemoveFile(i)
                }}
              >
                <Icon name={'trash'} width={12} height={12} />
              </S.DeleteButton>
            </S.ImagePreviewWrapper>
          ))}
      </Row>
    </Row>
  )
}

export default MultiImageUploader
