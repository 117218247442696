import { theme } from '@traba/theme'
import { COMPANY_WIDE_TEXT, IconName, InputStatus, User } from '@traba/types'
import {
  getLocationNameOrTruncatedAddress,
  getUserFullName,
  isBizInvitationCompanyWide,
} from '@traba/utils'
import React, { useCallback, useState } from 'react'
import { LoadingSpinner, Row, SearchSelect } from '../base-components'
import { Col } from '../base-components/Col'
import { IMenuItem, Tag } from '../base-components/Select/Select'
import { Text } from '../base-components/Text'

const createItemFromMember = (member: Partial<User>): IMenuItem => {
  const tagsRow: Tag[] = isBizInvitationCompanyWide(member)
    ? [
        {
          title: COMPANY_WIDE_TEXT,
          iconName: 'location' as IconName,
          variant: 'darkOrange',
        },
      ]
    : (member.locations || []).map((loc) => ({
        title: getLocationNameOrTruncatedAddress(loc),
        iconName: 'location' as IconName,
        variant: 'business',
      }))
  return {
    value: member.uid as string,
    label: getUserFullName(member),
    tagsRow,
  }
}

export type ReplaceSupervisorsForLocationSectionProps = {
  membersToReplace: Partial<User>[]
  replacementMembers: Partial<User>[]
  memberIdToMemberMap: Map<string, Partial<User>>
  loading?: boolean
  replacementSupervisorMap: Record<string, string> // this map represents the memberToReplaceMemberId -> replacementMemberId
  setReplacementSupervisorMap: React.Dispatch<
    React.SetStateAction<Record<string, string>>
  >
}

export function ReplaceSupervisorsForSingleLocationSection({
  membersToReplace,
  replacementMembers,
  memberIdToMemberMap,
  loading,
  replacementSupervisorMap,
  setReplacementSupervisorMap,
}: ReplaceSupervisorsForLocationSectionProps) {
  const [replacementErrors, setReplacementErrors] = useState<
    Record<string, string | undefined>
  >({})

  const handleSetReplaceUserForUser = useCallback(
    (memberToReplaceId: string) => (menuItem: IMenuItem | undefined) => {
      const newUserId = menuItem?.value
      setReplacementSupervisorMap((prevMap) => ({
        ...prevMap,
        [memberToReplaceId]: newUserId || '',
      }))
      setReplacementErrors((prevErrors) => ({
        ...prevErrors,
        [memberToReplaceId]: undefined,
      }))
    },
    [setReplacementSupervisorMap],
  )

  const getReplacementMembersForMember = useCallback(
    (memberUid: string | undefined) => {
      return replacementMembers
        .filter((m) => {
          const isNotCurrentUser = m.uid !== memberUid
          return isNotCurrentUser
        })
        .map(createItemFromMember)
    },
    [replacementMembers],
  )

  const onBlurSelect = (memberUid: string | undefined) => () => {
    if (memberUid && !replacementSupervisorMap[memberUid]) {
      setReplacementErrors((prevErrors) => ({
        ...prevErrors,
        [memberUid]: 'You must select a replacement member.',
      }))
    }
  }

  if (loading) {
    return <LoadingSpinner />
  }

  const emptyItem = { label: `--`, value: '' }

  return (
    <Col gap={theme.space.sm}>
      {membersToReplace.map((member) => {
        const selectedMemberId = replacementSupervisorMap[member.uid || '']
        const selectedMember = memberIdToMemberMap.get(selectedMemberId)
        const selectedMemberItem = selectedMember
          ? createItemFromMember(selectedMember)
          : emptyItem

        return (
          <Col key={member.uid} gap={theme.space.xxs}>
            <Row gap={theme.space.xxxs} alignCenter>
              <Text variant="h6">{getUserFullName(member)}</Text>
            </Row>
            <SearchSelect
              placeholder="Select a replacement supervisor"
              selectItem={selectedMemberItem}
              handleSelect={handleSetReplaceUserForUser(member.uid || '')}
              options={[
                emptyItem,
                ...getReplacementMembersForMember(member.uid),
              ]}
              style={{ width: '100%' }}
              menuListStyle={{ maxHeight: '300px' }}
              onlyShowLabel
              onBlur={onBlurSelect(member.uid)}
              inputStatus={
                member.uid && replacementErrors[member.uid]
                  ? InputStatus.error
                  : undefined
              }
              errorMessage={member.uid && replacementErrors[member.uid]}
            />
          </Col>
        )
      })}
    </Col>
  )
}
