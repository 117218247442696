import { LocationResponse, RecordStatus } from '@traba/types'
import { isLocationActive } from '@traba/utils'
import { useEffect, useMemo } from 'react'
import {
  RegionalFilterStatus,
  useAppContext,
} from 'src/context/appContext/AppContext'
import { useUserContext } from 'src/context/user/UserContext'
import { useLocations } from './useLocations'
import { OUTSIDE_REGION_ID } from './useRegions'

export const REGIONAL_FILTER_KEY = 'regionalFilter'
export const REGIONAL_FILTER_SELECT_ALL_KEY = 'RegionalFilterSelectAll'
export const ARCHIVED_REGION_ID = 'archived_locations'

export function getRegionalFilterFeatureKey(uid: string) {
  return `FEATURE_ANNOUNCEMENT_${uid}_REGIONAL_FILTER`
}

export function isRegionalFilterEnabled(
  regionalFilterStatus: RegionalFilterStatus,
) {
  return regionalFilterStatus === RegionalFilterStatus.ENABLED
}

export const useRegionalFilterSelectAll = () => {
  const {
    state: { userProfile },
  } = useUserContext()
  const uid = userProfile?.uid ?? ''

  const getRegionalFilterSelectAllSetting = (): boolean => {
    const originalSelectAllSetting = JSON.parse(
      localStorage.getItem(REGIONAL_FILTER_SELECT_ALL_KEY) ?? '{}',
    )
    return originalSelectAllSetting[uid ?? '']
  }

  const enableRegionalFilterSelectAll = () => {
    const originalSelectAllSetting = JSON.parse(
      localStorage.getItem(REGIONAL_FILTER_SELECT_ALL_KEY) ?? '{}',
    )
    originalSelectAllSetting[uid] = true
    localStorage.setItem(
      REGIONAL_FILTER_SELECT_ALL_KEY,
      JSON.stringify(originalSelectAllSetting),
    )
  }

  const disableRegionalFilterSelectAll = () => {
    // Delete from the local storage
    const originalSelectAllSetting = JSON.parse(
      localStorage.getItem(REGIONAL_FILTER_SELECT_ALL_KEY) ?? '{}',
    )
    delete originalSelectAllSetting[uid]
    localStorage.setItem(
      REGIONAL_FILTER_SELECT_ALL_KEY,
      JSON.stringify(originalSelectAllSetting),
    )
  }

  return {
    getRegionalFilterSelectAllSetting,
    enableRegionalFilterSelectAll,
    disableRegionalFilterSelectAll,
  }
}

export const useRegionalFilter = () => {
  const { state: appContextState, dispatch: appContextDispatch } =
    useAppContext()
  const {
    state: { userProfile },
  } = useUserContext()
  const uid = userProfile?.uid ?? ''
  const { getRegionalFilterSelectAllSetting, enableRegionalFilterSelectAll } =
    useRegionalFilterSelectAll()

  const getStoredRegionalFilter = (): Record<string, string[]> | undefined => {
    const storedRegionalFilters: Record<
      string,
      Record<string, string[]>
    > = JSON.parse(localStorage.getItem(REGIONAL_FILTER_KEY) ?? '{}')
    return storedRegionalFilters[uid]
  }

  const reloadRegionalFilter = (locations: LocationResponse[]) => {
    const storedRegionalFilter = getStoredRegionalFilter()

    // If the user is first time using regional filter, set all locations to be selected
    if (!storedRegionalFilter) {
      enableRegionalFilterSelectAll()
    }

    const selectAllOption: boolean = getRegionalFilterSelectAllSetting()
    // Short circuit if selectAllOption is true (just select all of the locations)
    if (selectAllOption) {
      const allRegionsSelected: Record<string, string[]> = {}
      for (const location of locations) {
        // Skip if location.regionId is not in valid region
        if (location.regionId === OUTSIDE_REGION_ID) {
          continue
        }

        // Define a key based on the record status
        const key =
          location.recordStatus === RecordStatus.Archived
            ? ARCHIVED_REGION_ID
            : location.regionId

        if (!allRegionsSelected[key]) {
          allRegionsSelected[key] = []
        }

        // Push the locationId to the appropriate array
        allRegionsSelected[key].push(location.locationId)
      }
      appContextDispatch({
        type: 'SAVE_REGIONAL_FILTER',
        value: allRegionsSelected,
        uid,
      })
      return
    }

    // Remove archived locations and add the updated locations if the original location was archived and new location is created
    const activeLocationMap = new Map<string, LocationResponse>()
    const archivedLocationMap = new Map<string, LocationResponse>()

    for (const location of locations) {
      if (location.recordStatus === RecordStatus.Active) {
        activeLocationMap.set(location.locationId, location)
      } else if (location.recordStatus === RecordStatus.Archived) {
        archivedLocationMap.set(location.locationId, location)
      }
    }

    let needUpdate = false
    const newRegionalFilter: Record<string, string[]> = {}
    for (const regionId in storedRegionalFilter) {
      newRegionalFilter[regionId] = []
      for (const selectedLocation of storedRegionalFilter[regionId]) {
        if (activeLocationMap.has(selectedLocation)) {
          newRegionalFilter[regionId].push(selectedLocation)
        } else if (archivedLocationMap.has(selectedLocation)) {
          needUpdate = true // Update regional filter since selected locations is archived or edited

          // Add the activeLocationId in case the location was edited instead of archived
          const archivedLocation = archivedLocationMap.get(selectedLocation)
          if (
            archivedLocation &&
            archivedLocation.activeLocationId &&
            activeLocationMap.has(archivedLocation.activeLocationId)
          ) {
            // Add the most active location id
            newRegionalFilter[regionId].push(archivedLocation.activeLocationId)
          } else if (archivedLocation && archivedLocation.activeLocationId) {
            if (!newRegionalFilter[ARCHIVED_REGION_ID]) {
              newRegionalFilter[ARCHIVED_REGION_ID] = []
            }
            newRegionalFilter[ARCHIVED_REGION_ID].push(
              archivedLocation.activeLocationId,
            )
          }
        }
      }

      if (newRegionalFilter[regionId].length === 0) {
        delete newRegionalFilter[regionId]
        needUpdate = true
      }
    }
    if (needUpdate || !appContextState.regionalFilter) {
      appContextDispatch({
        type: 'SAVE_REGIONAL_FILTER',
        value: newRegionalFilter,
        uid,
      })
    }
  }

  const { isLocationsFetched, uniqueLocations } = useLocations()
  useEffect(() => {
    if (isLocationsFetched) {
      reloadRegionalFilter(uniqueLocations)
    }
  }, [uniqueLocations])

  return {
    getStoredRegionalFilter,
    reloadRegionalFilter,
  }
}

export function useSelectedRegionalFilterLocations() {
  const {
    state: { regionalFilterStatus, regionalFilter },
  } = useAppContext()

  const { isLoading, uniqueLocations = [] } = useLocations()

  const isRegionalFilterEnabledOnPage =
    isRegionalFilterEnabled(regionalFilterStatus)

  // if regional filter is ENABLED, filter the company's regions/locations based on
  // the selected regions/locations. Otherwise, return ALL regions/locations
  // b/c then it means the page does not want to (or *cannot*) filter
  const selectedRegionIds = useMemo(
    () =>
      new Set(
        isRegionalFilterEnabledOnPage
          ? Object.keys(regionalFilter || {}).flat()
          : uniqueLocations.map((l) => l.regionId),
      ),
    [isRegionalFilterEnabledOnPage, regionalFilter, uniqueLocations],
  )

  const selectedLocationIds = useMemo(
    () =>
      new Set(
        isRegionalFilterEnabledOnPage
          ? Object.values(regionalFilter || {}).flat()
          : uniqueLocations.map((l) => l.locationId),
      ),
    [isRegionalFilterEnabledOnPage, regionalFilter, uniqueLocations],
  )

  const selectedLocations = useMemo(
    () =>
      isRegionalFilterEnabledOnPage
        ? uniqueLocations.filter((loc) =>
            selectedLocationIds.has(loc.locationId),
          )
        : uniqueLocations,
    [isRegionalFilterEnabledOnPage, selectedLocationIds, uniqueLocations],
  )

  const selectedActiveLocations = useMemo(
    () => selectedLocations.filter(isLocationActive),
    [selectedLocations],
  )

  return {
    isLoading,
    isRegionalFilterEnabledOnPage,
    selectedLocationIds,
    selectedRegionIds,
    selectedLocations,
    selectedActiveLocations,
  }
}
