import { UserAccessLevelOrLocationsStringWithTooltip } from '@traba/react-components'
import { USER_ROLE_NAMES } from '@traba/types'
import {
  assignedActiveLocationsForMember,
  isBizMemberCompanyWide,
} from '@traba/utils'
import { Table, Td, Tr } from 'src/components'
import { useUserContext } from 'src/context/user/UserContext'
import { useHotSettings } from 'src/hooks/useSystem'
import { theme } from 'src/libs/theme'
import { UserRole, UserWithRole } from 'src/types'
import { formatPhoneNumber } from 'src/utils/phoneNumberUtils'
import { EditActiveMembersDropdownMenu } from '../EditActiveMembersDropdownMenu'
import { MemberRoleInfoButton } from '../MemberRoleInfoButton'
import { StyledTdEditMenuContainer } from '../MembersProfile.styles'
import { MembersProfilePermission } from '../MembersProfilePermission'

interface ActiveMembersMenuProps {
  member: UserWithRole
}

function ActiveMembersMenu({ member }: ActiveMembersMenuProps) {
  return (
    <StyledTdEditMenuContainer>
      <EditActiveMembersDropdownMenu member={member} />
    </StyledTdEditMenuContainer>
  )
}

interface ActiveMembersTableProps {
  members: UserWithRole[]
  userCanEdit: boolean
  isLoading?: boolean
}

export function ActiveMembersTable(props: ActiveMembersTableProps) {
  const userContext = useUserContext()
  const { hotSettings } = useHotSettings()

  const self = userContext.state.userProfile?.uid
  const adminCount = props.members.filter(
    (m) => m.role === UserRole.Admin,
  ).length

  return (
    <div>
      <Table
        headers={[
          'Name',
          'Email',
          'Phone',
          'Role',
          ...(hotSettings?.enableRegionalAccessPhase2 ? ['Locations'] : []),
          ...(hotSettings?.enableNotificationPreferencesV2
            ? []
            : ['Permissions']),
          ...(props.userCanEdit ? [''] : []),
        ]}
        itemType="members"
        showEmptyState
        isLoading={props.isLoading}
      >
        {props.members.map((m) => (
          <Tr key={m.uid}>
            <Td>
              {m.firstName} {m.lastName}
              {self === m.uid ? ' (You)' : ''}
            </Td>
            <Td>{m.email}</Td>
            <Td>
              {m.phoneNumber ? formatPhoneNumber(m.phoneNumber, true) : ''}
            </Td>
            <Td>{USER_ROLE_NAMES[m.role]}</Td>
            {hotSettings?.enableRegionalAccessPhase2 && (
              <Td>
                <UserAccessLevelOrLocationsStringWithTooltip
                  isCompanyWide={isBizMemberCompanyWide(m)}
                  assignedLocations={assignedActiveLocationsForMember(m)}
                />
              </Td>
            )}
            {!hotSettings?.enableNotificationPreferencesV2 && (
              <Td>
                <MembersProfilePermission member={m} />
              </Td>
            )}
            {props.userCanEdit &&
              self !== m.uid &&
              !(adminCount === 1 && m.role === UserRole.Admin) && (
                <ActiveMembersMenu member={m} />
              )}
          </Tr>
        ))}
      </Table>
      <MemberRoleInfoButton
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: theme.space.xs,
        }}
      />
    </div>
  )
}
