import React from 'react'
import useMobile from 'src/hooks/useMobile'
import { MobileDisclaimer } from '../Disclaimer/MobileDisclaimer'
import { ActiveMembersSection } from './ActiveMembersSection/ActiveMembersSection'
import { ContactsSection } from './ContactsSection'
import { InvitationsSection } from './InvitationsSection'
import * as S from './MembersProfile.styles'

export const MembersProfile: React.FC = () => {
  const { isExclusivelyMobileView } = useMobile()

  if (isExclusivelyMobileView) {
    return <MobileDisclaimer />
  }

  return (
    <S.MembersProfileContainer>
      <ActiveMembersSection />

      <InvitationsSection />

      <ContactsSection />
    </S.MembersProfileContainer>
  )
}
