import { trabaApi } from '@traba/api-utils'
import { MINIMUM_MINUTES_BEFORE_SHIFT } from '@traba/consts'
import { useAlert } from '@traba/context'
import {
  InvitedWorkers,
  ForwardFillMax,
  InvoiceChargeCycle,
  RecordStatus,
  RequiredMultiShiftType,
  Shift,
  ShiftRequest,
  ShiftStatus,
  ShiftPayType,
  Schedule,
  CreateSchedule,
  CreateShiftRequest,
  CreateShiftRequestMetadata,
  RoleInfoForCreateShiftRequest,
} from '@traba/types'
import { doesUserHaveAccessToLocation } from '@traba/utils'
import { AxiosResponse } from 'axios'
import {
  addDays,
  addHours,
  getDay,
  nextDay,
  nextMonday,
  startOfTomorrow,
} from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import { isEmpty } from 'lodash'
import { useReducer } from 'react'
import { useQueryClient } from 'react-query'
import {
  getTimeAfterTimeWithin24Hours,
  setTimeFromDate,
} from 'src/shared/utils/dateUtils'
import { useCompany } from './useCompany'
import { useCompanyEmploymentType } from './useCompanyEmploymentType'
import { useLocations } from './useLocations'
import { useMembers } from './useMembers'
import { useRoles } from './useRoles'
import { useHotSettings } from './useSystem'

export const PAY_RATE_DEFAULT = 16

function getNewShiftTimes(schedule: Schedule) {
  const zonedStartTime = utcToZonedTime(schedule.startTime, schedule.timeZone)
  const dayOfWeek = getDay(zonedStartTime)
  const monday = nextMonday(new Date())

  const newStartDay = dayOfWeek === 1 ? monday : nextDay(monday, dayOfWeek)
  const newStartTime = setTimeFromDate(schedule.startTime, newStartDay)
  const newEndTime = getTimeAfterTimeWithin24Hours(
    setTimeFromDate(schedule.endTime, newStartDay),
    newStartTime,
  )
  const newEndDate = addDays(newEndTime, 13)

  return { newStartTime, newEndTime, newEndDate }
}

function getEndDate(
  isEdit: boolean | undefined,
  schedules: Schedule[],
  newEndDate: Date,
): Date | undefined {
  //
  return isEdit ? schedules[0].recurringSchedule?.endDate : newEndDate
}

export function useDefaultShiftRequest({
  shiftRequestTemplate,
  shiftTemplate,
  isEdit,
  ignoreRecurrence,
}: {
  shiftRequestTemplate?: ShiftRequest
  shiftTemplate?: Shift
  isEdit?: boolean
  ignoreRecurrence?: boolean
}): CreateShiftRequest {
  /**
   * Please note that if you use this function, `useMembers`, `useRoles`, and `useLocations` need to
   * finish loading beforehand. If you look at either `AddToExistingScheduleScreen.tsx` or
   * `BookShiftScreen.tsx`, you'll see that we preemptively call the hooks and render the component
   * after that is finished. Eventually though, we should refactor/nuke this code.
   * */
  const { getMemberById } = useMembers()
  const { getRoleById } = useRoles()
  const { getLocationById } = useLocations()
  const { hotSettings } = useHotSettings()
  const { company } = useCompany()

  const { inviteOnly } = useCompanyEmploymentType()

  const startTime = addHours(startOfTomorrow(), 9)
  const endTime = addHours(startOfTomorrow(), 17)
  const defaultSchedule: CreateSchedule = {
    startTime,
    endTime,
    isRecurringSchedule: false,
    recurringSchedule: undefined,
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  }
  let defaultRequest: CreateShiftRequest = {
    schedules: [defaultSchedule],
    locationId: '',
    companyId: '',
    roleId: '',
    supervisorId: '',
    favoriteWorkersFirst: false,
    allShiftsRequired: false,
    invitedWorkers: InvitedWorkers.ALL,
    scheduledBreaks: [],
    slotsRequested: 0,
    minSlotsRequested: 0,
    trustAndSafetyFeeHourly: { amount: 50, currency: 'USD' },
    workerMedia: [],
    hasPaidBreaks: false,
    status: ShiftStatus.ACTIVE,
    shortLocation: '', // legacy
    employerName: '', // legacy
    additionalEmails: [],
    payType: ShiftPayType.HOURLY,
    payRate: PAY_RATE_DEFAULT,
    ...(!!hotSettings?.allowRequiredMultiShiftInShiftRequest && {
      requiredMultiShiftType:
        company?.requiredMultiShiftType || RequiredMultiShiftType.None,
    }),
    forwardFillMax: inviteOnly
      ? ForwardFillMax.INVITED_ONLY
      : ForwardFillMax.NONE,
    invoiceChargeCycle: InvoiceChargeCycle.WEEKLY,
  }

  if (shiftRequestTemplate && !isEmpty(shiftRequestTemplate)) {
    const {
      roleId,
      locationId,
      parkingLocationId,
      supervisorId,
      parentInvoiceGroupId,
      invitedWorkers,
      shiftInvitations,
      scheduledBreaks,
      schedules,
      additionalEmails,
      minSlotsRequested,
      slotsRequested,
      payRate,
      numberOfUnits,
      payType,
      forwardFillMax,
      genderPreference,
      shiftRequestParentId,
    } = shiftRequestTemplate

    const { newStartTime, newEndTime, newEndDate } = getNewShiftTimes(
      schedules[0],
    )
    const location = getLocationById(locationId)
    const parkingLocation = parkingLocationId
      ? getLocationById(parkingLocationId)
      : undefined
    const foundSupervisor = getMemberById(supervisorId)
    const doesSupervisorHaveAccessAtLocation = doesUserHaveAccessToLocation({
      user: foundSupervisor,
      locationId,
    })

    defaultRequest = {
      ...defaultRequest,
      payRate,
      payType,
      numberOfUnits,
      roleId: getRoleById(roleId) ? roleId : '',
      locationId:
        location && location.recordStatus === RecordStatus.Active
          ? locationId
          : '',
      parkingLocationId: parkingLocationId
        ? parkingLocation &&
          parkingLocation.recordStatus === RecordStatus.Active
          ? parkingLocationId
          : ''
        : undefined,
      supervisorId: doesSupervisorHaveAccessAtLocation ? supervisorId : '',
      parentInvoiceGroupId,
      invitedWorkers,
      genderPreference,
      schedules: schedules.map((schedule) => {
        return {
          ...schedule,
          isRecurringSchedule: ignoreRecurrence
            ? false
            : schedule.isRecurringSchedule,
          startTime: newStartTime,
          endTime: newEndTime,
          recurringSchedule:
            !ignoreRecurrence && schedule.recurringSchedule
              ? {
                  ...schedule.recurringSchedule,
                  endDate: schedule.recurringSchedule.endDate
                    ? getEndDate(isEdit, schedules, newEndDate)
                    : undefined,
                }
              : undefined,
        }
      }),
      scheduledBreaks,
      additionalEmails,
      minSlotsRequested,
      slotsRequested,
      shiftInvitations,
      forwardFillMax,
      shiftRequestParentId,
    }
  }

  /** For Edit Mode as a Default show Properties of the Initial Selected Shift */
  if (isEdit && shiftTemplate && !isEmpty(shiftTemplate)) {
    const {
      roleId,
      locationId,
      parkingLocationId,
      supervisorId,
      invoiceGroupId,
      parentInvoiceGroupId,
      invitedWorkers,
      scheduledBreaks,
      startTime,
      endTime,
      additionalEmails,
      minSlotsRequested,
      slotsRequested,
      genderPreference,
      forwardFillMax,
    } = shiftTemplate

    defaultRequest = {
      ...defaultRequest,
      roleId,
      locationId,
      parkingLocationId,
      supervisorId,
      invoiceGroupId,
      parentInvoiceGroupId,
      invitedWorkers,
      schedules: [
        {
          ...defaultRequest.schedules[0],
          startTime,
          endTime,
        },
      ],
      scheduledBreaks,
      additionalEmails: additionalEmails || defaultRequest.additionalEmails,
      minSlotsRequested,
      slotsRequested,
      genderPreference,
      forwardFillMax,
    }
  }

  return defaultRequest
}

export function useBookShiftRequest({
  shiftRequestTemplate,
  shiftTemplate,
  isEdit,
  ignoreRecurrence,
  recurringRolesTemplate,
}: {
  shiftRequestTemplate?: ShiftRequest
  shiftTemplate?: Shift
  isEdit?: boolean
  ignoreRecurrence?: boolean
  recurringRolesTemplate?: RoleInfoForCreateShiftRequest[]
}) {
  const { company } = useCompany()
  const { members } = useMembers()
  const minutesAheadForShiftPosting =
    company?.minutesAheadForShiftPosting ?? MINIMUM_MINUTES_BEFORE_SHIFT

  const defaultShiftRequest = useDefaultShiftRequest({
    shiftRequestTemplate,
    shiftTemplate,
    isEdit,
    ignoreRecurrence,
  })

  const [shiftRequest, setShiftRequest] = useReducer(
    (
      state: CreateShiftRequest,
      value: Partial<CreateShiftRequest>,
    ): CreateShiftRequest => ({
      ...state,
      ...value,
    }),
    defaultShiftRequest,
  )

  const hasParkingLocationId = isEdit
    ? !!shiftTemplate?.parkingLocationId
    : shiftRequestTemplate?.parkingLocationId !== undefined

  const [shiftRequestMetadata, setShiftRequestMetadata] = useReducer(
    (
      state: CreateShiftRequestMetadata,
      value: Partial<CreateShiftRequestMetadata>,
    ): CreateShiftRequestMetadata => ({
      ...state,
      ...value,
    }),
    {
      scheduleExpanded: !isEmpty(shiftRequestTemplate),
      invitedContactRole: '',
      acceptedFeesAndTerms: undefined,
      activeRegion: null,
      activeParkingRegion: null,
      parkingLocationExpanded: hasParkingLocationId,
      shiftRequestParentTitle: '',
      roleName: '',
      locationName: '',
      recurringRoles: recurringRolesTemplate ?? [],
      minutesAheadForShiftPosting,
      companyUsers: members,
    },
  )

  return {
    defaultShiftRequest,
    shiftRequest,
    setShiftRequest,
    shiftRequestMetadata,
    setShiftRequestMetadata,
  }
}

export const useShiftRequests = () => {
  const { handleError } = useAlert()
  const { getMemberById, members } = useMembers()
  const { company } = useCompany()
  const queryClient = useQueryClient()

  type CreateShiftRequestResponse = ShiftRequest & { firstShiftId?: string }

  const bulkCreateShiftRequest = async (
    shiftRequests: CreateShiftRequest[],
    shiftRequestMetadata?: Partial<CreateShiftRequestMetadata>,
  ) => {
    const shiftRequestParentTitle =
      shiftRequestMetadata?.shiftRequestParentTitle ||
      (shiftRequestMetadata?.locationName
        ? `${shiftRequestMetadata?.locationName} Schedule`
        : undefined)

    try {
      const shiftRequestsToCreate = shiftRequests.map((shiftRequest) => {
        if (shiftRequest.additionalEmails?.length) {
          const pointOfContact = getMemberById(shiftRequest.supervisorId)
          shiftRequest.additionalEmails = shiftRequest.additionalEmails.filter(
            (email) => email !== pointOfContact?.email,
          )
        }

        const shiftCodesReceiverIds = members.reduce(
          (acc, member) => {
            if (
              member.uid &&
              member.uid !== shiftRequest.supervisorId &&
              member.communicationPermissions?.receiveAllShiftCodesSms
            ) {
              acc.push(member.uid)
            }
            return acc
          },
          [shiftRequest.supervisorId],
        )

        return {
          ...shiftRequest,
          employerName: company?.employerName, // legacy - required for now by ShiftRequestRawCompanyFieldsDto
          shiftRequestParentTitle,
          shiftCodesReceiverIds,
        }
      })

      return await trabaApi
        .post<CreateShiftRequestResponse>('/my-company/bulk-shift-requests', {
          shiftRequests: shiftRequestsToCreate,
        })
        .then((response: AxiosResponse<CreateShiftRequestResponse>) => {
          // Invalidate shift stats query for this day to force recalculation
          queryClient.invalidateQueries([
            'shiftStats',
            response.data.schedules[0].startTime.getDate(),
          ])
          return response
        })
    } catch (error: any) {
      const errorMessage = error.message ? error.message : error
      handleError(
        error,
        'useShiftRequests -> bulkCreateShiftRequest()',
        errorMessage,
        'Something went wrong',
      )
    }
  }

  return {
    bulkCreateShiftRequest,
  }
}
