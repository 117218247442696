import { TabPanel, Tab, Tabs, TabsContainer } from '@traba/react-components'
import React from 'react'
import { Text } from 'src/components/base'

import useMobile from 'src/hooks/useMobile'
import { AccountSettingsTab } from './AccountSettings.hooks'
import * as S from './AccountSettings.styles'

export interface AccountSettingsProps {
  activeTabs: AccountSettingsTab[]
  currentTab: number
  setCurrentTab: (arg: number) => void
}

export const AccountSettingsUI: React.FC<AccountSettingsProps> = (props) => {
  const { activeTabs, currentTab, setCurrentTab } = props
  const { isReactNativeApp } = useMobile()

  return (
    <S.AccountSettingsContainer>
      {!isReactNativeApp && (
        <>
          <Text variant="h2">Account Settings</Text>
          <TabsContainer>
            <Tabs
              variant="scrollable"
              value={currentTab}
              onChange={(_, v) => setCurrentTab(v)}
            >
              {activeTabs.map((t, i) => (
                <Tab key={`active-tab-${i}`} label={t.label} />
              ))}
            </Tabs>
          </TabsContainer>
        </>
      )}
      {activeTabs.map(({ Component }, i) => (
        <TabPanel key={`tab-panel-${i}`} value={currentTab} index={i}>
          <Component />
        </TabPanel>
      ))}
    </S.AccountSettingsContainer>
  )
}
