import abandoned from './abandoned.svg'
import accountSettings from './account-settings.svg'
import accountSettings_active from './account-settings_active.svg'
import alert from './alert.svg'
import amex from './amex.svg'
import approveHours from './approve-hours.svg'
import approveHoursAlt from './approve-hours_alt.svg'
import arrived from './arrived.svg'
import arrowBottom_primary from './arrow-bottom-primary.svg'
import arrowTop_primary from './arrow-top-primary.svg'
import backPack from './backPack.svg'
import backup from './backup.svg'
import bell from './bell.svg'
import billing from './billing.svg'
import billing_active from './billing_active.svg'
import billing_small_disabled from './billing_small_disabled.svg'
import billing_small_primary from './billing_small_primary.svg'
import block from './block.svg'
import block_active from './block_active.svg'
import block_simple from './block_simple.svg'
import blocked from './blocked.svg'
import blocked_active from './blocked_active.svg'
import calendar from './calendar.svg'
import calendar_active from './calendar_active.svg'
import cancel from './cancel.svg'
import cardPurple from './card-purple.svg'
import card from './card.svg'
import checkmark from './checkmark.svg'
import chevronDown from './chevron-down.svg'
import chevronLeft from './chevron-left.svg'
import chevronLeftActive from './chevron-left_active.svg'
import chevronRight from './chevron-right.svg'
import chevronRightActive from './chevron-right_active.svg'
import chevronUp from './chevron-up.svg'
import clock from './clock.svg'
import close from './close.svg'
import company from './company.svg'
import complete from './complete.svg'
import dashboard from './dashboard.svg'
import dashboard_active from './dashboard_active.svg'
import document from './document.svg'
import dollar from './dollar.svg'
import editPurple from './edit-purple.svg'
import edit from './edit.svg'
import email_small from './email_small.svg'
import error from './error.svg'
import eyeSlash from './eye-slash.svg'
import find_workers from './find_workers.svg'
import find_workers_active from './find_workers_active.svg'
import es from './flags/es.svg'
import uk from './flags/uk.svg'
import folder from './folder.svg'
import folderGray from './folder_gray.svg'
import greenCheck from './green_check.svg'
import heart from './heart.svg'
import heart_active from './heart_active.svg'
import info from './info.svg'
import leftArrow from './left-arrow.svg'
import location from './location.svg'
import location_unavailable from './location_unavailable.svg'
import logout from './logout.svg'
import mastercard from './mastercard.svg'
import message from './message.svg'
import message_active from './message_active.svg'
import no_show from './no_show.svg'
import not_left from './not_left.svg'
import on_break from './on_break.svg'
import on_shift from './on_shift.svg'
import on_the_way from './on_the_way.svg'
import phone from './phone.svg'
import refer from './refer.svg'
import refer_active from './refer_active.svg'
import rejected from './rejected.svg'
import rotate_left from './rotate_left.svg'
import running_late from './running_late.svg'
import scheduled_block_active from './scheduled-block_active.svg'
import scheduled from './scheduled.svg'
import scheduled_active from './scheduled_active.svg'
import search from './search.svg'
import send from './send.svg'
import settings from './settings.svg'
import sign_up_for_traba from './sign_up_for_traba.svg'
import star from './star.svg'
import star_active from './star_active.svg'
import star_blue from './star_blue.svg'
import star_border_grey from './star_border_grey.svg'
import star_border_purple from './star_border_purple.svg'
import star_filled from './star_filled.svg'
import timesheet from './timesheet.svg'
import timesheet_active from './timesheet_active.svg'
import trash from './trash.svg'
import two_user from './two_user.svg'
import undo from './undo.svg'
import unread_message from './unread_message.svg'
import unread_message_active from './unread_message_active.svg'
import userProfile from './user-profile.svg'
import userProfile_active from './user-profile_active.svg'
import visa from './visa.svg'
import warning from './warning.svg'
import workers from './workers.svg'
import workers_active from './workers_active.svg'

export type SvgIconName =
  | 'arrowBottom_primary'
  | 'arrowTop_primary'
  | 'bell'
  | 'chevronDown'
  | 'chevronUp'
  | 'chevronLeft'
  | 'chevronRight'
  | 'chevronLeftActive'
  | 'chevronRightActive'
  | 'company'
  | 'eyeSlash'
  | 'es'
  | 'info'
  | 'leftArrow'
  | 'logout'
  | 'search'
  | 'backPack'
  | 'settings'
  | 'uk'
  | 'star_blue'
  | 'userProfile'
  | 'userProfile_active'
  | 'leftArrow_completed' // For storybook icons, we can remove these once we have real icons
  | 'eyeSlash_completed'
  | 'eyeSlash_active'
  | 'error'
  | 'dashboard'
  | 'dashboard_active'
  | 'calendar'
  | 'calendar_active'
  | 'workers'
  | 'workers_active'
  | 'timesheet'
  | 'timesheet_active'
  | 'billing'
  | 'refer'
  | 'refer_active'
  | 'sign_up_for_traba'
  | 'billing_active'
  | 'billing_small_primary'
  | 'billing_small_disabled'
  | 'accountSettings'
  | 'accountSettings_active'
  | 'clock'
  | 'edit'
  | 'editPurple'
  | 'trash'
  | 'approveHours'
  | 'checkmark'
  | 'greenCheck'
  | 'phone'
  | 'alert'
  | 'backup'
  | 'on_shift'
  | 'cancel'
  | 'message'
  | 'message_active'
  | 'find_workers'
  | 'find_workers_active'
  | 'unread_message'
  | 'unread_message_active'
  | 'approveHoursAlt'
  | 'amex'
  | 'mastercard'
  | 'visa'
  | 'card'
  | 'cardPurple'
  | 'send'
  | 'document'
  | 'folder'
  | 'folderGray'
  | 'location'
  | 'email_small'
  | 'star'
  | 'abandoned'
  | 'arrived'
  | 'warning'
  | 'complete'
  | 'location_unavailable'
  | 'no_show'
  | 'not_left'
  | 'on_break'
  | 'on_the_way'
  | 'rejected'
  | 'running_late'
  | 'scheduled'
  | 'star_active'
  | 'undo'
  | 'blocked'
  | 'close'
  | 'blocked_active'
  | 'heart'
  | 'dollar'
  | 'heart_active'
  | 'block_simple'
  | 'block'
  | 'block_active'
  | 'scheduled_block_active'
  | 'scheduled_active'
  | 'star_filled'
  | 'star_border_grey'
  | 'star_border_purple'
  | 'two_user'
  | 'rotate_left'

export type SidebarIconName =
  | 'dashboard'
  | 'scheduled'
  | 'calendar'
  | 'workers'
  | 'message'
  | 'unread_message'
  | 'timesheet'
  | 'billing'
  | 'accountSettings'
  | 'workers'
  | 'refer'
  | 'find_workers'

const iconAssets: Record<SvgIconName, string> = {
  arrowBottom_primary,
  arrowTop_primary,
  bell,
  chevronDown,
  chevronUp,
  chevronLeft,
  chevronRight,
  chevronLeftActive,
  chevronRightActive,
  company,
  es,
  eyeSlash,
  eyeSlash_completed: eyeSlash,
  eyeSlash_active: eyeSlash,
  info,
  leftArrow,
  find_workers,
  find_workers_active,
  logout,
  search,
  settings,
  uk,
  userProfile,
  userProfile_active,
  leftArrow_completed: leftArrow,
  backPack,
  error,
  dashboard,
  dashboard_active,
  calendar,
  calendar_active,
  workers,
  workers_active,
  timesheet,
  timesheet_active,
  billing,
  refer,
  refer_active,
  sign_up_for_traba,
  billing_active,
  accountSettings,
  accountSettings_active,
  clock,
  backup,
  on_shift,
  edit,
  editPurple,
  trash,
  approveHours,
  checkmark,
  greenCheck,
  phone,
  cancel,
  message,
  message_active,
  unread_message,
  unread_message_active,
  approveHoursAlt,
  dollar,
  amex,
  mastercard,
  visa,
  card,
  abandoned,
  arrived,
  complete,
  location_unavailable,
  no_show,
  not_left,
  on_break,
  on_the_way,
  rejected,
  running_late,
  scheduled,
  cardPurple,
  send,
  billing_small_primary,
  billing_small_disabled,
  document,
  folder,
  folderGray,
  alert,
  location,
  email_small,
  star,
  star_active,
  undo,
  blocked,
  close,
  blocked_active,
  star_blue,
  heart,
  heart_active,
  block_simple,
  block,
  block_active,
  scheduled_block_active,
  scheduled_active,
  star_border_grey,
  star_border_purple,
  star_filled,
  two_user,
  rotate_left,
  warning,
}

export default iconAssets
