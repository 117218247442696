import { Checkbox, SelectChangeEvent } from '@mui/material'
import { theme } from '@traba/theme'
import React from 'react'
import Row from '../Row'
import { StyledSelectCheckbox } from '../Select/StyledSelectCheckbox'
import { Text } from '../Text'

export interface SearchSelectGroupTitleProps {
  title?: string
  groupId: string
  isGroupSelected?: boolean
  onSelectGroup?: (e: SelectChangeEvent<string>) => void
}

export function SearchSelectGroupTitle({
  title,
  groupId,
  isGroupSelected,
  onSelectGroup,
}: SearchSelectGroupTitleProps) {
  if (!title) {
    return null
  }

  const showCheckbox = !!onSelectGroup
  return (
    <Row
      alignCenter
      py={theme.space.xms}
      px={theme.space.xs}
      pl={showCheckbox ? theme.space.xsmed : theme.space.xs}
      style={{ backgroundColor: theme.colors.Grey20 }}
    >
      {showCheckbox && (
        <Checkbox
          checked={isGroupSelected}
          style={{
            padding: theme.space.xxs,
            margin: '-4px',
            marginRight: theme.space.xxxs,
          }}
          icon={<StyledSelectCheckbox />}
          checkedIcon={<StyledSelectCheckbox checked />}
          onChange={onSelectGroup}
          value={groupId}
        />
      )}
      <Text variant="body3" color={theme.colors.Grey80}>
        {title.toUpperCase()}
      </Text>
    </Row>
  )
}
