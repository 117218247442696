import { LoadingSpinner, MODAL_SIZE, TabPanel } from '@traba/react-components'
import { Roster } from '@traba/types'
import React, { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { MainLayout } from 'src/components'
import {
  Button,
  ButtonVariant,
  EmptyPlaceholderTile,
  Input,
  Row,
  Text,
} from 'src/components/base'
import { Modal } from 'src/components/base/Modal/Modal'
import { PaginationDeprecated } from 'src/components/base/PaginationDeprecated'
import { EmptyDataLocationSubtitle } from 'src/components/base/RegionalFilterSelect/EmptyDataLocationSubtitle'
import { RegionalFilterButton } from 'src/components/base/RegionalFilterSelect/RegionalFilterButton'
import { ConfirmationDialog } from 'src/components/Modals/ConfirmationDialog/ConfirmationDialog'
import { EditRosterModal } from 'src/components/Modals/RosterModal/EditRosterModal'
import { RosterModal } from 'src/components/Modals/RosterModal/RosterModal'
import {
  ReviewWorkerModalContent,
  ReviewWorkerModalContentProps,
} from 'src/components/ReviewWorker/components/ReviewWorkerModalContent.legacy'
import RostersTable from 'src/components/RostersTable/RostersTable'
import { TodaysWorkers } from 'src/components/TodaysWorkers'
import { MobileWorkersTabs } from 'src/components/Workers/MobileWorkersTabs'
import { WorkersTabs } from 'src/components/Workers/WorkersTabs'
import WorkerTable from 'src/components/WorkerTable/WorkerTable'
import {
  RegionalFilterStatus,
  useAppContext,
} from 'src/context/appContext/AppContext'
import {
  useCompanyWorkersByIds,
  WorkerDetails,
} from 'src/hooks/useCompanyWorkers'
import { useConnections } from 'src/hooks/useConnections'
import useMobile from 'src/hooks/useMobile'
import { RosterWithWorkers } from 'src/hooks/useRoster'
import { useRosters } from 'src/hooks/useRosters'
import { useSimplePagination } from 'src/hooks/useShiftPagination'
import { useUserPermissions } from 'src/hooks/useUser'
import { useHiredWorkers } from 'src/hooks/useWorkerJobPlacements'
import { theme } from 'src/libs/theme'
import { ConnectionType, UserRolePermission } from 'src/types'
import { filterWorkersByNameStringSearch } from 'src/utils/searchFilterUtils'

export const DEFAULT_PAGE_SIZE = 150

enum tabIndex {
  TODAY,
  ALL,
  FAVORITE,
  BLOCKED,
  ROSTER,
  HIRED,
}

export default function WorkersScreen() {
  const { dispatch: appContextDispatch } = useAppContext()
  const { isReactNativeApp, isMobileViewOrReactNative } = useMobile()
  const [searchFilter, setSearchFilter] = useState<string>('')

  const navigate = useNavigate()
  const { search } = useLocation()

  const tab = new URLSearchParams(search).get('t')
  const userCanManageWorkers = useUserPermissions([
    UserRolePermission.ManageWorkers,
  ])
  const [tabIdx, setTabIdx] = React.useState(tab ? parseInt(tab) : 0)
  const handleTabs = (e: React.SyntheticEvent<Element, Event>, val: number) => {
    setTabIdx(val)
    appContextDispatch({
      type: 'SET_REGIONAL_FILTER_STATUS',
      value:
        // If going into roster tab, disable the regional filter, otherwise, enable it
        val === tabIndex.ROSTER
          ? RegionalFilterStatus.DISABLED
          : RegionalFilterStatus.ENABLED,
    })
    navigate(`/workers?t=${val}`)
  }
  const [isCreateRosterModalOpen, setIsCreateRosterModalOpen] =
    React.useState(false)
  const [workersForRoster, setWorkersForRoster] = React.useState(
    new Set<string>(),
  )
  const [rosterToDelete, setRosterToDelete] = React.useState<
    Roster | undefined
  >(undefined)
  const [rosterToEdit, setRosterToEdit] = React.useState<
    Roster | RosterWithWorkers | undefined
  >(undefined)
  const [reviewModal, setReviewModal] = useState<
    Pick<ReviewWorkerModalContentProps, 'worker' | 'workerShift'> | undefined
  >(undefined)

  const {
    favoriteWorkers,
    blockedWorkers,
    scheduledBlockWorkers,
    isBlocked,
    isFavorite,
    isIneligible,
    isScheduledBlock,
    favoriteWorker,
  } = useConnections()

  const {
    rosters,
    isLoading: isLoadingRosters,
    removeRoster,
    updateRoster,
  } = useRosters()

  // Filter workers by regions selected in the regional filter
  const { data: allWorkers, isLoading: allLoading } = useCompanyWorkersByIds({
    filterByRegion: true,
  })

  const { data: allHiredWorkers, isLoading: allHiredLoading } =
    useHiredWorkers()

  const {
    data: allWorkersWithValidAccountStatus,
    isLoading: isLoadingAllWorkersWithValidAccountStatus,
  } = useCompanyWorkersByIds({
    filterByRegion: true,
    excludeInvalidStatusWorkers: true,
  })
  useEffect(() => {
    appContextDispatch({
      type: 'SET_REGIONAL_FILTER_STATUS',
      value:
        // If going into roster tab, disable the regional filter, otherwise, enable it
        tabIdx === tabIndex.ROSTER
          ? RegionalFilterStatus.DISABLED
          : RegionalFilterStatus.ENABLED,
    })
    return () => {
      appContextDispatch({
        type: 'SET_REGIONAL_FILTER_STATUS',
        value: RegionalFilterStatus.HIDE,
      })
    }
  }, [appContextDispatch])

  const memoAllWorkersWithValidAccountStatus = useMemo(() => {
    const favoriteWorkersSet = new Set(favoriteWorkers)
    const hiredWorkersSet = new Set(allHiredWorkers?.workerIds)
    const blockedWorkersSet = new Set([
      ...blockedWorkers,
      ...scheduledBlockWorkers,
    ])
    const sortedWorkers = allWorkersWithValidAccountStatus
      ? allWorkersWithValidAccountStatus.sort(
          (a: WorkerDetails, b: WorkerDetails) =>
            a.worker.firstName.localeCompare(b.worker.firstName),
        )
      : []
    const favorites: WorkerDetails[] = []
    const blocks: WorkerDetails[] = []
    const nonBlockedWorkers: WorkerDetails[] = []
    const hiredWorkers: WorkerDetails[] = []

    sortedWorkers?.forEach((worker: WorkerDetails) => {
      if (hiredWorkersSet.has(worker.worker.uid || worker.worker.workerId)) {
        hiredWorkers.push(worker)
      }
      if (favoriteWorkersSet.has(worker.worker.uid)) {
        favorites.push(worker)
      }
      if (blockedWorkersSet.has(worker.worker.uid)) {
        blocks.push(worker)
      } else {
        nonBlockedWorkers.push(worker)
      }
    })

    return {
      sortedWorkers,
      favorites,
      blocks,
      nonBlockedWorkers,
      hiredWorkers,
    }
  }, [
    favoriteWorkers,
    blockedWorkers,
    scheduledBlockWorkers,
    allWorkersWithValidAccountStatus,
  ])

  const { sortedWorkers, favorites, blocks, nonBlockedWorkers, hiredWorkers } =
    memoAllWorkersWithValidAccountStatus

  const searchInput = (
    <Input
      placeholder="Search workers..."
      leftIconName="search"
      name="workerSearch"
      containerStyle={{
        marginBottom: theme.space.sm,
      }}
      type="text"
      defaultValue=""
      width={isMobileViewOrReactNative ? '100%' : '33%'}
      value={searchFilter}
      onChange={(e) => {
        e.preventDefault()
        setSearchFilter(e.target.value)
        resetPagination()
      }}
      onClear={() => {
        setSearchFilter('')
      }}
    />
  )

  const filteredAllWorkers = useMemo(() => {
    return filterWorkersByNameStringSearch(sortedWorkers, searchFilter)
  }, [sortedWorkers, searchFilter])

  const filteredFavorites = useMemo(() => {
    return filterWorkersByNameStringSearch(favorites, searchFilter)
  }, [favorites, searchFilter])

  const filteredBlocks = useMemo(() => {
    return filterWorkersByNameStringSearch(blocks, searchFilter)
  }, [blocks, searchFilter])

  const filteredHired = useMemo(() => {
    return filterWorkersByNameStringSearch(hiredWorkers, searchFilter)
  }, [hiredWorkers, searchFilter])

  const {
    page: pageAllWorkers,
    data: allPageData,
    onPageLeft: onPageLeftAllWorkers,
    onPageRight: onPageRightAllWorkers,
    resetPagination,
  } = useSimplePagination<WorkerDetails>(DEFAULT_PAGE_SIZE, filteredAllWorkers)
  const {
    page: pageFavorites,
    data: favoritesPageData,
    onPageLeft: onPageLeftFavorites,
    onPageRight: onPageRightFavorites,
  } = useSimplePagination<WorkerDetails>(DEFAULT_PAGE_SIZE, filteredFavorites)
  const {
    page: pageBlocked,
    data: blocksPageData,
    onPageLeft: onPageLeftBlocked,
    onPageRight: onPageRightBlocked,
  } = useSimplePagination<WorkerDetails>(DEFAULT_PAGE_SIZE, filteredBlocks)

  const {
    page: pageHiredWorkers,
    data: hiredWorkersPageData,
    onPageLeft: onPageLeftHiredWorkers,
    onPageRight: onPageRightHiredWorkers,
  } = useSimplePagination<WorkerDetails>(DEFAULT_PAGE_SIZE, filteredHired)

  const allToDisplay = allPageData.slice(0, DEFAULT_PAGE_SIZE)
  const favoritesToDisplay = favoritesPageData.slice(0, DEFAULT_PAGE_SIZE)
  const blocksToDisplay = blocksPageData.slice(0, DEFAULT_PAGE_SIZE)
  const hiredToDisplay = hiredWorkersPageData.slice(0, DEFAULT_PAGE_SIZE)

  return (
    <MainLayout title="Workers Management">
      <Text variant="h2">Workers Management</Text>
      {!isMobileViewOrReactNative && (
        <Text style={{ marginTop: '16px' }} variant="body2">
          Manage your workers here. Workers signed up for futures shifts will be
          shared first, then favorite workers. Blocked workers will be hidden.
        </Text>
      )}
      {isReactNativeApp && (
        <Row
          style={{ marginTop: theme.space.xs, marginBottom: theme.space.zero }}
        >
          <RegionalFilterButton />
        </Row>
      )}
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          marginTop: isReactNativeApp ? undefined : theme.space.sm,
        }}
      >
        {isMobileViewOrReactNative ? (
          <MobileWorkersTabs tabIdx={tabIdx} handleTabs={handleTabs} />
        ) : (
          <WorkersTabs tabIdx={tabIdx} handleTabs={handleTabs} />
        )}
        <div style={{ marginLeft: 'auto' }}>
          {tabIdx === tabIndex.ALL ? (
            <PaginationDeprecated
              page={pageAllWorkers}
              pageSize={DEFAULT_PAGE_SIZE}
              onPageLeft={onPageLeftAllWorkers}
              onPageRight={onPageRightAllWorkers}
              dataSize={allPageData?.length}
            />
          ) : tabIdx === tabIndex.FAVORITE ? (
            <PaginationDeprecated
              page={pageFavorites}
              pageSize={DEFAULT_PAGE_SIZE}
              onPageLeft={onPageLeftFavorites}
              onPageRight={onPageRightFavorites}
              dataSize={favoritesPageData.length}
            />
          ) : tabIdx === tabIndex.BLOCKED ? (
            <PaginationDeprecated
              page={pageBlocked}
              pageSize={DEFAULT_PAGE_SIZE}
              onPageLeft={onPageLeftBlocked}
              onPageRight={onPageRightBlocked}
              dataSize={blocksPageData.length}
            />
          ) : tabIdx === tabIndex.HIRED ? (
            <PaginationDeprecated
              page={pageHiredWorkers}
              pageSize={DEFAULT_PAGE_SIZE}
              onPageLeft={onPageLeftHiredWorkers}
              onPageRight={onPageRightHiredWorkers}
              dataSize={hiredWorkersPageData.length}
            />
          ) : null}
        </div>
      </div>
      <TabPanel value={tabIdx} index={tabIndex.TODAY}>
        <Row mt={theme.space.sm}>
          <TodaysWorkers />
        </Row>
      </TabPanel>
      <TabPanel value={tabIdx} index={tabIndex.ALL}>
        {allLoading ? (
          <LoadingSpinner />
        ) : allWorkers && allWorkers.length ? (
          <>
            {searchInput}
            <WorkerTable
              data={allToDisplay}
              userCanManageWorkers={userCanManageWorkers}
              isBlocked={isBlocked}
              isFavorite={isFavorite}
              isIneligible={isIneligible}
              isScheduledBlock={isScheduledBlock}
              setReviewModal={setReviewModal}
              favoriteWorker={favoriteWorker}
            />
          </>
        ) : (
          <div style={{ marginTop: theme.space.xxs }}>
            <EmptyPlaceholderTile
              title="All your past workers will appear here"
              subtitle={
                <EmptyDataLocationSubtitle
                  isMobileView={isMobileViewOrReactNative}
                  extraText="to see workers in those regions."
                />
              }
              iconName="folder"
            />
          </div>
        )}
      </TabPanel>
      <TabPanel value={tabIdx} index={tabIndex.FAVORITE}>
        {allLoading ? (
          <LoadingSpinner />
        ) : favoritesToDisplay && favoritesToDisplay.length ? (
          <>
            {searchInput}
            <WorkerTable
              data={favoritesToDisplay}
              userCanManageWorkers={userCanManageWorkers}
              isBlocked={isBlocked}
              isFavorite={isFavorite}
              isIneligible={isIneligible}
              isScheduledBlock={isScheduledBlock}
              setReviewModal={setReviewModal}
              favoriteWorker={favoriteWorker}
            />
          </>
        ) : (
          <div style={{ marginTop: theme.space.xxs }}>
            <EmptyPlaceholderTile
              title="Your favorite workers will appear here"
              subtitle={
                <EmptyDataLocationSubtitle
                  isMobileView={isMobileViewOrReactNative}
                  extraText="to see workers in those regions."
                />
              }
              iconName="folder"
            />
          </div>
        )}
      </TabPanel>
      <TabPanel value={tabIdx} index={tabIndex.BLOCKED}>
        {allLoading ? (
          <LoadingSpinner />
        ) : blocksToDisplay && blocksToDisplay.length ? (
          <>
            {searchInput}
            <WorkerTable
              data={blocksToDisplay}
              userCanManageWorkers={userCanManageWorkers}
              isBlocked={isBlocked}
              isFavorite={isFavorite}
              isIneligible={isIneligible}
              isScheduledBlock={isScheduledBlock}
              setReviewModal={setReviewModal}
              favoriteWorker={favoriteWorker}
            />
          </>
        ) : (
          <div style={{ marginTop: theme.space.xxs }}>
            <EmptyPlaceholderTile
              title="Your blocked workers will appear here"
              subtitle={
                <EmptyDataLocationSubtitle
                  isMobileView={isMobileViewOrReactNative}
                  extraText="to see workers in those regions."
                />
              }
              iconName="folder"
            />
          </div>
        )}
      </TabPanel>
      <TabPanel value={tabIdx} index={tabIndex.ROSTER}>
        <RostersTab
          isLoadingRosters={
            isLoadingRosters || isLoadingAllWorkersWithValidAccountStatus
          }
          rosters={rosters}
          setWorkersForRoster={setWorkersForRoster}
          setIsCreateRosterModalOpen={setIsCreateRosterModalOpen}
          setRosterToDelete={setRosterToDelete}
          setRosterToEdit={setRosterToEdit}
        />
      </TabPanel>
      <TabPanel value={tabIdx} index={tabIndex.HIRED}>
        {allHiredLoading ? (
          <LoadingSpinner />
        ) : allHiredWorkers?.workerIds?.length ? (
          <>
            {searchInput}
            <WorkerTable
              data={hiredToDisplay}
              userCanManageWorkers={false}
              isBlocked={isBlocked}
              isFavorite={isFavorite}
              isIneligible={isIneligible}
              isScheduledBlock={isScheduledBlock}
              setReviewModal={setReviewModal}
              favoriteWorker={favoriteWorker}
              hideConnections={true}
            />
          </>
        ) : (
          <div style={{ marginTop: theme.space.xxs }}>
            <EmptyPlaceholderTile
              title="All your hired workers will appear here"
              subtitle={
                <EmptyDataLocationSubtitle
                  isMobileView={isMobileViewOrReactNative}
                  extraText="to see workers in those regions."
                />
              }
              iconName="folder"
            />
          </div>
        )}
      </TabPanel>
      <Modal
        size={MODAL_SIZE.LARGE}
        isOpen={!!reviewModal}
        handleClose={() => setReviewModal(undefined)}
      >
        <ReviewWorkerModalContent
          onClose={() => setReviewModal(undefined)}
          variation={ConnectionType.FAVORITE}
          {...reviewModal!}
        />
      </Modal>
      <RosterModal
        isOpen={isCreateRosterModalOpen}
        setIsOpen={() => setIsCreateRosterModalOpen(false)}
        workers={nonBlockedWorkers}
        initialSelectedWorkers={workersForRoster}
      />
      {rosterToEdit && (
        <EditRosterModal
          workers={nonBlockedWorkers}
          roster={rosterToEdit}
          setRoster={setRosterToEdit}
          updateRoster={updateRoster}
        />
      )}
      <ConfirmationDialog
        open={!!rosterToDelete}
        onConfirm={() => {
          rosterToDelete && removeRoster(rosterToDelete.id)
          setRosterToDelete(undefined)
        }}
        onClose={() => setRosterToDelete(undefined)}
        title="Delete Roster?"
        onConfirmCTA="Delete"
        confirmationText={`Are you sure you want to delete ${rosterToDelete?.rosterName}?`}
      />
    </MainLayout>
  )
}

function RostersTab({
  isLoadingRosters,
  rosters,
  setWorkersForRoster,
  setIsCreateRosterModalOpen,
  setRosterToDelete,
  setRosterToEdit,
}: {
  isLoadingRosters: boolean
  rosters: Roster[] | undefined
  setWorkersForRoster: React.Dispatch<React.SetStateAction<Set<string>>>
  setIsCreateRosterModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  setRosterToDelete: React.Dispatch<React.SetStateAction<Roster | undefined>>
  setRosterToEdit: React.Dispatch<
    React.SetStateAction<Roster | RosterWithWorkers | undefined>
  >
}) {
  return isLoadingRosters ? (
    <LoadingSpinner />
  ) : rosters && rosters.length ? (
    <RostersTable
      data={rosters}
      onClickNewRoster={() => {
        setWorkersForRoster(new Set())
        setIsCreateRosterModalOpen(true)
      }}
      onClickDeleteRoster={setRosterToDelete}
      onClickEditRoster={(roster) => {
        setRosterToEdit(roster)
      }}
    />
  ) : (
    <div style={{ marginTop: theme.space.xxs }}>
      <EmptyPlaceholderTile
        iconName="workers_active"
        title="Your rosters will appear here"
        subtitle="Create a roster to easily assign shifts to a group of your past workers."
        content={
          <Button
            slim
            variant={ButtonVariant.FILLED}
            style={{ marginTop: theme.space.sm }}
            trackingEvent={{ message: 'User Clicked New Roster' }}
            onClick={() => {
              setWorkersForRoster(new Set())
              setIsCreateRosterModalOpen(true)
            }}
          >
            New Roster
          </Button>
        }
      />
    </div>
  )
}
