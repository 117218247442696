import { WorkerDetails } from 'src/hooks/useCompanyWorkers'
import useMobile from 'src/hooks/useMobile'

import { ReviewWorkerModalContentProps } from '../ReviewWorker/components/ReviewWorkerModalContent.legacy'
import { Table } from '../Table/Table'
import { MobileWorkerTableRow } from './MobileWorkerTableRow'
import { WorkerTableRow } from './WorkerTableRow'

type WorkerTableProps = {
  headers?: string[]
  data: WorkerDetails[]
  userCanManageWorkers: boolean
  setReviewModal: (
    data: Pick<ReviewWorkerModalContentProps, 'worker' | 'workerShift'>,
  ) => void
  isBlocked: (workerId: string) => boolean
  isFavorite: (workerId: string) => boolean
  isIneligible: (workerId: string) => boolean
  isScheduledBlock: (workerId: string) => boolean
  favoriteWorker: (workerId: string) => void
  hideConnections?: boolean
}

const DEFAULT_HEADERS = [
  'WORKER NAME',
  'LAST WORKED WITH YOU',
  '# SHIFTS WITH YOU',
  '',
]

const DEFAULT_REACT_NATIVE_HEADERS: string[] = []

export const WorkerTable = (props: WorkerTableProps) => {
  const {
    headers,
    data,
    isBlocked,
    isIneligible,
    isFavorite,
    isScheduledBlock,
    userCanManageWorkers,
    setReviewModal,
    favoriteWorker,
    hideConnections = false,
  } = props
  const { isMobileViewOrReactNative } = useMobile()

  return (
    <Table
      headers={
        headers ||
        (isMobileViewOrReactNative
          ? DEFAULT_REACT_NATIVE_HEADERS
          : DEFAULT_HEADERS)
      }
      centerHeaders
      style={{ tableLayout: 'fixed' }}
    >
      {data?.map((workerDetails: WorkerDetails) =>
        isMobileViewOrReactNative ? (
          <MobileWorkerTableRow
            {...workerDetails}
            isBlocked={isBlocked}
            isIneligible={isIneligible}
            isFavorite={isFavorite}
            isScheduledBlock={isScheduledBlock}
            favoriteWorker={favoriteWorker}
            userCanManageWorkers={userCanManageWorkers}
            setReviewModal={setReviewModal}
            hideConnections={hideConnections}
          />
        ) : (
          <WorkerTableRow
            {...workerDetails}
            isBlocked={isBlocked}
            isIneligible={isIneligible}
            isFavorite={isFavorite}
            isScheduledBlock={isScheduledBlock}
            favoriteWorker={favoriteWorker}
            userCanManageWorkers={userCanManageWorkers}
            setReviewModal={setReviewModal}
            hideConnections={hideConnections}
          />
        ),
      )}
    </Table>
  )
}

export default WorkerTable
