import { EmploymentType } from './employmentType'

export enum BackgroundCheckStatus {
  PENDING = 'PENDING',
  INVITATION_CREATED = 'INVITATION_CREATED',
  REPORT_CREATED = 'REPORT_CREATED',
  CONSIDER = 'CONSIDER',
  CLEAR = 'CLEAR',
  SUSPENDED = 'SUSPENDED',
  DISPUTE = 'DISPUTE',
  CANCELED = 'CANCELED',
  COMPLETE = 'COMPLETE',
}

export enum DetailedBackgroundCheckStatus {
  // From BackgroundCheckStatus
  PENDING = 'PENDING',
  INVITATION_CREATED = 'INVITATION_CREATED',
  REPORT_CREATED = 'REPORT_CREATED',
  CONSIDER = 'CONSIDER',
  CLEAR = 'CLEAR',
  SUSPENDED = 'SUSPENDED',
  DISPUTE = 'DISPUTE',
  CANCELED = 'CANCELED',
  COMPLETE = 'COMPLETE',

  // From AdjudicationStatus
  POST_ADVERSE_ACTION = 'POST_ADVERSE_ACTION',
  PRE_ADVERSE_ACTION = 'PRE_ADVERSE_ACTION',

  // From BackgroundCheckAssessment
  ELIGIBLE = 'ELIGIBLE',
  REVIEW = 'REVIEW',
  ESCALATED = 'ESCALATED',

  // Derived status
  ELIGIBLE_AFTER_REVIEW = 'ELIGIBLE_AFTER_REVIEW',
}

export enum ApprovalStatusReason {
  WorkerCommittedFraud = 'Worker Committed Fraud',
  WorkerCommittedTheft = 'Worker Committed Theft',
  OffPlatformActivity = 'Off-platform Activity',
  Violence = 'Violence',
  UnprofessionalBehavior = 'Unprofessional Behavior',
  CriminalActivity = 'Criminal Activity',
  UnderTheInfluence = 'Under The Influence',
  AbuseOrHarassment = 'Abuse/Harassment',
  NoShowAtShift = 'No Show at Shift',
  LateToShift = 'Late to shift',
  AbandonedShift = 'Abandoned Shift',
  LateCancellation = 'Late Shift Cancellation',
  DupeSSNAndDoB = 'DUPE_SSN_DOB', //new worker reported ssn&dob already exist in db
  SSNAndDoBMismatch = 'SSN_DOB_MISMATCH', //worker reported ssn&dob mismatch with checkr results
  None = 'None',
}

export type BgcCandidate = {
  firstName?: string
  middleName?: string
  lastName?: string
  dob?: string
  last4SSN?: string
}

export enum BGCRequirement {
  CLEAN = 'CLEAN',
  STANDARD = 'STANDARD',
}

export type AdjudicationStatus =
  | 'ENGAGED'
  | 'POST_ADVERSE_ACTION'
  | 'PRE_ADVERSE_ACTION'

export type BackgroundCheckAssessment = 'ELIGIBLE' | 'REVIEW' | 'ESCALATED'

export type BackgroundCheck = {
  invitationId?: string | null
  candidateId?: string | null
  reportId?: string | null
  status?: BackgroundCheckStatus | null
  adjudication?: AdjudicationStatus | null
  assessment?: BackgroundCheckAssessment | null
  createdAt?: Date
  completedAt?: Date | null
  updatedAt?: Date | null
  candidate?: BgcCandidate | null
  reportedDob?: string | null
}

export enum InstantPayEligibilityStatus {
  ELIGIBLE = 'ELIGIBLE',
  INELIGIBLE = 'INELIGIBLE',
  ELIGIBLE_WITH_WARNING = 'ELIGIBLE_WITH_WARNING',
  PERMANENTLY_INELIGIBLE = 'PERMANENTLY_INELIGIBLE',
}

export enum InstantPayIneligibilityReason {
  NOT_ENOUGH_SHIFTS = 'NOT_ENOUGH_SHIFTS',
  REVOKED_DUE_TO_INCIDENT = 'REVOKED_DUE_TO_INCIDENT',
  BELOW_MIN_RELIABILITY = 'BELOW_MIN_RELIABILITY',
  BELOW_MIN_OTA = 'BELOW_MIN_OTA',
  BELOW_MIN_LOCATION = 'BELOW_MIN_LOCATION',
  BELOW_PERFECT_SHIFT_STREAK_REQUIREMENT = 'BELOW_PERFECT_SHIFT_STREAK_REQUIREMENT',
}

export type InstantPayEligibility = {
  status: InstantPayEligibilityStatus
  reasons?: InstantPayIneligibilityReason[]
}

export type AccountStatus = {
  id?: string
  accountStatus: AccountApprovalStatus
  approvalStatusReason?: ApprovalStatusReason
  activeRegion?: boolean
  approvedAt?: Date
  createdAt: Date
  payment?: {
    setupStatus: string
    createdAt: Date
    instantPayEligible?: boolean
    instantPayEnabled?: boolean
    instantPayOnboardingComplete?: boolean
    instantPayEligibility?: InstantPayEligibility
    payoutsEnabled?: boolean
    payoutsDisabledReason?: string
    payoutsDisabledDeadline?: Date
  }
  backgroundCheck?: BackgroundCheck
  w9Form?: {
    status: W9FormStatus
    updatedAt: Date
  }
  stash?: Stash
  isTrainingShiftComplete?: boolean
  suspensionExpiration?: Date
  activeEmploymentTypes?: EmploymentType[]
  phoneNumberStatus?: PhoneNumberStatus
}

export type WorkerAccountStatusForBusiness = Pick<
  AccountStatus,
  'isTrainingShiftComplete'
>

export type Stash = {
  accountStatus: AccountApprovalStatus
  backgroundCheck: BackgroundCheck
  reinstatedAt?: Date
}

export enum W9FormStatus {
  Unverified = 'UNVERIFIED',
  Pending = 'PENDING',
  Verified = 'VERIFIED',
}

export enum AccountApprovalStatus {
  Approved = 'APPROVED',
  Unapproved = 'UNAPPROVED',
  Rejected = 'REJECTED',
  Banned = 'BANNED',
  Suspended = 'SUSPENDED',
  Terminated = 'TERMINATED',
  TerminationPending = 'TERMINATION_PENDING',
}

export enum PhoneNumberStatus {
  VERIFIED = 'VERIFIED',
  UNREACHABLE = 'UNREACHABLE',
  WRONG_NUMBER = 'WRONG_NUMBER',
}
