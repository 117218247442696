import { theme } from '@traba/theme'
import React from 'react'
import Row from '../Row'
import { SvgIcon } from '../SvgIcon'
import { Text } from '../Text'

interface Props {
  text?: string
}

export function InputInfoCalloutRow({ text }: Props) {
  if (!text) {
    return null
  }

  return (
    <Row alignCenter gap={theme.space.xxs}>
      <SvgIcon name="alert" size={16} color={theme.colors.Grey60} />
      <Text variant="body3">{text}</Text>
    </Row>
  )
}
