import { useHotSettings } from '@traba/hooks'
import { CardTile, DotMenu, DotMenuProps } from '@traba/react-components'
import { LocationResponse } from '@traba/types'
import {
  getLocationNameOrTruncatedAddress,
  getUserNameWithRole,
} from '@traba/utils'
import { Col, Icon, Row, Text } from 'src/components/base'
import { useLocationAssignedMembers } from 'src/hooks/useMembers'
import { useRoles } from 'src/hooks/useRoles'
import { theme } from 'src/libs/theme'
import { getAddressString } from 'src/utils/stringUtils'

// This is where the logic meets the UI. This separation allows use to pass the data directly to the UI in StoryBook

const PLACEHOLDER_TEXT = '-'

export type LocationProps = {
  location: LocationResponse
  archive?: (location: LocationResponse) => void
  edit?: (location: LocationResponse) => void
  userCanEdit?: boolean
}

export const Location = (props: LocationProps) => {
  const { hotSettings } = useHotSettings()
  const { location, archive, edit } = props

  const locationAssignedMembers =
    useLocationAssignedMembers(location.locationId) || []
  const membersString = locationAssignedMembers
    .filter((user) => !!user.role)
    .map(getUserNameWithRole)
    .join(', ')
  const additionalContactsString = locationAssignedMembers
    .filter((user) => !user.role)
    .map(getUserNameWithRole)
    .join(', ')

  const { getRolesByLocationId } = useRoles()
  const locationAssignedRoles = getRolesByLocationId(location.locationId) || []
  const rolesString = locationAssignedRoles
    .map((role) => role.roleName)
    .join(', ')

  const menuItems: DotMenuProps['menuItems'] = []
  if (edit) {
    menuItems.push({
      title: 'Edit location',
      iconName: 'edit',
      onClick: () => {
        edit(location)
        window.analytics.track(`User Clicked Edit Location`, {})
      },
      color: theme.colors.MidnightBlue,
    })
  }

  if (archive) {
    menuItems.push({
      title: 'Archive location',
      iconName: 'trash',
      onClick: () => {
        archive(location)
        window.analytics.track(`User Clicked Archive Location`, {})
      },
      color: theme.colors.Red60,
    })
  }

  const actionableCard = props.userCanEdit && menuItems.length > 0

  return (
    <Col gap={theme.space.sm}>
      <Row alignCenter gap={theme.space.xxs} justifyBetween>
        <Row gap={theme.space.sm} alignCenter>
          <CardTile size="56px">
            <Icon name="location" />
          </CardTile>
          <Col>
            <Text variant="h5">
              {getLocationNameOrTruncatedAddress(location)}
            </Text>
            <Text>{getAddressString(location.address)}</Text>
          </Col>
        </Row>

        {actionableCard && (
          <DotMenu
            type="locations"
            dotMenuKey={location.locationId}
            menuItems={menuItems}
          />
        )}
      </Row>

      <Col gap={theme.space.xs}>
        <div>
          <Text variant="caption">ARRIVAL INSTRUCTIONS</Text>
          <Text>{location.locationInstructions || PLACEHOLDER_TEXT}</Text>
        </div>

        {hotSettings?.enableRegionalAccessPhase2 && (
          <>
            <div>
              <Text variant="caption">ASSIGNED MEMBERS</Text>
              <Text>{membersString || PLACEHOLDER_TEXT}</Text>
            </div>

            <div>
              <Text variant="caption">ADDITIONAL CONTACTS</Text>
              <Text>{additionalContactsString || PLACEHOLDER_TEXT}</Text>
            </div>
          </>
        )}

        <div>
          <Text variant="caption">ROLES</Text>
          <Text>{rolesString || PLACEHOLDER_TEXT}</Text>
        </div>
      </Col>
    </Col>
  )
}
