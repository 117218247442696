import { Menu, MenuItem } from '@mui/material'
import { useHotSettings } from '@traba/hooks'
import {
  TabPanel,
  MobileTab,
  Tabs,
  TabsContainer,
} from '@traba/react-components'
import { JobStatus, Roster } from '@traba/types'
import React, { useState } from 'react'
import { Button, ButtonVariant, SvgIcon, Text } from 'src/components/base'
import { WorkersOnShiftTable } from 'src/components/WorkersOnShiftTable/'
import { useWorkersOnShift } from 'src/components/WorkersOnShiftTable/WorkersOnShiftTable.hooks'
import { useUserContext } from 'src/context/user/UserContext'
import { useCompanyWorkersByIds } from 'src/hooks/useCompanyWorkers'
import { useRoster } from 'src/hooks/useRoster'
import { theme } from 'src/libs/theme'
import { BookShiftsInvitationsModal } from 'src/screens/BookShifts/steps/sections/BookShiftsInvitationsModal'
import {
  EditWorkerSlotsEntryPoint,
  EditWorkerSlotsModal,
} from 'src/screens/EditShifts/EditWorkerSlotsModal'
import { UpcomingShiftDetailsProps } from '../../UpcomingShiftDetails.hooks'
import { Announcements } from '../Announcements'
import { buildWorkerTableCSV } from '../export-utils'
import ShareShiftDetails from '../ShareShiftDetails'
import { ShiftCostSummary } from '../ShiftCostSummary'
import { ShiftInvitationsTable } from '../ShiftInvitationsTable'

export const MobileUpcomingShiftSummary: React.FC<UpcomingShiftDetailsProps> = (
  props: UpcomingShiftDetailsProps,
) => {
  const {
    shift,
    expectedCost,
    userCanViewWages,
    userCanManageWorkers,
    editing,
    setEditing,
    updateShift,
    setShiftToUpdate,
    shiftToUpdate,
    userCanManageShift,
    location,
    parkingLocation,
    shiftInvitations,
    rescindInvitation,
    sendInvitations,
    isEditSlotsEnabled,
    isEditWorkerSlotsModalOpen,
    setIsEditWorkerSlotsModalOpen,
    handleUpdateSlotsRequested,
  } = props
  const { hotSettings } = useHotSettings()
  const { workerShifts = [] } = props
  const [currentTab, setCurrentTab] = useState(0)
  const [isInviteAdditionalOpen, setIsInviteAdditionalOpen] = useState(false)
  const [selectedRoster, setSelectedRoster] = useState<Roster | undefined>()
  const [selectedWorkers, setSelectedWorkers] = useState(new Set<string>())
  const [showCostSummary, setShowCostSummary] = useState(false)
  const [showShareShiftDetails, setShowShareShiftDetails] = useState(false)
  const { roster } = useRoster(
    selectedRoster?.id || '',
    shift.locationId,
    shift.roleId,
  )
  const userContext = useUserContext()
  const { data: workers } = useCompanyWorkersByIds()
  const { slotsRequested } = shift
  const { workersOnShift, workersOnBackup } = useWorkersOnShift({
    isUpcoming: true,
    workerShifts,
    slotsRequested,
  })

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const isOpen = !!anchorEl
  function handleClick(event: React.MouseEvent<HTMLElement>) {
    setAnchorEl(event.currentTarget)
  }
  function handleClose() {
    setAnchorEl(null)
  }

  const tableActions = (
    <Button
      onClick={() =>
        buildWorkerTableCSV(
          [...workersOnShift, ...workersOnBackup],
          shift,
          location,
          userContext?.state,
          hotSettings?.enableWorkerEditTime,
        )
      }
      variant={ButtonVariant.OUTLINED}
      title="Download worker roster"
    >
      <SvgIcon name="download" color={theme.colors.Grey50} />
    </Button>
  )

  const TABS = [
    {
      key: 'workers',
      label: <Text>Workers</Text>,
      iconName: 'twoUsers',
      Component: (
        <WorkersOnShiftTable
          shift={shift}
          additionalWorkerActions={tableActions}
          isUpcoming={true}
          isReactNativeApp={true}
          onClickInviteWorker={() => {
            setIsInviteAdditionalOpen(true)
          }}
          isEditSlotsEnabled={isEditSlotsEnabled}
          onClickEditWorkerSlots={() => {
            setIsEditWorkerSlotsModalOpen(true)
          }}
        />
      ),
    },
    {
      key: 'shift-invitations',
      label: <Text>Invitations</Text>,
      iconName: 'twoUsers',
      Component: (
        <ShiftInvitationsTable
          shift={shift}
          shiftInvitations={shiftInvitations}
          canceledWorkerShifts={workerShifts.filter(
            (ws) => ws.jobStatus === JobStatus.Canceled,
          )}
          rescindInvitation={rescindInvitation}
          onClickInviteWorker={() => {
            setIsInviteAdditionalOpen(true)
          }}
        />
      ),
    },
    ...(userCanManageWorkers
      ? [
          {
            key: 'announcements',
            label: <Text>Announcements</Text>,
            Component: (
              <Announcements
                shiftId={shift.id}
                shift={shift}
                location={location}
                parkingLocation={parkingLocation}
              />
            ),
          },
        ]
      : []),
  ]

  const onClickWorker = (workerId: string) => {
    const newWorkers = new Set(selectedWorkers)
    if (selectedWorkers.has(workerId)) {
      newWorkers.delete(workerId)
    } else {
      newWorkers.add(workerId)
    }
    setSelectedWorkers(newWorkers)
  }

  const sendInvitationsToWorkers = async () => {
    sendInvitations({
      shiftId: shift.shiftId,
      workerIds: Array.from(selectedWorkers),
      includeRescinded: true,
    })
    setIsInviteAdditionalOpen(false)
    const idx = TABS.findIndex((tab) => tab.key === 'shift-invitations')
    setCurrentTab(idx)
  }

  return (
    <>
      <TabsContainer style={{ alignItems: 'center' }}>
        <Tabs
          style={{ width: '90%' }}
          value={currentTab}
          onChange={(_, v) => {
            setCurrentTab(v)
            setShowCostSummary(false)
            setShowShareShiftDetails(false)
          }}
        >
          {TABS.map((t, index) => (
            <MobileTab
              style={{
                alignItems: 'center',
                backgroundColor:
                  currentTab === index ? theme.colors.Violet10 : undefined,
              }}
              label={t.label}
            />
          ))}
        </Tabs>

        <Text
          color={theme.colors.MidnightBlue}
          variant="h6"
          onClick={handleClick}
        >
          More
        </Text>
      </TabsContainer>
      {!showCostSummary &&
        !showShareShiftDetails &&
        TABS.map(({ Component }, i) => (
          <TabPanel value={currentTab} index={i}>
            {Component}
          </TabPanel>
        ))}
      {showCostSummary && (
        <ShiftCostSummary
          workerShifts={workerShifts}
          expectedCost={expectedCost}
          shift={shift}
        />
      )}
      {showShareShiftDetails && (
        <ShareShiftDetails
          editing={editing}
          setEditing={setEditing}
          updateShift={updateShift}
          setShiftToUpdate={setShiftToUpdate}
          shiftToUpdate={shiftToUpdate}
          shift={shift}
        />
      )}
      <Menu
        id={`shift-menu`}
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': `shift-menu`,
        }}
      >
        {userCanViewWages && (
          <MenuItem
            onClick={() => {
              handleClose()
              setShowCostSummary(true)
              setShowShareShiftDetails(false)
              setCurrentTab(-1)
            }}
            style={{ padding: `${theme.space.sm / 2} ${theme.space.sm}` }}
          >
            Estimated cost
          </MenuItem>
        )}
        {userCanManageShift && (
          <MenuItem
            onClick={() => {
              handleClose()
              setShowCostSummary(false)
              setShowShareShiftDetails(true)
              setCurrentTab(-1)
            }}
            style={{ padding: `${theme.space.sm / 2} ${theme.space.sm}` }}
          >
            Share shift details
          </MenuItem>
        )}
      </Menu>
      <BookShiftsInvitationsModal
        isOpen={isInviteAdditionalOpen}
        setIsOpen={setIsInviteAdditionalOpen}
        selectedRoster={selectedRoster}
        setSelectedRoster={setSelectedRoster}
        selectedWorkers={selectedWorkers}
        setSelectedWorkers={setSelectedWorkers}
        onConfirm={sendInvitationsToWorkers}
        roster={roster}
        disabledWorkerIdsSet={
          new Set(shiftInvitations?.map((invitation) => invitation.workerId))
        }
        onClickWorker={onClickWorker}
        locationId={shift.locationId}
        roleId={shift.roleId}
        shiftId={shift.shiftId}
      />
      <EditWorkerSlotsModal
        isOpen={isEditWorkerSlotsModalOpen}
        setIsOpen={setIsEditWorkerSlotsModalOpen}
        onClose={() => setIsEditWorkerSlotsModalOpen(false)}
        shift={shift}
        workerShifts={workersOnShift.filter(
          // Filter out canceled worker shifts from list of workers to remove
          (ws) => ws.jobStatus !== JobStatus.Canceled,
        )}
        workersOnBackup={workersOnBackup.filter(
          (ws) => ws.jobStatus !== JobStatus.Canceled,
        )}
        companyWorkers={workers}
        onClickInviteWorker={() => {
          setIsInviteAdditionalOpen(true)
        }}
        handleUpdateSlotsRequested={handleUpdateSlotsRequested}
        entryPoint={EditWorkerSlotsEntryPoint.WORKERS_ON_SHIFT_TABLE}
      />
    </>
  )
}
