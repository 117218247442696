import { useHotSettings } from '@traba/hooks'
import {
  Button,
  ButtonVariant,
  Card,
  Col,
  MODAL_SIZE,
  Row,
} from '@traba/react-components'
import { COMPANY_WIDE_ID, CompanyCategory, UserRole } from '@traba/types'
import { containsText, sortRolesByLocationNameThenRoleName } from '@traba/utils'
import React, { useEffect, useState } from 'react'
import { EmptyPlaceholderTile, Modal, Text } from 'src/components/base'
import {
  RegionalFilterStatus,
  useAppContext,
} from 'src/context/appContext/AppContext'
import { useCompany } from 'src/hooks/useCompany'
import { useSelectedRegionalFilterLocations } from 'src/hooks/useRegionalFilter'
import { useUser, useUserPermissions } from 'src/hooks/useUser'
import { theme } from 'src/libs/theme'
import { RoleData, UserRolePermission } from 'src/types'

import { Plus } from '../assets/svg'
import { ArchiveRoleModal } from '../Modals/ArchiveRoleModal'
import { CreateRoleModal } from '../Modals/CreateRoleModal'
import { EditRoleModal } from '../Modals/EditRoleModal'
import { Role } from '../Role'
import { SearchWithLocationSelectionRow } from '../SearchWithLocationSelectionRow'
import { ALL_SELECTED_ID } from '../SearchWithLocationSelectionRow/LocationSingleSelectFilter'

export type RolesProfileProps = {
  roles: RoleData[]
}

export const RolesProfileUI: React.FC<RolesProfileProps> = (
  props: RolesProfileProps,
) => {
  const { user } = useUser()
  const { company } = useCompany()
  const { hotSettings } = useHotSettings()
  const { selectedLocationIds } = useSelectedRegionalFilterLocations()
  const { dispatch: appContextDispatch } = useAppContext()

  const userCanViewWages = useUserPermissions([UserRolePermission.ViewPay])
  // ManagerUserRoles permission is not applicable as that is for managing roles attached to business app users
  const userCanEditShiftRoles =
    useUserPermissions([UserRolePermission.ManageShifts]) &&
    user?.role !== UserRole.LimitedShiftCreator
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [duplicateRoleId, setDuplicateRoleId] = useState<string | undefined>(
    undefined,
  )
  function openModal() {
    setIsModalOpen(true)
  }
  function closeModal() {
    setIsModalOpen(false)
    setDuplicateRoleId(undefined)
  }

  const [showEditModal, setShowEditModal] = useState(false)
  const [roleToEdit, setRoleToEdit] = useState<RoleData | undefined>(undefined)

  const [showArchiveModal, setShowArchiveModal] = useState(false)
  const [roleToArchive, setRoleToArchive] = useState<RoleData | undefined>(
    undefined,
  )

  const handleArchiveModalClose = () => {
    setShowArchiveModal(false)
    setRoleToArchive(undefined)
  }

  const handleArchive = (role: RoleData) => {
    setShowArchiveModal(true)
    setRoleToArchive(role)
  }

  const handleEdit = (role: RoleData) => {
    setShowEditModal(true)
    setRoleToEdit(role)
  }

  const handleDuplicateRole = (role: RoleData) => {
    setIsModalOpen(true)
    setDuplicateRoleId(role.roleId)
  }

  const handleEditModalClose = () => {
    setShowEditModal(false)
    setRoleToEdit(undefined)
  }

  const [searchFilterText, setSearchFilterText] = useState('')
  const [selectedLocation, setSelectedLocation] = useState(ALL_SELECTED_ID)

  const isAllLocationsSelected = selectedLocation === ALL_SELECTED_ID
  const isCompanyWideSelected = selectedLocation === COMPANY_WIDE_ID
  const selectedLocationId =
    !isAllLocationsSelected && !isCompanyWideSelected
      ? selectedLocation
      : undefined

  useEffect(() => {
    appContextDispatch({
      type: 'SET_REGIONAL_FILTER_STATUS',
      value: hotSettings?.enableRegionalAccessPhase1
        ? RegionalFilterStatus.ENABLED
        : RegionalFilterStatus.HIDE,
    })
    return () => {
      appContextDispatch({
        type: 'SET_REGIONAL_FILTER_STATUS',
        value: RegionalFilterStatus.HIDE,
      })
    }
  }, [appContextDispatch, hotSettings?.enableRegionalAccessPhase1])

  const filteredRoles = props.roles
    .filter((role) => {
      const isRoleCompanyWide = !role.location
      const locationIdForRole = role.location?.locationId
      const isRoleLocationIncludedInRegionalFilter =
        isRoleCompanyWide ||
        (locationIdForRole && selectedLocationIds.has(locationIdForRole))
      const isRoleInSelectedLocation =
        isAllLocationsSelected ||
        (isRoleCompanyWide && isCompanyWideSelected) ||
        selectedLocationId === locationIdForRole
      const showRoleBasedOnLocation =
        isRoleLocationIncludedInRegionalFilter && isRoleInSelectedLocation

      if (hotSettings?.enableRegionalAccessPhase1 && !showRoleBasedOnLocation) {
        return false
      }

      const textMatches = `${role.roleName} ${role.roleDescription}`
      return containsText(textMatches, searchFilterText)
    })
    .sort((roleA, roleB) =>
      sortRolesByLocationNameThenRoleName(
        { roleName: roleA.roleName, location: roleA.location },
        { roleName: roleB.roleName, location: roleB.location },
      ),
    )

  const rolesExistButNotInFilteredResults =
    filteredRoles.length === 0 && props.roles.length > 0
  const emptyListTitle = rolesExistButNotInFilteredResults
    ? 'No roles matching the filters'
    : 'Your roles will show up here'
  const emptyListSubtitle = rolesExistButNotInFilteredResults
    ? 'Change filtering to see your roles.'
    : 'You can add a role above.'

  const showGenderPreference = company?.allowGenderPreference

  return (
    <Col gap={theme.space.xs}>
      <Row alignCenter justifyBetween>
        <Col gap={theme.space.xxs}>
          <Text variant="h5">Roles</Text>
          <Text variant="body2">
            {userCanEditShiftRoles ? 'Manage' : 'View'} your company‘s roles.
          </Text>
        </Col>
        {userCanEditShiftRoles && (
          <Button
            leftIcon={<Plus />}
            onClick={openModal}
            variant={ButtonVariant.FILLED}
          >
            Create new role
          </Button>
        )}
      </Row>
      <SearchWithLocationSelectionRow
        filtersKey="role"
        analyticsKey="Roles"
        searchFilterText={searchFilterText}
        setSearchFilterText={setSearchFilterText}
        searchPlaceholder="Search by role name or description"
        selectedLocation={selectedLocation}
        setSelectedLocation={setSelectedLocation}
        hideLocationSearch={!hotSettings?.enableRegionalAccessPhase1}
        allLocationsSelectedText="All roles"
      />
      {filteredRoles.length ? (
        filteredRoles.map((role: RoleData) => (
          <Card key={role.roleId} borderWidth="1px" style={{ width: '100%' }}>
            <Role
              role={role}
              edit={handleEdit}
              archive={handleArchive}
              duplicate={handleDuplicateRole}
              userCanViewWages={userCanViewWages}
              userCanEdit={userCanEditShiftRoles}
              showGenderPreference={showGenderPreference}
            />
          </Card>
        ))
      ) : (
        <EmptyPlaceholderTile
          title={emptyListTitle}
          subtitle={emptyListSubtitle}
          iconName="folder"
        />
      )}
      <Modal isOpen={isModalOpen} handleClose={closeModal}>
        <CreateRoleModal
          onClose={closeModal}
          fromPrevious={!!duplicateRoleId}
          duplicatePreviousRoleId={duplicateRoleId}
          isEventCompany={company?.category === CompanyCategory.EVENTS}
        />
      </Modal>
      <Modal
        size={MODAL_SIZE.MEDIUM}
        isOpen={showArchiveModal}
        handleClose={handleArchiveModalClose}
      >
        {roleToArchive && (
          <ArchiveRoleModal
            role={roleToArchive}
            handleClose={handleArchiveModalClose}
          />
        )}
      </Modal>
      <Modal
        size={MODAL_SIZE.MEDIUM}
        isOpen={showEditModal}
        handleClose={handleEditModalClose}
      >
        {roleToEdit && (
          <EditRoleModal
            role={roleToEdit}
            handleClose={handleEditModalClose}
            isEventCompany={company?.category === CompanyCategory.EVENTS}
          />
        )}
      </Modal>
    </Col>
  )
}
