import { InputStatus } from '@traba/types'
import styled, { createGlobalStyle } from 'styled-components'
import { RequiredProps } from '../Required'

export const SearchSelectContainer = styled.div`
  width: ${({ style }) => style?.width};
  margin: ${({ style }) => style?.margin};
  max-width: 100%;

  #search-select {
    font-family: 'Poppins';
    color: ${({ theme }) => theme.colors.MidnightBlue};
    font-size: 14;
  }
  #search-select-label {
    font-family: 'Poppins';
  }
  .MuiOutlinedInput-root {
    height: 40px;
    border-radius: 8px;
    background-color: white;
  }
`

export const SearchSelectBoxStyling = createGlobalStyle`
  #search-text-field {
    font-family: 'Poppins';
  }
  .MuiOutlinedInput-root.Mui-focused fieldset {
    border: ${({ theme }) => `1px solid ${theme.colors.brand} !important`};
  }
  .MuiOutlinedInput-root fieldset {
    border-radius: 8px !important;
  }
`
export const SearchSelectLabel = styled.label<RequiredProps>`
  top: -10px;
  left: 12px;
  position: absolute;
  background-color: white;
  border-radius: 4px;
  padding: 0px 5px 0px 5px;
  font-size: 1.1em;
  transition: 0.2s;
  pointer-events: none;
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme, inputStatus }) => {
    switch (inputStatus) {
      case InputStatus.error:
        return theme.error.border
      default:
        return theme.colors.Grey50
    }
  }};
`
